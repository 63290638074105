import {
    AppBar,
    Box,
    IconButton,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { useAppState } from '../../../AppStateProvider'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Event } from '../../../models/Event'
import {
    BackgroundIconButton,
    SelectButton,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { iso2eventtime } from '../../../utils/DateStringConverter'
import { NavigateEventSelectTickets } from '../../../models/NavigateState'
import { useEffect, useState } from 'react'
import { DataStatus } from '../../../models/Dataloading'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import TicketSelectItem from '../TicketSelectItem'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import {
    navigateTickets,
    navigateToGiftTickets,
} from '../../../utils/NavigationFunctions'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    // borderBottom: '1px solid ' + theme.palette.border.secondary,
    background: hexToRGBA('#111111', 0.6),
    position: 'fixed',
    width: `calc(100%)`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
}))

const EventTicketSelectMobileView = () => {
    const navigate = useNavigate()
    const apiManager = useApiManager()
    const location = useLocation()
    const { slug } = useParams<{ slug: string }>()
    const initialState = location.state as NavigateEventSelectTickets
    const [eventState, setEventState] = useState<Event | null>(
        initialState ? initialState.event : null
    )
    const [eventloaded, setEventLoaded] = useState<DataStatus>(
        initialState?.event ? DataStatus.success : DataStatus.loading
    )
    const [selectedAmount, setSelectedAmount] = useState(0)

    useEffect(() => {
        if (!eventState) {
            if (slug) {
                apiManager
                    .getEvent(slug)
                    .then((response) => {
                        setEventState(response.event)
                        setEventLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        setEventLoaded(DataStatus.failure)
                        console.log(error)
                    })
            }
        }
    }, [])
    return (
        <Box
            sx={{
                mt: '56px',
                position: 'relative',
                mb: `${navMobileBarHeight}px`,
            }}
        >
            {eventState && (
                <>
                    <EventTicketSelectTopBarMobileView
                        eventState={eventState}
                    />
                    <EventTicketSelectBottomBarMobileView
                        eventState={eventState}
                        selectedAmount={selectedAmount}
                    />
                    <Box
                        sx={{
                            p: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Grab your tickets
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: '16px',
                                mt: '4px',
                            }}
                        >
                            {iso2eventtime(
                                eventState.event_time,
                                'short',
                                'short'
                            )}
                        </Typography>
                        <TicketSelectItem
                            eventState={eventState}
                            selectedAmount={selectedAmount}
                            setSelectedAmount={setSelectedAmount}
                        />
                    </Box>
                </>
            )}
        </Box>
    )
}

interface EventTicketSelectTopBarMobileViewProps {
    eventState: Event
}
const EventTicketSelectTopBarMobileView = ({
    eventState,
}: EventTicketSelectTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const apiManager = useApiManager()
    const navigate = useNavigate()

    const {
        data: eventImage,
        isLoading: isEventImageLoading,
        error: userImageError,
    } = useQuery(
        eventState ? eventState.image : '',
        () => {
            if (eventState) {
                return apiManager.grabEventPhoto(eventState.image)
            }
        },
        {
            enabled: !!eventState,
        }
    )
    return (
        <TopBar
            sx={{
                overflow: 'hidden',
                borderBottom: (theme) =>
                    eventImage
                        ? 'transparent'
                        : '1px solid ' + theme.palette.border.secondary,
            }}
        >
            {eventImage && (
                <img
                    src={eventImage}
                    alt="Event"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        filter: 'blur(10px)',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        scale: 1.1,
                    }}
                />
            )}

            <Toolbar
                sx={{
                    minHeight: '55px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                {eventState && (
                    <>
                        <BackgroundIconButton
                            sx={{
                                height: '36px',
                                width: '36px',
                            }}
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            <ArrowBackIosNewRoundedIcon
                                sx={{
                                    fontSize: '16px',
                                }}
                            />
                        </BackgroundIconButton>
                        <Box ml="12px">
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    color: 'text.primary',
                                    fontWeight: 'bold',
                                }}
                            >
                                {eventState.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    bt: 0,
                                    color: 'text.primary',
                                }}
                            >
                                Select Tickets
                            </Typography>
                        </Box>
                    </>
                )}
            </Toolbar>
        </TopBar>
    )
}

const BottomBar = styled(Box)(({ theme }) => ({
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `100%`,
    zIndex: theme.zIndex.drawer + 1,
    // borderRadius: '10px 10px 0 0',
    // boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.8)',
    borderTop: `1px solid ${theme.palette.border.primary}`,
}))

interface EventBottomBarMobileViewProps {
    eventState: Event
    selectedAmount: number
}
const EventTicketSelectBottomBarMobileView = ({
    eventState,
    selectedAmount,
}: EventBottomBarMobileViewProps) => {
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [purchasing, setPurchasing] = useState(false)
    const handlePurchase = () => {
        if (!purchasing) {
            setPurchasing(true)
            apiManager
                .purchaseTicket(eventState.slug, selectedAmount)
                .then((response) => {
                    navigateTickets(navigate, state, setState, 'active')
                    setPurchasing(false)
                })
                .catch((error) => {
                    setPurchasing(false)
                    console.log(error)
                })
        }
    }
    return (
        <BottomBar
            sx={{
                bottom: `0px`,
                display: 'flex',
                alignItems: 'center',
                alignContent: 'top',
                height: `${navMobileBarHeight}px`,
            }}
        >
            <Box
                sx={{
                    justifyContent: 'center',
                    p: '12px 16px',
                    width: '100%',
                }}
            >
                <SelectButton
                    fullWidth
                    sx={{
                        height: '40px',
                        fontSize: '16px',
                        p: '0px 8px',
                        position: 'relative',
                    }}
                    disabled={selectedAmount === 0}
                    onClick={handlePurchase}
                >
                    {purchasing ? (
                        <StyledCircularProgress
                            sx={{
                                color: 'text.primary',
                            }}
                            size={'26px'}
                        />
                    ) : (
                        <>
                            <ShoppingCartRoundedIcon
                                sx={{ fontSize: '20px', ml: '8px' }}
                            />
                            <Box sx={{ flexGrow: 1 }} />

                            <IconButton
                                sx={{
                                    backgroundColor: hexToRGBA('#111111', 0.1),
                                    '&:hover': {
                                        backgroundColor: hexToRGBA(
                                            '#111111',
                                            0.1
                                        ),
                                    },
                                    color: 'text.primary',
                                    width: '25px',
                                    height: '25px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {selectedAmount}
                                </Typography>
                            </IconButton>
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '16px',
                                    color: 'text.primary',
                                    fontWeight: 'bold',
                                }}
                            >
                                Checkout
                            </Typography>
                        </>
                    )}
                </SelectButton>
            </Box>
        </BottomBar>
    )
}

export default EventTicketSelectMobileView
