import {
    Box,
    Collapse,
    Drawer,
    IconButton,
    List,
    styled,
    Typography,
} from '@mui/material'
import { useAppState } from '../../../AppStateProvider'
import EventStringSubView from '../../EventView/EventStringSubView'
import zIndex from '@mui/material/styles/zIndex'
import {
    PrimaryDivider,
    StyledIconButton,
} from '../../Components/StyledComponents'
import { useNavigate } from 'react-router-dom'
import { navigateToEvent } from '../../../utils/NavigationFunctions'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { useState } from 'react'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import AccountDetailsSubView from '../../AccountView/AccountSubViews/AccountDetailsSubView'

interface StyledArrowForwardIosRoundedIconProps
    extends React.ComponentProps<typeof ArrowForwardIosRoundedIcon> {
    open: boolean
}
const StyledArrowForwardIosRoundedIcon = styled(
    (props: StyledArrowForwardIosRoundedIconProps) => (
        <ArrowForwardIosRoundedIcon {...props} />
    )
)(({ theme, open }) => ({
    color: theme.palette.text.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: 300,
        boxSizing: 'border-box',
        background: theme.palette.background.primary,
        // borderRight: '1px solid ' + theme.palette.border.primary,
        zIndex: zIndex.drawer + 5,
    },
    zIndex: zIndex.drawer + 5,
}))

interface FeedDrawerMobileViewProps {
    showDrawer: boolean
    setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>
}
const FeedDrawerMobileView = ({
    showDrawer,
    setShowDrawer,
}: FeedDrawerMobileViewProps) => {
    const { state, setState } = useAppState()
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const [showItems, setShowItems] = useState(['recent', 'trending'])
    const handleCollapse = (item: string) => {
        if (showItems.includes(item)) {
            setShowItems(showItems.filter((i) => i !== item))
        } else {
            setShowItems([...showItems, item])
        }
    }
    return (
        <StyledDrawer
            variant="temporary"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: '0px 16px',
                        height: 55,
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                        height={30}
                    />
                    <Box flex={1} />
                    <StyledIconButton
                        sx={{
                            height: '35px',
                            width: '35px',
                        }}
                        onClick={() => setShowDrawer(false)}
                    >
                        <ClearRoundedIcon
                            sx={{
                                fontSize: '24px',
                            }}
                        />
                    </StyledIconButton>
                </Box>
                <PrimaryDivider />
            </Box>
            <Box
                sx={{
                    p: '16px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.tertiary',
                            fontWeight: 'bold',
                        }}
                    >
                        RECENTLY VISITED
                    </Typography>
                    <Box flex={1} />
                    <StyledIconButton
                        sx={{
                            height: '35px',
                            width: '35px',
                        }}
                        onClick={() => handleCollapse('recent')}
                    >
                        <StyledArrowForwardIosRoundedIcon
                            style={{ fontSize: 16 }}
                            open={showItems.includes('recent')}
                        />
                    </StyledIconButton>
                </Box>
                <Collapse in={showItems.includes('recent')}>
                    {state.recentUsers.length > 0 && (
                        <List>
                            {state.recentUsers
                                .slice()
                                .reverse()
                                .map((users) => (
                                    <Box
                                        sx={{
                                            mb: '16px',
                                        }}
                                    >
                                        <AccountDetailsSubView
                                            user={users}
                                            followButton={false}
                                            bio={false}
                                            size={35}
                                        />
                                    </Box>
                                ))}
                        </List>
                    )}
                </Collapse>
                <PrimaryDivider
                    sx={{
                        mb: '8px',
                    }}
                />
            </Box>
        </StyledDrawer>
    )
}

export default FeedDrawerMobileView
