import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    Tab,
    Tabs,
    Divider,
    List,
    InputBase,
    Button,
    ButtonBase,
} from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavigatePostState } from '../../../models/NavigateState'
import { string } from 'yup'
import { DataStatus } from '../../../models/Dataloading'
import { Post } from '../../../models/Post'
import { set } from 'date-fns'
import PostItemCommentView from '../PostItemCommentView'
import {
    PrimaryDivider,
    SecondaryTypography,
    StyledChip,
    StyledCircularProgress,
    TabBoxSelect,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import { Comment } from '../../../models/Comment'
import CommentView from '../CommentView'
import { styled } from '@mui/material/styles'
import PostEventSubView from '../../SideViews/PostEventSideView'
import { Create } from '@mui/icons-material'
import CreateCommentView from '../CreateCommentView'
import SideLinksView from '../../SideViews/SideLinksView'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import SortPopper from '../../Components/SortPopper'
import EmptyCommentsView from '../../Components/StaticPages/EmptyView/EmptyCommentsView'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostItemCommentMobileView from './PostItemCommentMobileView'
import CommentMobileView from './CommentMobileView'
import PostTopBarMobileView from './PostTopBarMobileView'
import PostCommentBarMobileView from './PostCommentBarMobileView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import CreateCommentExpandMobileView from './CreateCommentExpandMobileView'
import PostOptionsMobileView from './PostOptionsMobileView'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { useQuery } from 'react-query'

interface StyledArrowForwardIosRoundedIconProps
    extends React.ComponentProps<typeof ArrowForwardIosRoundedIcon> {
    open: boolean
}
const StyledArrowForwardIosRoundedIcon = styled(
    (props: StyledArrowForwardIosRoundedIconProps) => (
        <ArrowForwardIosRoundedIcon {...props} />
    )
)(({ theme, open }) => ({
    color: theme.palette.text.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const PostMainMobileView = React.memo(() => {
    const apiManager = useApiManager()
    const { uuid } = useParams<{ uuid: string }>()
    const location = useLocation()
    const navigate = useNavigate()
    const locationState = location.state as NavigatePostState | null
    const searchParams = new URLSearchParams(location.search)
    const [postState, setPostState] = useState<Post | null>(
        locationState?.post ?? null
    )
    const [comments, setComments] = useState<Comment[] | null>(
        locationState?.comments ?? null
    )
    const [postImage, setPostImage] = useState<string | null>(
        locationState?.postImage ?? null
    )
    const [postAuthorImage, setPostAuthorImage] = useState<string | null>(
        locationState?.postAuthorImage ?? null
    )
    const [postAuthorImageLoaded, setPostAuthorImageLoaded] =
        useState<DataStatus>(
            locationState?.postAuthorImage
                ? DataStatus.success
                : DataStatus.loading
        )
    const [postImageLoaded, setPostImageLoaded] = useState<DataStatus>(
        locationState?.postImage ? DataStatus.success : DataStatus.loading
    )
    const [sortbyState, setSortByState] = useState(
        searchParams.get('sort') || 'top'
    )
    const [postloaded, setPostLoaded] = useState<DataStatus>(
        locationState?.post ? DataStatus.success : DataStatus.loading
    )
    const [inputComment, setInputComment] = useState<string>('')
    const [showExpandComment, setShowExpandComment] = useState(false)
    const [focused, setFocused] = useState(false)
    const [commenting, setCommenting] = useState(false)
    const [selectedComment, setSelectedComment] = useState<Comment | null>(null)
    const [commentAuthorImage, setCommentAuthorImage] = useState<
        string | null | undefined
    >(null)
    const [showOptions, setShowOptions] = useState(false)
    // useEffect(() => {
    //     if (locationState !== null) {
    //         setPostImage(locationState.postImage)
    //         setPostAuthorImage(locationState.postAuthorImage)
    //         setPost(locationState.post)
    //         setComments(locationState.comments)
    //         setPostLoaded(DataStatus.success)
    //         setPostImageLoaded(DataStatus.success)
    //         setPostAuthorImageLoaded(DataStatus.success)
    //     } else {
    //         setPostLoaded(DataStatus.failure)
    //         setPostImageLoaded(DataStatus.failure)
    //         setPostAuthorImageLoaded(DataStatus.failure)
    //     }
    // }, [location.key])
    const {
        data: userQueryImage,
        isLoading: isUserImageLoading,
        error,
    } = useQuery(
        postState ? postState.author.image : '',
        () => {
            if (postState && !postAuthorImage) {
                return apiManager.grabUserPhoto(postState.author.image)
            }
        },
        {
            enabled: !!postState && !!!postAuthorImage,
            onSuccess: (data) => {
                if (data) {
                    setPostAuthorImage(data)
                    setPostAuthorImageLoaded(DataStatus.success)
                }
            },
            onError: () => {
                setPostAuthorImageLoaded(DataStatus.failure)
            },
        }
    )
    const {
        data: postQueryImage,
        isLoading: isPostImageLoading,
        error: postImageError,
    } = useQuery(
        postState ? postState.image : '',
        () => {
            if (postState && !postImage) {
                return apiManager.grabPostPhoto(postState.image)
            }
        },
        {
            enabled: !!postState && !!!postImage,
            onSuccess: (data) => {
                if (data) {
                    setPostImage(data)
                    setPostLoaded(DataStatus.success)
                }
            },
            onError: () => {
                setPostLoaded(DataStatus.failure)
            },
        }
    )

    // useEffect(() => {
    //     if (userQueryImage && !postAuthorImage){
    //         setPostAuthorImage(userQueryImage)
    //     }
    // },[userQueryImage,postAuthorImage])

    useEffect(() => {
        if (postloaded === DataStatus.loading) {
            if (uuid) {
                apiManager
                    .getPostbyId(uuid)
                    .then((response) => {
                        setPostState(response.post)
                        setPostLoaded(DataStatus.success)
                        apiManager
                            .getComments(response.post.id)
                            .then((response) => {
                                setComments(response.comments)
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        setPostLoaded(DataStatus.failure)
                    })
            }
        } else {
            if (!comments && postState) {
                apiManager
                    .getComments(postState.id)
                    .then((response) => {
                        setComments(response.comments)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
    }, [])

    const handleSortChange = (newValue: string) => {
        if (postState) {
            navigate(`/app/post/${postState.uuid}/?sort=${newValue}`, {
                state: {
                    post: postState,
                    comments: comments,
                    postImage: postImage,
                    postAuthorImage: postAuthorImage,
                },
            })
            setSortByState(newValue)
            apiManager
                .getComments(postState.id)
                .then((response) => {
                    setComments(response.comments)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    useEffect(() => {
        setSortByState(searchParams.get('sort') || 'top')
    }, [searchParams.get('sort')])
    return (
        <div
            id="post-main-view"
            style={{
                height: '100%',
                width: '100%',
                position: 'relative',
                justifyContent: 'center',
                display: 'flex',
                float: 'left',
                zIndex: 1,
            }}
        >
            {postState && (
                <PostOptionsMobileView
                    post={postState}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                />
            )}
            {showExpandComment && postState ? (
                <CreateCommentExpandMobileView
                    post={postState}
                    postImage={postImage}
                    setPostImage={setPostImage}
                    postImageStatus={postImageLoaded}
                    setPostImageStatus={setPostImageLoaded}
                    postAuthorImage={postAuthorImage}
                    commentAuthorImage={commentAuthorImage}
                    setPostAuthorImage={setPostAuthorImage}
                    postAuthorImageStatus={postAuthorImageLoaded}
                    setPostAuthorImageStatus={setPostAuthorImageLoaded}
                    selectedComment={selectedComment}
                    setSelectedComment={setSelectedComment}
                    comments={comments}
                    setComments={setComments}
                    showExpandComment={showExpandComment}
                    setShowExpandComment={setShowExpandComment}
                    inputComment={inputComment}
                    setInputComment={setInputComment}
                    setCommenting={setCommenting}
                    setFocused={setFocused}
                />
            ) : (
                <>
                    {postState && (
                        <PostTopBarMobileView
                            post={postState}
                            setShowOptions={setShowOptions}
                        />
                    )}
                    {postState && (
                        <PostCommentBarMobileView
                            post={postState}
                            comments={comments}
                            setComments={setComments}
                            inputComment={inputComment}
                            setInputComment={setInputComment}
                            focused={focused}
                            setFocused={setFocused}
                            commenting={commenting}
                            setCommenting={setCommenting}
                            setShowExpandComments={setShowExpandComment}
                        />
                    )}
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            marginTop: '56px',
                            marginBottom: `${navMobileBarHeight + 60}px`,
                            position: 'relative',
                        }}
                    >
                        {postloaded === DataStatus.success &&
                        postState !== null ? (
                            <Box>
                                <PostItemCommentMobileView
                                    post={postState}
                                    postImage={postImage}
                                    userImage={postAuthorImage}
                                    isUserImageLoading={true}
                                    postloaded={postloaded}
                                    postAuthorImageLoaded={
                                        postAuthorImageLoaded
                                    }
                                    postImageLoaded={postImageLoaded}
                                    setShowOptions={setShowOptions}
                                />

                                <PrimaryDivider
                                    sx={{
                                        marginTop: '0px',
                                        marginBottom: '4px',
                                    }}
                                />
                                {comments !== null ? (
                                    comments.length === 0 ? (
                                        <EmptyCommentsView />
                                    ) : (
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    mt: '16px',
                                                    alignItems: 'center',
                                                    ml: '16px',
                                                }}
                                            >
                                                <TabBoxSelect
                                                    selected={
                                                        sortbyState === 'top'
                                                    }
                                                    onClick={() =>
                                                        handleSortChange('top')
                                                    }
                                                >
                                                    Top
                                                </TabBoxSelect>
                                                <TabBoxSelect
                                                    selected={
                                                        sortbyState === 'latest'
                                                    }
                                                    sx={{
                                                        ml: '8px',
                                                    }}
                                                    onClick={() =>
                                                        handleSortChange(
                                                            'latest'
                                                        )
                                                    }
                                                >
                                                    Latest
                                                </TabBoxSelect>
                                            </Box>
                                            {comments.map((comment) => (
                                                <Box
                                                    sx={{ padding: '0px 0px' }}
                                                >
                                                    <CommentMobileView
                                                        post={postState}
                                                        comment={comment}
                                                        setSelectedComment={
                                                            setSelectedComment
                                                        }
                                                        setCommentAuthorImage={
                                                            setCommentAuthorImage
                                                        }
                                                        setShowExpandComment={
                                                            setShowExpandComment
                                                        }
                                                    />
                                                    <PrimaryDivider />
                                                </Box>
                                            ))}
                                        </>
                                    )
                                ) : (
                                    <ErrorTabView />
                                )}
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <StyledCircularProgress />
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </div>
    )
})

export default PostMainMobileView
