import { Box, styled, Typography } from '@mui/material'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { SelectButton } from '../../Components/StyledComponents'
import { Event } from '../../../models/Event'
import { navigateToEventSelectTickets } from '../../../utils/NavigationFunctions'
import { useAppState } from '../../../AppStateProvider'
import { useNavigate } from 'react-router-dom'

const BottomBar = styled(Box)(({ theme }) => ({
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `100%`,
    zIndex: theme.zIndex.drawer + 1,
    // borderRadius: '10px 10px 0 0',
    // boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.8)',
    borderTop: `1px solid ${theme.palette.border.primary}`,
}))

interface EventBottomBarMobileViewProps {
    eventState: Event
}
const EventBottomBarMobileView = ({
    eventState,
}: EventBottomBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    return (
        <BottomBar
            sx={{
                bottom: `0px`,
                display: 'flex',
                alignItems: 'center',
                alignContent: 'top',
                height: `${navMobileBarHeight}px`,
            }}
        >
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: '12px 16px',
                    width: '100%',
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        width: '150px',
                    }}
                >
                    <Typography
                        sx={{
                            color: 'text.tertiary',
                            fontSize: '12px',
                        }}
                    >
                        Price Range
                    </Typography>
                    <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize: '18px',
                            fontWeight: 'bold',
                        }}
                    >
                        Free
                    </Typography>
                </Box>
                <Box flex={1} />
                <SelectButton
                    sx={{
                        height: '40px',
                        fontSize: '16px',
                        p: '0px 16px',
                    }}
                    onClick={() =>
                        navigateToEventSelectTickets(
                            eventState,
                            null,
                            navigate,
                            state,
                            setState
                        )
                    }
                >
                    <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Grab tickets
                    </Typography>
                </SelectButton>
            </Box>
        </BottomBar>
    )
}

export default EventBottomBarMobileView
