import { useQuery } from 'react-query'
import { Event } from '../../models/Event'
import { useApiManager } from '../../api/ApiMangerProvider'
import { useState } from 'react'
import { DataStatus } from '../../models/Dataloading'
import { Box, styled, Typography } from '@mui/material'
import { UserPhotoCircle } from '../Components/StyledComponents'
import { navigateToEvent } from '../../utils/NavigationFunctions'
import { useAppState } from '../../AppStateProvider'
import { useNavigate } from 'react-router-dom'

const EventPhotoCircle = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
}))

interface EventStringSubViewProps {
    event: Event
}

const EventStringSubView = ({ event }: EventStringSubViewProps) => {
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [eventImageStatus, setEventImageStatus] = useState(DataStatus.loading)
    const {
        data: eventImage,
        isLoading: isEventImageLoading,
        error: userImageError,
    } = useQuery(event.image, () => apiManager.grabEventPhoto(event.image), {
        onSuccess: () => {
            setEventImageStatus(DataStatus.success)
        },
        onError: () => {
            setEventImageStatus(DataStatus.failure)
        },
    })
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={() =>
                navigateToEvent(
                    apiManager,
                    event,
                    eventImage,
                    navigate,
                    state,
                    setState,
                    'details'
                )
            }
        >
            <EventPhotoCircle
                sx={{
                    height: '30px',
                    width: '30px',
                    overflow: 'hidden',
                }}
            >
                {eventImage && (
                    <img
                        src={eventImage}
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                    />
                )}
            </EventPhotoCircle>
            <Typography
                sx={{
                    fontSize: '14px',
                    color: 'text.primary',
                    ml: '8px',
                }}
            >
                {event.title}
            </Typography>
        </Box>
    )
}

export default EventStringSubView
