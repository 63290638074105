import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useApiManager } from '../../api/ApiMangerProvider'
import { NavigateEventSelectTickets } from '../../models/NavigateState'
import { useEffect, useState } from 'react'
import { DataStatus } from '../../models/Dataloading'
import { Event } from '../../models/Event'
import { Box, IconButton, Typography } from '@mui/material'
import { navMobileBarHeight } from '../../layouts/DefaultLayout/NavMobileBar'
import EventSubPostView from './EventSubPostView'
import {
    SecondaryDivider,
    SelectButton,
    StyledCircularProgress,
} from '../Components/StyledComponents'
import TicketSelectItem from './TicketSelectItem'
import { navigateTickets } from '../../utils/NavigationFunctions'
import { useAppState } from '../../AppStateProvider'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import { hexToRGBA } from '../../utils/haxToRGBA'

const EventTicketSelectDesktopView = () => {
    const navigate = useNavigate()
    const apiManager = useApiManager()
    const location = useLocation()
    const { slug } = useParams<{ slug: string }>()
    const initialState = location.state as NavigateEventSelectTickets
    const [eventState, setEventState] = useState<Event | null>(
        initialState ? initialState.event : null
    )
    const [eventImage, setEventImage] = useState<string | null>(
        initialState ? initialState.eventImage : null
    )
    const [eventStatus, setEventStatus] = useState<DataStatus>(
        initialState?.event ? DataStatus.success : DataStatus.loading
    )
    const [selectedAmount, setSelectedAmount] = useState(0)
    const [purchasing, setPurchasing] = useState(false)
    const { state, setState } = useAppState()
    const handlePurchase = () => {
        if (!purchasing && eventState) {
            setPurchasing(true)
            apiManager
                .purchaseTicket(eventState.slug, selectedAmount)
                .then((response) => {
                    navigateTickets(navigate, state, setState, 'active')
                    setPurchasing(false)
                })
                .catch((error) => {
                    setPurchasing(false)
                    console.log(error)
                })
        }
    }
    useEffect(() => {
        if (!eventState) {
            if (slug) {
                apiManager
                    .getEvent(slug)
                    .then((response) => {
                        setEventState(response.event)
                        setEventStatus(DataStatus.success)
                    })
                    .catch((error) => {
                        setEventStatus(DataStatus.failure)
                        console.log(error)
                    })
            }
        }
    }, [])
    return (
        <>
            {eventStatus === DataStatus.success && eventState && (
                <Box
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        height: 'calc(100%)',
                        mt: '56px',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '400px',
                            position: 'absolute',
                            top: 0,
                            zIndex: -1,
                        }}
                    >
                        {eventImage && (
                            <img
                                src={eventImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    opacity: 1,
                                    filter: 'blur(20px)',
                                    zIndex: 1,
                                    WebkitMaskImage:
                                        'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))',
                                    scale: 1.1,
                                }}
                            />
                        )}
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: 600,

                            p: '0px 20px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 22,
                                fontWeight: 'bold',
                                color: 'text.primary',
                                mt: '16px',
                            }}
                        >
                            Select Tickets
                        </Typography>
                        <SecondaryDivider sx={{ mt: '8px', mb: '16px' }} />
                        <EventSubPostView event={eventState} />
                        <Box
                            sx={{
                                m: '16px 0px',
                            }}
                        >
                            <TicketSelectItem
                                eventState={eventState}
                                selectedAmount={selectedAmount}
                                setSelectedAmount={setSelectedAmount}
                            />
                        </Box>
                        <SelectButton
                            fullWidth
                            sx={{
                                height: '40px',
                                fontSize: '16px',
                                p: '0px 8px',
                                mt: '16px',
                                position: 'relative',
                            }}
                            disabled={selectedAmount === 0}
                            onClick={handlePurchase}
                        >
                            {purchasing ? (
                                <StyledCircularProgress
                                    sx={{
                                        color: 'text.primary',
                                    }}
                                    size={'26px'}
                                />
                            ) : (
                                <>
                                    <ShoppingCartRoundedIcon
                                        sx={{ fontSize: '20px', ml: '8px' }}
                                    />
                                    <Box sx={{ flexGrow: 1 }} />

                                    <IconButton
                                        sx={{
                                            backgroundColor: hexToRGBA(
                                                '#111111',
                                                0.1
                                            ),
                                            '&:hover': {
                                                backgroundColor: hexToRGBA(
                                                    '#111111',
                                                    0.1
                                                ),
                                            },
                                            color: 'text.primary',
                                            width: '25px',
                                            height: '25px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: 'text.primary',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {selectedAmount}
                                        </Typography>
                                    </IconButton>
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '16px',
                                            color: 'text.primary',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Checkout
                                    </Typography>
                                </>
                            )}
                        </SelectButton>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default EventTicketSelectDesktopView
