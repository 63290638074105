import React, { useEffect, useState, useRef } from 'react'
import { Event } from '../../models/Event'
import { DataStatus } from '../../models/Dataloading'
import { styled } from '@mui/material/styles'
import { Box, CardContent, CardMedia, Typography } from '@mui/material'
import { grabEventPhoto } from '../../api/RestEvent'
import { useAppState } from '../../AppStateProvider'
import { Link, useNavigate } from 'react-router-dom'
import { iso2eventtime } from '../../utils/DateStringConverter'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { grabUserPhoto } from '../../api/RestUser'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import {
    AttendingBox,
    HostBox,
    SecondaryTypography,
    SelectTypography,
} from '../Components/StyledComponents'
import AccountAvatarSubView from '../AccountView/AccountAvatarSubView'
import AccountDetailsSubViewWithFollow from '../AccountView/AccountSubViews/AccountDetailsSubView'
import AccountDetailsSubView from '../AccountView/AccountSubViews/AccountDetailsSubView'
import { navigateToEvent } from '../../utils/NavigationFunctions'
import { Person } from '@mui/icons-material'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { useApiManager } from '../../api/ApiMangerProvider'
import { darkenHexColor } from '../../utils/haxToRGBA'
import { useQuery } from 'react-query'

interface EventSubViewProps {
    event: Event
}

const LoadingBox = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const EventTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: 16,
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: '10px 0px',
    '&:last-child': {
        paddingBottom: 0,
    },
    zIndex: 10,
}))

const EventCard = styled('div')(({ theme }) => ({
    boxShadow: 'none',
    // backgroundColor: theme.palette.background.secondary,
    // border: `1px solid ${theme.palette.border.primary}`,
    padding: 10,
    borderRadius: 10,
    position: 'relative',
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    cursor: 'pointer',
}))

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledRepeatRoundedIcon = styled(RepeatRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledPersonOutlineRoundedIcon = styled(PersonOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const DetailsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 50,
}))

const EventSubView = ({ event }: EventSubViewProps) => {
    const apiManager = useApiManager()
    const navigator = useNavigate()
    const { state, setState } = useAppState()
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimage, setEventImage] = React.useState<string | null>(null)
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const {
        data: eventImage,
        isLoading: isEventImageLoading,
        error: eventImageError,
    } = useQuery(event.image, () => apiManager.grabEventPhoto(event.image))
    const {
        data: userImage,
        isLoading: isUserImageLoading,
        error: userImageError,
    } = useQuery(event.primary_host.image, () =>
        apiManager.grabUserPhoto(event.primary_host.image)
    )
    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 300)
    }
    const [userimage, setUserImage] = React.useState<string | null>(null)

    // useEffect(() => {
    //     try {
    //         apiManager
    //             .grabEventPhoto(event.image)
    //             .then((response) => {
    //                 setEventImageStatus(DataStatus.failure)
    //                 setEventImage(response)
    //                 setEventImageStatus(DataStatus.success)
    //             })
    //             .catch((error) => {
    //                 console.error(error)
    //                 setEventImageStatus(DataStatus.failure)
    //             })
    //     } catch (error) {
    //         console.error(error)
    //         setEventImageStatus(DataStatus.failure)
    //     }
    // }, [])
    // useEffect(() => {
    //     try {
    //         apiManager
    //             .grabUserPhoto(event.primary_host.image)
    //             .then((response) => {
    //                 setUserImage(response)
    //                 setUserImageStatus(DataStatus.success)
    //             })
    //             .catch((error) => {
    //                 console.error(error)
    //                 setUserImageStatus(DataStatus.failure)
    //             })
    //     } catch (error) {
    //         console.error(error)
    //         setUserImageStatus(DataStatus.failure)
    //     }
    // }, [])
    return (
        <EventCard
            onClick={() =>
                navigateToEvent(
                    apiManager,
                    event,
                    eventImage,
                    navigator,
                    state,
                    setState,
                    'details'
                )
            }
        >
            {/* {eventimagestatus === DataStatus.success && eventimage && (
            <div
            style={{
                overflow: 'hidden',
                position: 'absolute',
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                zIndex: 0,
                left: 0,
                top: 0,
            }}
            >
            <img
                src={eventimage}
                alt="Event"
                style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '10px',
                    filter: 'blur(20px)',
                    zIndex: 0,
                    objectFit: 'cover',
                    aspectRatio: '20/9',

                }}
            />
            </div>)} */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    // height: '200px',
                    position: 'relative',
                    aspectRatio: '20/9',
                }}
            >
                {eventImage ? (
                    <img
                        src={eventImage}
                        alt="Event"
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px',
                        }}
                    />
                ) : eventimagestatus === DataStatus.loading ? (
                    <LoadingBox />
                ) : (
                    <LoadingBox />
                )}
                <DetailsBox>
                    {event.favorited ? (
                        <FavoriteRoundedIcon
                            sx={{
                                marginRight: '4px',
                                fontSize: 16,
                                color: 'text.favorite',
                            }}
                        />
                    ) : (
                        <StyledFavoriteBorderRoundedIcon
                            style={{ marginRight: 5, fontSize: 16 }}
                        />
                    )}
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        fontSize={12}
                    >
                        {event.favorites_count}
                    </Typography>
                    <Box width={20} />
                    <StyledRepeatRoundedIcon
                        style={{ marginRight: 5, fontSize: 16 }}
                    />
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        fontSize={12}
                    >
                        {event.post_count}
                    </Typography>
                    <Box width={20} />
                    <StyledPersonOutlineRoundedIcon
                        style={{ marginRight: 5, fontSize: 16 }}
                    />
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        fontSize={12}
                    >
                        {event.attendee_count}
                    </Typography>
                </DetailsBox>
            </div>
            <StyledCardContent>
                <Box>
                    <Box display="flex" alignItems="center">
                        {event.is_host ? (
                            <HostBox
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: '4px',
                                    mr: '8px',
                                }}
                            >
                                <SecurityRoundedIcon
                                    sx={{
                                        color: 'background.primary',
                                        fontSize: 12,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: 'background.primary',
                                        ml: '4px',
                                    }}
                                >
                                    Host
                                </Typography>
                            </HostBox>
                        ) : (
                            event.attending && (
                                <AttendingBox
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: '4px',
                                    }}
                                >
                                    <PersonRoundedIcon
                                        sx={{
                                            fontSize: 14,
                                            color: 'background.primary',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: 'background.primary',
                                            ml: '4px',
                                        }}
                                    >
                                        Attending
                                    </Typography>
                                </AttendingBox>
                            )
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                alignItems: 'top',
                                mt: '8px',
                            }}
                        >
                            <AccountAvatarSubView
                                user={event.primary_host}
                                userimage={userImage}
                                isUserImageLoading={isUserImageLoading}
                                handleAccountPopperClose={
                                    handleAccountPopperClose
                                }
                                handleAccountPopperOpen={
                                    handleAccountPopperOpen
                                }
                                following={event.primary_host.following}
                                height={40}
                                width={40}
                            />
                        </Box>
                        <Box
                            sx={{
                                ml: '8px',
                                height: '100%',
                                float: 'right',
                                alignItems: 'top',
                            }}
                        >
                            <EventTitle
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                {event.title}
                            </EventTitle>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    // fontWeight: 'bold',
                                    color: 'text.selected',
                                }}
                            >
                                {iso2eventtime(
                                    event.event_time,
                                    'long',
                                    'short'
                                )}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: 'text.primary',
                                    }}
                                >
                                    {event.primary_host.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        ml: '4px',
                                        color: 'text.secondary',
                                    }}
                                >
                                    @{event.primary_host.username}
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    color: 'text.secondary',
                                }}
                            >
                                {event.primary_host.follower_size} followers • 0
                                events
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </StyledCardContent>
        </EventCard>
    )
}

export default EventSubView
