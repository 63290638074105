import { useNavigate } from 'react-router-dom'
import { User } from '../../models/User'
import { useAppState } from '../../AppStateProvider'
import { useEffect, useState } from 'react'
import { DataStatus } from '../../models/Dataloading'
import { followUser, grabUserPhoto, unfollowUser } from '../../api/RestUser'
import { getEvents, grabEventPhoto } from '../../api/RestEvent'
import { Box, Typography } from '@mui/material'
import { navigateToAccount } from '../../utils/NavigationFunctions'
import {
    FollowButton,
    LoadingBox,
    PrimaryDivider,
    UnfollowButton,
    UserPhotoCircle,
} from '../Components/StyledComponents'
import ErrorAccountAvatarView from '../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import { useApiManager } from '../../api/ApiMangerProvider'
import { useQuery, useQueryClient } from 'react-query'

const userphotoheight = 60
const eventphotoheight = 80

interface AccountFullSubViewProps {
    user: User
}
const AccountFullSubView = ({ user }: AccountFullSubViewProps) => {
    const queryClient = useQueryClient()
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [userState, setUserState] = useState<User>(user)
    const [eventImageStatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [userImageStatus, setuserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const {
        data: userImage,
        isLoading: isUserImageLoading,
        error: userImageError,
    } = useQuery(user.image, () => apiManager.grabUserPhoto(user.image))
    const {
        data: eventImage,
        isLoading: isEventImageLoading,
        error: eventImageError,
    } = useQuery(`banner-${user.username}`, () =>
        apiManager.grabUserBannerPhoto(user.username)
    )

    const handleFollow = () => {
        setUserState({
            ...userState,
            follower_size: userState.follower_size + 1,
            following: true,
        })
        try {
            followUser(userState.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleUnfollow = () => {
        setUserState({
            ...userState,
            follower_size: userState.follower_size - 1,
            following: false,
        })
        try {
            unfollowUser(userState.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px 10px 0px 0px',
                    height: eventphotoheight,
                    overflow: 'hidden',
                }}
            >
                {eventImage ? (
                    <img
                        src={eventImage}
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: 0,
                        }}
                    />
                ) : (
                    <LoadingBox
                        sx={{
                            height: '100%',
                            width: '100%',
                            borderRadius: 0,
                            objectFit: 'cover',
                        }}
                    />
                )}
            </div>
            <Box
                style={{
                    position: 'absolute',
                    top: eventphotoheight - userphotoheight / 2,
                    left: '20px',
                }}
            >
                {userImage ? (
                    <UserPhotoCircle
                        includeBorder={true}
                        sx={{
                            height: userphotoheight,
                            width: userphotoheight,
                            borderRadius: '50%',
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            src={userImage}
                            alt="userimage"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </UserPhotoCircle>
                ) : isUserImageLoading ? (
                    <UserPhotoCircle
                        includeBorder={true}
                        sx={{
                            height: userphotoheight,
                            width: userphotoheight,
                            borderRadius: '50%',
                            overflow: 'hidden',
                        }}
                    />
                ) : (
                    <ErrorAccountAvatarView
                        size={userphotoheight}
                        includeBorder={true}
                    />
                )}
            </Box>
            <Box
                style={{
                    position: 'absolute',
                    top: eventphotoheight,
                    padding: '10px 0px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'right',
                    justifyContent: 'right',
                }}
            >
                {userState.following ? (
                    <UnfollowButton
                        sx={{ marginRight: '20px' }}
                        onClick={handleUnfollow}
                    >
                        Following
                    </UnfollowButton>
                ) : (
                    <FollowButton
                        sx={{ marginRight: '20px' }}
                        onClick={handleFollow}
                    >
                        Follow
                    </FollowButton>
                )}
            </Box>
            <Box
                style={{
                    padding: '0px 20px',
                    marginTop: userphotoheight / 2 + 10,
                    paddingBottom: '15px',
                }}
            >
                <Typography
                    mt={1}
                    sx={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: 'text.primary',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                    onClick={() =>
                        navigateToAccount(
                            apiManager,
                            queryClient,
                            userState.username,
                            userState,
                            userImage,
                            eventImage,
                            navigate,
                            state,
                            setState,
                            'attending'
                        )
                    }
                >
                    {userState.name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 14,
                        color: 'text.tertiary',
                    }}
                >
                    @{userState.username}
                </Typography>
                {userState.bio && (
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'text.primary',
                        }}
                    >
                        {userState.bio}
                    </Typography>
                )}
                <PrimaryDivider sx={{ mt: '15px', mb: '15px' }} />
                <Box
                    justifyContent={'left'}
                    alignItems={'center'}
                    display={'flex'}
                >
                    <Box
                        sx={{
                            justifyItems: 'center',
                            paddingRight: '15px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                textAlign: 'left',
                                color: 'text.primary',
                            }}
                        >
                            {userState.follower_size}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: 'text.tertiary',
                            }}
                        >
                            Followers
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            justifyItems: 'center',
                            padding: '0px 15px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                textAlign: 'left',
                                color: 'text.primary',
                            }}
                        >
                            {userState.following_size}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: 'text.tertiary',
                            }}
                        >
                            Following
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            justifyItems: 'center',
                            padding: '0px 15px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                textAlign: 'left',
                                color: 'text.primary',
                            }}
                        >
                            {0}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: 'text.tertiary',
                            }}
                        >
                            Posts
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            justifyItems: 'center',
                            padding: '0px 15px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                textAlign: 'left',
                                color: 'text.primary',
                            }}
                        >
                            {0}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: 'text.tertiary',
                            }}
                        >
                            Events
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AccountFullSubView
