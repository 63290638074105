import { AppBar, Box, styled, Toolbar, Typography } from '@mui/material'
import { Ticket } from '../../../models/Ticket'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { useAppState } from '../../../AppStateProvider'
import {
    BackgroundIconButton,
    SecondaryDivider,
    StyledLinearProgress,
} from '../../Components/StyledComponents'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavigateTicket } from '../../../models/NavigateState'
import { useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { iso2date, iso2eventtime } from '../../../utils/DateStringConverter'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { navigateToEvent } from '../../../utils/NavigationFunctions'
import { useApiManager } from '../../../api/ApiMangerProvider'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    borderBottom: '1px solid ' + theme.palette.border.secondary,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
}))

const TicketContainer = styled(Box)(({ theme }) => ({
    background: theme.palette.background.secondary,
    borderRadius: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.8)',
    margin: '32px',
}))

const TicketNumberContainer = styled(Box)(({ theme }) => ({
    background: theme.palette.background.tertiary,
    borderRadius: 50,
    padding: '4px 8px',
}))
const TicketStateContainer = styled(Box)(({ theme }) => ({
    background: theme.palette.background.selected,
    borderRadius: 50,
    padding: '4px 8px',
}))

const TicketPageMobileView = () => {
    const apiManager = useApiManager()
    const location = useLocation()
    const ticketId = useParams<{ id: string }>()
    const locationState = location.state as NavigateTicket
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [ticketState, setTicketState] = useState<Ticket | null>(
        locationState ? locationState.ticket : null
    )
    const [eventImage, setEventImage] = useState<string | null>(
        locationState ? locationState.eventImage : null
    )
    return (
        <Box
            sx={{
                pt: '55px',
                pb: `${navMobileBarHeight}px`,
                position: 'relative',
                zIndex: 1,
                height: '100%',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '80%',
                    position: 'fixed',
                    top: 0,
                    zIndex: -1,
                }}
            >
                {eventImage && (
                    <img
                        src={eventImage}
                        style={{
                            width: `100%`,
                            filter: `blur(40px)`,
                            height: '100%',
                            background:
                                'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                        }}
                    />
                )}
            </Box>
            <TicketPageTopBarMobileView />
            {ticketState && (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <TicketContainer>
                        <Box
                            sx={{
                                display: 'flex',
                                p: '16px',
                            }}
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                                height={30}
                            />
                            <Box flex={1} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'top',
                                    height: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: 'text.disabled',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    No. #
                                    {ticketState ? ticketState.id : '000000'}
                                </Typography>
                                <Box flex={1} />
                            </Box>
                        </Box>
                        {/* <Typography
                sx={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: 'text.primary',
                }}>
                    {ticketState.event.title}
                </Typography> */}
                        <SecondaryDivider
                            sx={{
                                borderStyle: 'dashed',
                            }}
                        />
                        <Box
                            sx={{
                                p: '32px 0px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            <Box>
                                <QRCodeSVG
                                    value={ticketState.id.toString()}
                                    size={150}
                                    bgColor="#ffffff"
                                    fgColor="#000000"
                                    level="H"
                                />
                                <Box
                                    sx={{
                                        width: '100%',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <TicketStateContainer
                                        sx={{
                                            mt: '8px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: 'text.primary',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {ticketState.state.toUpperCase()}
                                        </Typography>
                                    </TicketStateContainer>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <SecondaryDivider
                                sx={{
                                    borderStyle: 'dashed',
                                    flexGrow: 1,
                                }}
                            />
                            <TicketNumberContainer
                                sx={{
                                    m: '8px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: 'text.tertiary',
                                    }}
                                >
                                    1 of 1
                                </Typography>
                            </TicketNumberContainer>
                            <SecondaryDivider
                                sx={{
                                    borderStyle: 'dashed',
                                    flexGrow: 1,
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                p: '24px 16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: 'text.tertiary',
                                }}
                            >
                                TICKET HOLDER
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                    color: 'text.primary',
                                }}
                            >
                                {ticketState.current_owner.name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mt: '16px',
                                    color: 'text.tertiary',
                                }}
                            >
                                EVENT TITLE
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: 'text.primary',
                                }}
                            >
                                {ticketState.event.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: 'text.selected',
                                    fontWeight: 'bold',
                                }}
                                onClick={() =>
                                    navigateToEvent(
                                        apiManager,
                                        ticketState.event,
                                        eventImage,
                                        navigate,
                                        state,
                                        setState,
                                        'details'
                                    )
                                }
                            >
                                Event Listing
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mt: '16px',
                                    color: 'text.tertiary',
                                }}
                            >
                                TICKET TYPE
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: 'text.primary',
                                }}
                            >
                                General Admission
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: '16px',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.tertiary',
                                        }}
                                    >
                                        EVENT DATE
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.primary',
                                        }}
                                    >
                                        {iso2eventtime(
                                            ticketState.event.event_time,
                                            'short',
                                            'long'
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.selected',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Add to calendar
                                    </Typography>
                                </Box>
                                <Box flex={1} />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.tertiary',
                                        }}
                                    >
                                        EVENT LOCATION
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.primary',
                                        }}
                                    >
                                        {ticketState.event.address.city},{' '}
                                        {ticketState.event.address.state}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.selected',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={() =>
                                            window.open(
                                                'https://maps.google.com?q=' +
                                                    ticketState.event.location
                                                        .x +
                                                    ',' +
                                                    ticketState.event.location.y
                                            )
                                        }
                                    >
                                        View in map
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </TicketContainer>
                </Box>
            )}
        </Box>
    )
}

const TicketPageTopBarMobileView = () => {
    const location = useLocation()
    const ticketId = useParams<{ id: string }>()
    const locationState = location.state as NavigateTicket
    const { state, setState } = useAppState()
    const navigate = useNavigate()

    return (
        <TopBar
            sx={{
                overflow: 'hidden',
            }}
        >
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                sx={{
                    minHeight: '55px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    position: 'relative',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </Typography>
                <Typography
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                >
                    Event tickets
                </Typography>
            </Toolbar>
        </TopBar>
    )
}

export default TicketPageMobileView
