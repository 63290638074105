import { Box, IconButton, styled } from '@mui/material'
import FeedTopBarMobileView from './FeedTopBarMobileView'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom'
import { NavigateFeedState } from '../../../models/NavigateState'
import { useAppState } from '../../../AppStateProvider'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { getPosts } from '../../../api/RestPost'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import PostView from '../../PostView/PostView'
import EmptyPostsView from '../../Components/StaticPages/EmptyView/EmptyPostsView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { use } from 'marked'
import FeedSearchMobileView from './FeedSearchMobileView'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import FeedDrawerMobileView from './FeedDrawerMobileView'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useMobile } from '../../../utils/MobileProvider'

const PostIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.selected,
    padding: '16px',
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

const FeedMobileView = () => {
    const apiManger = useApiManager()
    const location = useLocation()
    const navigate = useNavigate()
    console.log(performance.getEntriesByType('navigation')[0])
    const initialpoststateData = sessionStorage.getItem('isRefreshed')
        ? null
        : (location.state as NavigateFeedState | null)
    const { state, setState } = useAppState()
    const [offset, setOffset] = useState(
        initialpoststateData ? initialpoststateData.poststate.length : 0
    )
    const [postState, setPostState] = useState<Post[]>(
        initialpoststateData ? initialpoststateData.poststate : []
    )
    const [postloaded, setPostLoaded] = useState<DataStatus>(
        initialpoststateData ? DataStatus.success : DataStatus.loading
    )
    const [postLoadMore, setPostLoadMore] = useState<DataStatus>(
        DataStatus.none
    )
    const [showSearch, setShowSearch] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false)
    // Fetches posts from the server with every offset change
    // useEffect(() => {
    //     document.body.style.overflow = 'auto';
    // },[])
    // useQuery('feed',
    //     () => apiManger.getPosts(null, null, null, 10, offset),
    //     {
    //         onSuccess: (data) => {
    //             setPostState(data.posts)
    //             setOffset(offset + data.posts.length)
    //             setPostLoaded(DataStatus.success)
    //         },
    //         onError: (error) => {
    //             setPostLoaded(DataStatus.failure)
    //             console.log(error)
    //         },
    //         cacheTime: 0,
    //         staleTime: 0,
    //     },

    // )
    // const {
    //     data,
    //     fetchNextPage,
    // } = useInfiniteQuery('feed',
    //     ({ pageParam = 0 }) => {
    //         return apiManger.getPosts(null, null, null, 10, offset)},
    //     {
    //         getNextPageParam: (lastPage) => {
    //             console.log(lastPage)
    //             return lastPage.posts.length > 0 ? lastPage.posts.length : false
    //         },
    //         onSuccess: (data) => {
    //             const allPosts = data.pages.flatMap((page) => page.posts);
    //             setPostState(allPosts);
    //             setPostLoaded(DataStatus.success)
    //             setPostLoadMore(DataStatus.success)
    //             setOffset(offset + allPosts.length)
    //         },
    //         onError: (error) => {
    //             setPostLoaded(DataStatus.failure)
    //             console.log(error)
    //         },
    //     }
    // )

    useEffect(() => {
        if (postLoadMore === DataStatus.loading) {
            apiManger
                .getPosts(null, null, null, 5, offset)
                .then((response) => {
                    console.log(offset)
                    setPostState((prevState) => {
                        navigate(location.pathname, {
                            state: {
                                poststate: [...prevState, ...response.posts],
                            },
                            replace: true,
                        })
                        return [...prevState, ...response.posts]
                    })
                    setOffset(offset + response.posts.length)
                    setPostLoadMore(DataStatus.success)
                    console.log(postState)
                })
                .catch((error) => {
                    setPostLoadMore(DataStatus.failure)
                })
        }
    }, [postLoadMore])

    // const reloadRef = useRef<HTMLDivElement | null>(null)
    // useEffect(() => {
    //     if (!reloadRef.current || !postState) return

    //     const observer = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting && postState.length > 0) {
    //                 setPostLoadMore(DataStatus.loading)
    //                 fetchNextPage()
    //             }
    //         },
    //         { root: null, threshold: 1.0 }
    //     )

    //     observer.observe(reloadRef.current)

    //     return () => {
    //         if (reloadRef.current) {
    //             observer.unobserve(reloadRef.current)
    //         }
    //     }
    // }, [reloadRef.current])
    useEffect(() => {
        if (postloaded === DataStatus.loading) {
            apiManger
                .getPosts(null, null, null, 10, offset)
                .then((response) => {
                    setPostState((prevState) => [
                        ...prevState,
                        ...response.posts,
                    ])
                    setOffset(offset + response.posts.length)
                    setPostLoaded(DataStatus.success)
                })
                .catch((error) => {
                    setPostLoaded(DataStatus.failure)
                    console.log(error)
                })
        }
    }, [])
    // useEffect(() => {
    //     if (offset === 0 && initialpoststateData !== null) {
    //         setPostLoaded(DataStatus.success)
    //         setPostState(initialpoststateData.poststate)
    //         setOffset(postState.length)
    //     } else {
    //         try {
    //             apiManger
    //                 .getPosts(null, null, null, 10, offset)
    //                 .then((response) => {
    //                     setPostState((prevState) => [
    //                         ...prevState,
    //                         ...response.posts,
    //                     ])
    //                     setOffset(offset + response.posts.length)
    //                     setPostLoaded(DataStatus.success)
    //                 })
    //                 .catch((error) => {
    //                     setPostLoaded(DataStatus.failure)
    //                     console.log(error)
    //                 })
    //         } catch (error) {
    //             setPostLoaded(DataStatus.failure)
    //             console.log(error)
    //         }
    //     }
    // }, [])
    return (
        <Box>
            {showSearch ? (
                <FeedSearchMobileView
                    showSearch={showSearch}
                    setShowSearch={setShowSearch}
                />
            ) : (
                <>
                    <FeedDrawerMobileView
                        showDrawer={showDrawer}
                        setShowDrawer={setShowDrawer}
                    />
                    <FeedTopBarMobileView
                        setShowDrawer={setShowDrawer}
                        setShowSearch={setShowSearch}
                    />
                    <Box
                        sx={{
                            mt: '60px',
                            mb: `${navMobileBarHeight}px`,
                            position: 'relative',
                        }}
                    >
                        {/* <Box
                    sx={{
                        position: 'fixed',
                        top: '0',
                        right: '0',
                        padding: '16px',
                        height: 'calc(100vh -  100px)',
                        zIndex: '100',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        display: 'flex',
                        pointerEvents: 'none',
                    }}
                >
                    <PostIconButton>
                        <AddRoundedIcon />
                    </PostIconButton>
                </Box> */}
                        {postloaded === DataStatus.loading ? (
                            <Box
                                sx={{
                                    pt: '8px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <StyledCircularProgress />
                            </Box>
                        ) : postloaded === DataStatus.failure ? (
                            <ErrorTabView />
                        ) : (
                            postloaded === DataStatus.success &&
                            postState && (
                                <>
                                    {postState.length > 0 ? (
                                        <Box>
                                            {postState.map((post) => (
                                                <Box>
                                                    <PostMobileView
                                                        post={post}
                                                    />
                                                    <PrimaryDivider />
                                                </Box>
                                            ))}
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                    <FeedLoadMoreView
                                        setPostLoadMore={setPostLoadMore}
                                        postLoadMore={postLoadMore}
                                    />
                                </>
                            )
                        )}
                    </Box>
                </>
            )}
        </Box>
    )
}

interface FeedLoadMoreViewProps {
    setPostLoadMore: (status: DataStatus) => void
    postLoadMore: DataStatus
}
const FeedLoadMoreView = ({
    setPostLoadMore,
    postLoadMore,
}: FeedLoadMoreViewProps) => {
    const reloadRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (!reloadRef.current) return

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setPostLoadMore(DataStatus.loading)
                }
            },
            { root: null, threshold: 1.0 }
        )

        observer.observe(reloadRef.current)

        return () => {
            if (reloadRef.current) {
                observer.unobserve(reloadRef.current)
            }
        }
    }, [reloadRef.current])
    return (
        <Box
            ref={reloadRef}
            sx={{
                height: '80px',
            }}
        >
            {postLoadMore === DataStatus.loading && (
                <Box
                    sx={{
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            )}
        </Box>
    )
}
export default FeedMobileView
