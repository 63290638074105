import React from 'react'
import { NavigateCreatePostState } from './NavigateState'
import { Event } from './Event'
import { User } from './User'
import { Post } from './Post'

export interface AppState {
    name: string
    username: string
    usertoken: string
    useremail: string
    userpassword: string
    userimagelink: string
    userimage: string | null
    showprogress: boolean
    progressfinished: boolean
    progress: number
    progresstransition: boolean
    progressintervalids: NodeJS.Timer[]
    location: { latitude: number | null; longitude: number | null }
    isMobile: boolean
    googlemapsinteractable: boolean
    open: boolean
    drawertemporaryopen: boolean
    tempCreatePost: NavigateCreatePostState
    recentEvents: Event[]
    recentUsers: User[]
    recentPosts: Post[]
}

export const defaultAppState: AppState = {
    name: '',
    username: '',
    usertoken: '',
    useremail: '',
    userpassword: '',
    userimagelink: '',
    userimage: null,
    showprogress: false,
    progressfinished: false,
    progresstransition: false,
    progressintervalids: [],
    progress: 0,
    location: { latitude: null, longitude: null },
    isMobile: false,
    googlemapsinteractable: true,
    open: true,
    drawertemporaryopen: false,
    tempCreatePost: {
        event: null,
        eventImage: null,
        eventHostImage: null,
        caption: '',
    },
    recentEvents: [],
    recentUsers: [],
    recentPosts: [],
}

interface AppStateContextProps {
    state: AppState
    setState: React.Dispatch<React.SetStateAction<AppState>>
}

export const AppStateContext = React.createContext<
    AppStateContextProps | undefined
>(undefined)
