import { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    Tab,
    Tabs,
    Divider,
    List,
    InputBase,
    Button,
    ButtonBase,
} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { NavigatePostState } from '../../models/NavigateState'
import { string } from 'yup'
import { DataStatus } from '../../models/Dataloading'
import { Post } from '../../models/Post'
import { set } from 'date-fns'
import PostItemCommentView from './PostItemCommentView'
import {
    PrimaryDivider,
    SecondaryTypography,
    StyledChip,
    StyledCircularProgress,
    TertiaryTypography,
} from '../Components/StyledComponents'
import { Comment } from '../../models/Comment'
import CommentView from './CommentView'
import { styled } from '@mui/material/styles'
import PostEventSubView from '../SideViews/PostEventSideView'
import { Create } from '@mui/icons-material'
import CreateCommentView from './CreateCommentView'
import SideLinksView from '../SideViews/SideLinksView'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import SortPopper from '../Components/SortPopper'
import EmptyCommentsView from '../Components/StaticPages/EmptyView/EmptyCommentsView'
import ErrorTabView from '../Components/StaticPages/ErrorView/ErrorTabView'
import { useAppState } from '../../AppStateProvider'
import PostMainMobileView from './PostMobileView'
import { useQuery } from 'react-query'
import { useApiManager } from '../../api/ApiMangerProvider'
import { navMobileBarHeight } from '../../layouts/DefaultLayout/NavMobileBar'

interface StyledArrowForwardIosRoundedIconProps
    extends React.ComponentProps<typeof ArrowForwardIosRoundedIcon> {
    open: boolean
}
const StyledArrowForwardIosRoundedIcon = styled(
    (props: StyledArrowForwardIosRoundedIconProps) => (
        <ArrowForwardIosRoundedIcon {...props} />
    )
)(({ theme, open }) => ({
    color: theme.palette.text.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const PostMainView = () => {
    const { state, setState } = useAppState()
    return (
        <>{state.isMobile ? <PostMainMobileView /> : <PostMainDesktopView />}</>
    )
}
const PostMainDesktopView = () => {
    const apiManager = useApiManager()
    const { uuid } = useParams<{ uuid: string }>()
    const location = useLocation()
    const locationState = location.state as NavigatePostState | null
    const [postState, setPostState] = useState<Post | null>(
        locationState ? locationState.post : null
    )
    const [comments, setComments] = useState<Comment[] | null>(
        locationState ? locationState.comments : null
    )
    const [postImage, setPostImage] = useState<string | null>(
        locationState ? locationState.postImage : null
    )
    const [postAuthorImage, setPostAuthorImage] = useState<string | null>(
        locationState ? locationState.postAuthorImage : null
    )
    const [postAuthorImageLoaded, setPostAuthorImageLoaded] =
        useState<DataStatus>(DataStatus.loading)
    const [postImageLoaded, setPostImageLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Top')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const {
        data: postQueryImage,
        isLoading: isEventImageLoading,
        error: userImageError,
    } = useQuery(
        postState ? postState.image : '',
        function () {
            if (postState && !postImage) {
                return apiManager.grabPostPhoto(postState.image)
            }
        },
        { enabled: !!postState && !!!postImage }
    )
    useEffect(() => {
        if (postQueryImage && !postImage) {
            setPostImage(postQueryImage)
        }
    }, [postQueryImage, postImage, postState])

    useEffect(() => {
        if (locationState !== null) {
            setPostImage(locationState.postImage)
            setPostAuthorImage(locationState.postAuthorImage)
            setPostState(locationState.post)
            setComments(locationState.comments)
            setPostLoaded(DataStatus.success)
            setPostImageLoaded(DataStatus.success)
            setPostAuthorImageLoaded(DataStatus.success)
        } else {
            setPostLoaded(DataStatus.failure)
            setPostImageLoaded(DataStatus.failure)
            setPostAuthorImageLoaded(DataStatus.failure)
        }
    }, [])

    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }
    return (
        <Box
            id="post-main-view"
            style={{
                height: '100%',
                width: '100%',
                position: 'relative',
                justifyContent: 'center',
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    maxWidth: 1300,
                    marginRight: '0',
                    height: '100%',
                    mt: `${navMobileBarHeight + 8}px`,
                    mb: '50px',
                    zIndex: 0,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {postloaded === DataStatus.success && postState ? (
                    <Box
                        sx={{
                            width: 'calc(100% - 400px)',
                            paddingLeft: '20px',
                        }}
                    >
                        <PostItemCommentView
                            post={postState}
                            postImage={postImage}
                            postAuthorImage={postAuthorImage}
                            postloaded={postloaded}
                            postAuthorImageLoaded={postAuthorImageLoaded}
                            postImageLoaded={postImageLoaded}
                            comments={comments}
                        />

                        {/* <PrimaryDivider
                            sx={{ marginTop: '8px', marginBottom: '4px' }}
                        /> */}
                        <Box
                            sx={{
                                mt: '8px',
                                mb: '16px',
                            }}
                        >
                            <CreateCommentView
                                post={postState}
                                comments={comments}
                                setComments={setComments}
                            />
                        </Box>

                        {comments && postState ? (
                            comments.length === 0 ? (
                                <EmptyCommentsView />
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mt: '8px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <SortPopper
                                            sortOptions={['Top', 'Latest']}
                                            sortbyState={sortbyState}
                                            setSortByState={setSortByState}
                                            openpopover={sortbyShowState}
                                            setOpenPopover={setSortbyShowState}
                                            anchorEl={sortAnchorEl}
                                            setAnchorEl={setSortAnchorEl}
                                        />
                                        <StyledChip
                                            sx={{
                                                cursor: 'pointer',
                                                background: (theme) =>
                                                    theme.palette.background
                                                        .tertiary,
                                                borderRadius: '8px',
                                            }}
                                            component={ButtonBase}
                                            onClick={handleSortPopperOpen}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    color: 'text.secondary',
                                                }}
                                            >
                                                Sort: {sortbyState}
                                            </Typography>
                                            <StyledArrowForwardIosRoundedIcon
                                                sx={{ fontSize: 12, ml: '4px' }}
                                                open={sortbyShowState}
                                            />
                                        </StyledChip>
                                    </Box>

                                    {comments.map((comment) => (
                                        <Box sx={{ m: '8px 0px' }}>
                                            <CommentView
                                                post={postState}
                                                comment={comment}
                                            />
                                        </Box>
                                    ))}
                                </>
                            )
                        ) : (
                            <ErrorTabView />
                        )}
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                        }}
                    >
                        <StyledCircularProgress />
                    </Box>
                )}

                <Box
                    style={{
                        width: '340px',
                        height: '100%',
                        justifyContent: 'center',
                        position: 'sticky',
                        top: `${navMobileBarHeight + 8}px`,
                        padding: '0px 20px',
                    }}
                >
                    {postloaded === DataStatus.success && postState !== null ? (
                        <Box sx={{ height: '100%' }}>
                            <PostEventSubView event={postState?.event} />
                            <SideLinksView />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <StyledCircularProgress />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default PostMainView
