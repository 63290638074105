import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
    AppBar,
    IconButton,
    Toolbar,
    InputBase,
    Box,
    Popover,
    Typography,
    useMediaQuery,
    useTheme,
    LinearProgress,
    IconButtonProps,
} from '@mui/material'
import { DataStatus } from '../../models/Dataloading'
import { grabUserPhoto } from '../../api/RestUser'
import { Link as RouterLink } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import EventRoundedIcon from '@mui/icons-material/EventRounded'
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded'
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { hexToRGBA } from '../../utils/haxToRGBA'
import { navigateToCreatePost } from '../../utils/NavigationFunctions'
import { UserPhotoCircle } from '../../views/Components/StyledComponents'
import ErrorAccountAvatarView from '../../views/Components/StaticPages/ErrorView/ErrorAccountAvatarView'

const topbarcolor = hexToRGBA('#111111', 0.6)
const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    borderBottom: '1px solid ' + theme.palette.border.secondary,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    // backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    // boxShadow: 'none',
    // marginRight: 6,
}))

const SearchButton = styled(SearchRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.tertiary,
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

const Search = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
    marginRight: theme.spacing(2),
    marginLeft: 15,
    paddingLeft: 10,
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: 5,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 600,
        },
    },
}))

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

interface StyledIconButtonProps extends IconButtonProps {
    component?: React.ElementType
    href?: string
}

const StyledIconButton = styled((props: StyledIconButtonProps) => (
    <IconButton {...props} />
))(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

interface LogoIconButtonProps extends IconButtonProps {
    component?: React.ElementType
    href?: string
}

const LogoIconButton = styled((props: LogoIconButtonProps) => (
    <IconButton {...props} />
))(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

interface TopViewProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    drawertemporaryopen: boolean
    setDrawerTemporaryOpen: React.Dispatch<React.SetStateAction<boolean>>
    showprogress: boolean
    setShowProgress: React.Dispatch<React.SetStateAction<boolean>>
    progress: number
    setProgress: React.Dispatch<React.SetStateAction<number>>
}

const TopBarView = ({
    open,
    setOpen,
    setDrawerTemporaryOpen,
    showprogress,
    setShowProgress,
    progress,
    setProgress,
}: TopViewProps) => {
    const { state, setState } = useAppState()
    const [userimage, setUserImage] = React.useState<string | null>(
        state.userimage
    )
    const navigate = useNavigate()
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const timerRef = useRef<number | null>(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [popoverContent, setPopoverContent] = useState('')

    const handlePopoverOpen = (event: any, content: string) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setPopoverContent(content)
            setOpenPopover(true)
        }, 400)
    }
    const handlePopoverClose = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
            timerRef.current = null
        }
        setAnchorEl(null)
        setOpenPopover(false)
    }
    const [openpopover, setOpenPopover] = useState(false)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    useEffect(() => {
        try {
            grabUserPhoto('', state.usertoken)
                .then((response) => {
                    setUserImage(response)
                    setUserImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setUserImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.error(error)
            setUserImageStatus(DataStatus.failure)
        }
    }, [])

    const navigateToDiscovery = (href: string) => {
        console.log('clicked', href)
        setShowProgress(true)
        setProgress(0)
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0
                }
                const diff = Math.random() * 2
                return Math.min(oldProgress + diff, 100)
            })
        }, 1000)
        if (href === '/app/createevent') {
            setShowProgress(true)
            clearInterval(timer)
            setProgress(100)
            setTimeout(() => {
                setShowProgress(false)
                setProgress(0)
                navigate(href, { state: { eventstate: null, code: 500 } })
            }, 500)
        }
    }

    return (
        // <div>
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 10, paddingRight: 10 }}
            >
                <StyledIconButton
                    onClick={function () {
                        isSmallScreen
                            ? setState({ ...state, drawertemporaryopen: true })
                            : setState((prevItems) => ({
                                  ...prevItems,
                                  open: !prevItems.open,
                              }))
                    }}
                >
                    <MenuRoundedIcon />
                </StyledIconButton>

                <LogoIconButton
                    component={RouterLink}
                    href="/app/home/"
                    disableRipple
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                        height={30}
                    />
                </LogoIconButton>

                <Box sx={{ flexGrow: 1 }} />
                <Search style={{ height: 40 }}>
                    <SearchButton sx={{ height: 40 }} />
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        style={{ height: 40 }}
                    />
                </Search>
                <Box sx={{ flexGrow: 1 }} />
                <StyledIconButton
                    sx={{ marginLeft: '5px', marginRight: '5px' }}
                    onMouseEnter={(e) => handlePopoverOpen(e, 'Create a post')}
                    onMouseLeave={handlePopoverClose}
                    onClick={() => {
                        navigateToCreatePost(
                            null,
                            null,
                            null,
                            navigate,
                            state,
                            setState
                        )
                    }}
                >
                    <PostAddRoundedIcon />
                </StyledIconButton>
                <StyledIconButton
                    onClick={() => navigateToDiscovery('/app/createevent')}
                    sx={{ marginLeft: '5px', marginRight: '5px' }}
                    onMouseEnter={(e) =>
                        handlePopoverOpen(e, 'Create an event')
                    }
                    onMouseLeave={handlePopoverClose}
                >
                    <EventRoundedIcon />
                </StyledIconButton>
                <StyledIconButton
                    sx={{ marginLeft: '5px', marginRight: '5px' }}
                    onMouseEnter={(e) =>
                        handlePopoverOpen(e, 'Show notifications')
                    }
                    onMouseLeave={handlePopoverClose}
                >
                    <NotificationsNoneRoundedIcon />
                </StyledIconButton>
                <Box
                    sx={{
                        marginLeft: '5px',
                        marginRight: '5px',
                        padding: '3px',
                    }}
                >
                    {state.userimage ? (
                        <UserPhotoCircle
                            sx={{
                                width: 35,
                                height: 35,
                                borderRadius: '50%',
                                overflow: 'hidden',
                            }}
                            includeBorder={false}
                        >
                            <img
                                src={state.userimage}
                                alt="User"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </UserPhotoCircle>
                    ) : (
                        <ErrorAccountAvatarView
                            size={35}
                            includeBorder={false}
                        />
                    )}
                </Box>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={openpopover}
                    anchorEl={anchorEl}
                    disableRestoreFocus
                >
                    <Typography sx={{ padding: 1 }}>
                        {popoverContent}
                    </Typography>
                </Popover>
            </Toolbar>
        </TopBar>
    )
}

const TopBarMobileView = () => {
    const { state, setState } = useAppState()
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 10, paddingRight: 10 }}
            >
                <StyledIconButton>
                    <MenuRoundedIcon />
                </StyledIconButton>
                <LogoIconButton
                    component={RouterLink}
                    href="/app/home/"
                    disableRipple
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                        height={30}
                    />
                </LogoIconButton>
            </Toolbar>
        </TopBar>
    )
}

export default TopBarView
