import { useState } from 'react'
import { styled } from '@mui/system'
import { Switch, SwitchProps, Button, Typography, Box } from '@mui/material'
import { FilterData } from '../../../models/Filter'
import { PrimaryDivider } from '../../Components/StyledComponents'

export const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? theme.palette.background.selected
                        : theme.palette.background.selected,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.background.selected,
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
    },
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

interface AttendeesPickerViewProps {
    tempfilterstate: FilterData
    setTempFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    width: string
}

const FollowersPickerView = ({
    tempfilterstate,
    setTempFilterState,
    width,
}: AttendeesPickerViewProps) => {
    const [value, setValue] = useState(tempfilterstate.following)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked)
        setTempFilterState({
            ...tempfilterstate,
            following: event.target.checked,
        })
    }
    return (
        <Box sx={{ width: width }}>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.tertiary',
                    fontWeight: 'bold',
                }}
            >
                FOLLOWERS ATTENDING
            </Typography>
            <PrimaryDivider
                sx={{
                    m: '8px 0px',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    mt: '16px',
                    alignContent: 'center',
                }}
            >
                <SecondaryTypography sx={{ fontSize: 14 }}>
                    Filter events for if following accounts are attending
                </SecondaryTypography>
                <Box flex={1} />
                <IOSSwitch checked={value} onChange={handleChange} />
            </Box>
        </Box>
    )
}

export default FollowersPickerView
