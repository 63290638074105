import React, { useEffect, useState, useRef } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { Box, ButtonBase, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Comment } from '../../../models/Comment'
import { DataStatus } from '../../../models/Dataloading'
import { grabUserPhoto } from '../../../api/RestUser'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'

import { timeDifferenceFromCurrentTime } from '../../../utils/TimeDifference'
import AccountPopoverView from '../../AccountView/AccountPopoverView'
import AccountAvatarSubView from '../../AccountView/AccountAvatarSubView'
import { set } from 'date-fns'
import { favoriteComment } from '../../../api/RestComment'
import { Post } from '../../../models/Post'
import { User } from '../../../models/User'
import { navigateToAccount } from '../../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { useQuery, useQueryClient } from 'react-query'
import CommentOptionsMobileView from './CommentOptionsMobileView'

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledChatBubbleOutlineRoundedIcon = styled(ChatBubbleOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const FollowIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.text.primary,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.primary,
    },
}))

const CommentCard = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: theme.palette.background.primary,
    padding: 16,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const StyledChip = styled(Box)(({ theme }) => ({
    color: theme.palette.text.secondary,
    borderRadius: 50,
    padding: '5px 10px',
    display: 'flex',
    backgroundColor: 'transparent',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

interface CommentViewProps {
    post: Post
    comment: Comment
    setSelectedComment: React.Dispatch<React.SetStateAction<Comment | null>>
    setCommentAuthorImage: React.Dispatch<
        React.SetStateAction<string | null | undefined>
    >
    setShowExpandComment: React.Dispatch<React.SetStateAction<boolean>>
}

const CommentMobileView = ({
    comment,
    post,
    setSelectedComment,
    setCommentAuthorImage,
    setShowExpandComment,
}: CommentViewProps) => {
    const queryClient = useQueryClient()
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const navigator = useNavigate()
    const [userState, setUserState] = useState<User>(comment.author)
    const [following, setFollowing] = useState<boolean>(
        comment.author.following
    )
    const [commentState, setCommentState] = useState<Comment>(comment)
    const [userImageStatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const [showOptions, setShowOptions] = useState(false)
    const {
        data: userImage,
        isLoading: isUserImageLoading,
        error: userImageError,
    } = useQuery(userState.image, () =>
        apiManager.grabUserPhoto(userState.image)
    )
    const handleReply = () => {
        setSelectedComment(comment)
        setCommentAuthorImage(userImage)
        setShowExpandComment(true)
    }
    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }

    const handleFavorite = () => {
        if (!commentState.favorited) {
            setCommentState({
                ...commentState,
                favorited: true,
                favorites_count: commentState.favorites_count + 1,
            })
            try {
                favoriteComment(post.id, comment.id, state.usertoken)
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            setCommentState({
                ...commentState,
                favorited: false,
                favorites_count: commentState.favorites_count - 1,
            })
            try {
                favoriteComment(post.id, comment.id, state.usertoken)
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <CommentCard position={'relative'}>
            <CommentOptionsMobileView
                post={post}
                comment={comment}
                showOptions={showOptions}
                setShowOptions={setShowOptions}
            />
            <Box display="flex" position={'relative'} height={'100%'}>
                <Box
                    sx={{
                        height: '100%',
                        alignItems: 'top',
                        marginTop: '4px',
                    }}
                >
                    <AccountAvatarSubView
                        user={comment.author}
                        userimage={userImage}
                        isUserImageLoading={isUserImageLoading}
                        handleAccountPopperClose={handleAccountPopperClose}
                        handleAccountPopperOpen={handleAccountPopperOpen}
                        following={following}
                        height={35}
                        width={35}
                    />
                    <AccountPopoverView
                        user={userState}
                        setUser={setUserState}
                        openpopover={openAccountPopover}
                        setOpenPopover={setOpenAccountPopover}
                        anchorEl={accountAnchorEl}
                        setAnchorEl={setAccountAnchorEl}
                        timerRef={timerRef}
                    />
                </Box>

                <Box
                    sx={{
                        marginLeft: '4px',
                        height: '100%',
                        width: '100%',
                        alignItems: 'top',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                cursor: 'pointer',
                                color: 'text.primary',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                            onMouseOver={(e) =>
                                !state.isMobile && handleAccountPopperOpen(e)
                            }
                            onMouseLeave={(e) =>
                                !state.isMobile && handleAccountPopperClose()
                            }
                            onClick={() =>
                                navigateToAccount(
                                    apiManager,
                                    queryClient,
                                    userState.username,
                                    userState,
                                    userImage,
                                    null,
                                    navigator,
                                    state,
                                    setState,
                                    'attending'
                                )
                            }
                        >
                            {userState.name}
                        </Typography>

                        {comment.is_host ? (
                            <SecurityRoundedIcon
                                sx={{
                                    color: 'text.host',
                                    fontSize: 18,
                                    ml: '4px',
                                }}
                            />
                        ) : (
                            comment.attending && (
                                <PersonRoundedIcon
                                    sx={{
                                        color: 'text.attending',
                                        fontSize: 18,
                                        ml: '4px',
                                    }}
                                />
                            )
                        )}

                        <Box width={4} />
                        <Typography
                            sx={{ fontSize: 14, color: 'text.secondary' }}
                        >
                            @{userState.username}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                ml: '4px',
                                color: 'text.secondary',
                            }}
                        >
                            {'\u{2022} ' +
                                timeDifferenceFromCurrentTime(
                                    comment.created_at
                                )}
                        </Typography>
                        <Box flex={1} />
                    </Box>
                    <Typography sx={{ fontSize: 14, color: 'text.primary' }}>
                        {comment.body}
                    </Typography>
                    <Box display="flex">
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'relative',
                                height: '31px',
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'absolute',
                                    left: '-10px',
                                }}
                            >
                                <StyledChip
                                    component={ButtonBase}
                                    onClick={handleFavorite}
                                >
                                    {commentState.favorited ? (
                                        <>
                                            <FavoriteRoundedIcon
                                                sx={{
                                                    color: (theme) =>
                                                        theme.palette.text
                                                            .favorite,
                                                    marginRight: '8px',
                                                    fontSize: 16,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    // color: (theme) =>
                                                    //     theme.palette.text.favorite,
                                                    fontSize: 14,
                                                    color: 'text.primary',
                                                }}
                                            >
                                                {commentState.favorites_count}
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <StyledFavoriteBorderRoundedIcon
                                                style={{
                                                    marginRight: 8,
                                                    fontSize: 16,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    color: 'text.primary',
                                                }}
                                            >
                                                {commentState.favorites_count}
                                            </Typography>
                                        </>
                                    )}
                                </StyledChip>
                                <Box width={10} />
                                <StyledChip
                                    component={ButtonBase}
                                    onClick={handleReply}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.primary',
                                        }}
                                    >
                                        Reply
                                    </Typography>
                                </StyledChip>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                        width: '36px',
                    }}
                >
                    <StyledIconButton
                        sx={{
                            position: 'absolute',
                            top: '-6px',
                        }}
                        onClick={() => setShowOptions(true)}
                    >
                        <MoreHorizRoundedIcon sx={{ fontSize: 18 }} />
                    </StyledIconButton>
                    <Box
                        sx={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    ></Box>
                </Box>
            </Box>
        </CommentCard>
    )
}

export default CommentMobileView
