import { Box, styled, Typography } from '@mui/material'
import {
    PrimaryDivider,
    PrimaryTypography,
    SelectTypography,
    StyledCircularProgress,
} from '../Components/StyledComponents'
import { DataStatus } from '../../models/Dataloading'
import { useEffect, useState } from 'react'
import { getEvents } from '../../api/RestEvent'
import { useAppState } from '../../AppStateProvider'
import EventSubPostView from '../EventView/EventSubPostView'
import { Event } from '../../models/Event'
import { User } from '../../models/User'
import ErrorTabView from '../Components/StaticPages/ErrorView/ErrorTabView'
import { useApiManager } from '../../api/ApiMangerProvider'
import EventStringSubView from '../EventView/EventStringSubView'

interface SideTrendingEventsViewProps {}

const Container = styled(Box)(({ theme }) => ({
    // border: `1px solid ${theme.palette.border.primary}`,
    backgroundColor: theme.palette.background.secondary,
    padding: 0,
    borderRadius: 10,
    width: '100%',
}))

const SideTrendingEventsView = ({}: SideTrendingEventsViewProps) => {
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [eventLoaded, setEventLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventState, setEventState] = useState<Event[]>([])
    useEffect(() => {
        if (eventLoaded === DataStatus.loading) {
            try {
                apiManager
                    .getEvents(null, null, null, 5, 5, 0)
                    .then((response) => {
                        setEventState(response.events)
                        setEventLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        console.log(error)
                        setEventLoaded(DataStatus.failure)
                    })
            } catch (error) {
                console.log(error)
                setEventLoaded(DataStatus.failure)
            }
        }
    }, [])
    return (
        <Container sx={{ height: '100%' }}>
            <Box
                sx={{
                    height: '100%',
                    padding: '16px 16px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 12,
                        display: 'flex',
                        fontWeight: 'bold',
                        color: 'text.tertiary',
                    }}
                >
                    TRENDING EVENTS
                </Typography>
                <PrimaryDivider
                    sx={{
                        margin: '8px 0',
                    }}
                />
                {eventLoaded === DataStatus.loading ? (
                    <Box
                        sx={{
                            marginTop: '16px',
                            justifyContent: 'center',
                            display: 'flex',
                            height: '200px',
                        }}
                    >
                        <StyledCircularProgress />
                    </Box>
                ) : eventLoaded === DataStatus.failure ? (
                    <ErrorTabView />
                ) : eventLoaded === DataStatus.success &&
                  eventState.length === 0 ? (
                    <></>
                ) : (
                    <Box marginTop={'16px'}>
                        {eventState.map((event) => (
                            <Box marginBottom={'16px'}>
                                <EventStringSubView event={event} />
                            </Box>
                        ))}

                        <SelectTypography
                            sx={{
                                fontSize: 14,
                                display: 'flex',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                                cursor: 'pointer',
                            }}
                        >
                            View More
                        </SelectTypography>
                    </Box>
                )}
            </Box>
        </Container>
    )
}

export default SideTrendingEventsView
