import { useEffect, useRef, useState } from 'react'
import { Box, Typography, Tab, Tabs, Button, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { Event } from '../../models/Event'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DataStatus } from '../../models/Dataloading'
import {
    AttendingBox,
    HostBox,
    LoadingBox,
    PrimaryDivider,
    PrimaryTypography,
    ScrollBarDiv,
    SecondaryTypography,
    SelectButton,
    StyledCircularProgress,
} from '../Components/StyledComponents'
import { NavigateEventState } from '../../models/NavigateState'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import EventPostsView from './EventPostsView'
import EventPostsSecondaryView from './EventPostSecondaryView'
import EventAttendeesView from './EventAttendeesView'
import EventDetailsView from './EventDetailsView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../Components/StyledComponents/StyledTabView'
import SideLinksView from '../SideViews/SideLinksView'
import { set } from 'date-fns'
import EventPhotosView from './EventPhotosView'
import {
    navigateToEvent,
    navigateToEventPhotos,
    navigateToEventSelectTickets,
} from '../../utils/NavigationFunctions'
import { tab } from '@testing-library/user-event/dist/tab'
import { useAppState } from '../../AppStateProvider'
import { hexToRGBA } from '../../utils/haxToRGBA'
import EventPageMobileView from './EventMobileView'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import { iso2eventtime } from '../../utils/DateStringConverter'
import { useApiManager } from '../../api/ApiMangerProvider'
import { navMobileBarHeight } from '../../layouts/DefaultLayout/NavMobileBar'
import { useDesktopSize } from '../../utils/DesktopSizeProvider'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import BuildRoundedIcon from '@mui/icons-material/BuildRounded'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'

const eventphotoheight = 265

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledRepeatRoundedIcon = styled(RepeatRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledPersonOutlineRoundedIcon = styled(PersonOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const BackgroundBox = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.background.primary,
    color: theme.palette.background.primary,
    width: '100%',
    zIndex: 0,
}))

const PicturesBox = styled(Box)(({ theme }) => ({
    width: '100%',
    borderRadius: 50,
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.6),
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignText: 'center',
    display: 'flex',
    padding: '5px 10px',
    cursor: 'pointer',
}))

const GrabTicketBox = styled(Box)(({ theme }) => ({
    borderRadius: 10,
    backgroundColor: theme.palette.background.secondary,
    // border: '1px solid ' + theme.palette.border.primary,
    // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    padding: '10px 20px',
}))

interface tabDictProps {
    [key: string]: number
}

const EventPageView = () => {
    const { state, setState } = useAppState()
    return (
        <>
            {state.isMobile ? (
                <EventPageMobileView />
            ) : (
                <EventPageDesktopView />
            )}
        </>
    )
}
const EventPageDesktopView = () => {
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const { slug } = useParams<{ slug: string }>()
    const navigate = useNavigate()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const location = useLocation()
    const locationEvent = location.state as NavigateEventState | null
    const eventslugRef = useRef(slug)
    const [eventState, setEventState] = useState<Event | null>(null)
    const [eventImage, setEventImage] = useState<string | null>(null)
    const [eventHostImage, setEventHostImage] = useState<string | null>(null)
    const [eventloaded, setEventLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const theme = useTheme()
    const [value, setValue] = useState(tabIndex)
    const { desktopBreakpoint } = useDesktopSize()
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigate(`/app/event/${slug}/${newValue}`)
    }

    useEffect(() => {
        setValue(tabIndex)
        if (eventslugRef.current !== slug) {
            window.scrollTo(0, 0)
        }
        eventslugRef.current = slug
        if (locationEvent !== null) {
            setEventImage(locationEvent.eventImage)
            setEventLoaded(DataStatus.success)
            setEventState(locationEvent.event)
        } else {
            setEventLoaded(DataStatus.failure)
        }
    }, [window.location.pathname])
    return (
        <>
            {window.location.pathname.includes('images') && <EventPhotosView />}
            {eventloaded === DataStatus.loading ? (
                <Box
                    style={{
                        height: '100%',
                        width: '100%',
                        // overflowY: 'auto',
                        marginTop: 56,
                        justifyContent: 'center',
                        display: 'flex',
                        borderRadius: 2,
                        padding: 10,
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : eventState !== null ? (
                <Box
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        mt: '56px',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height:
                                desktopBreakpoint <= theme.breakpoints.values.md
                                    ? '665px'
                                    : '400px',
                            position: 'absolute',
                            top: 0,
                            zIndex: -1,
                        }}
                    >
                        {eventImage && (
                            <img
                                src={eventImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    opacity: 1,
                                    filter: 'blur(20px)',
                                    zIndex: 1,
                                    WebkitMaskImage:
                                        'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))',
                                    scale: 1.1,
                                }}
                            />
                        )}
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: 1200,
                            display: 'flex',
                            flexDirection: 'row',
                            p: '0px 20px',
                        }}
                    >
                        <Box
                            sx={{
                                width:
                                    desktopBreakpoint >
                                    theme.breakpoints.values.md
                                        ? 'calc(60% - 20px)'
                                        : '100%',
                                padding: '0px 0px',
                                mt: '40px',
                            }}
                        >
                            {desktopBreakpoint <= theme.breakpoints.values.md &&
                                (eventImage ? (
                                    <Box
                                        sx={{
                                            backgroundColor:
                                                'background.primary',
                                            width: `100%`,
                                            borderRadius: '20px',
                                            overflow: 'hidden',
                                            height: eventphotoheight,
                                            mb: '16px',
                                        }}
                                    >
                                        <img
                                            src={eventImage}
                                            style={{
                                                width: `100%`,
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <LoadingBox
                                        sx={{
                                            width: '100%',
                                            borderRadius: '20px',
                                            height: eventphotoheight,
                                            opacity: 1,
                                            zIndex: 1,
                                            objectFit: 'cover',
                                            mb: '16px',
                                        }}
                                    />
                                ))}
                            <Box display="flex" alignItems="center">
                                {eventState.is_host ? (
                                    <HostBox
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mr: '8px',
                                            // p: '4px 20px',
                                            // pl: '16px',
                                        }}
                                    >
                                        <SecurityRoundedIcon
                                            sx={{
                                                color: 'background.primary',
                                                fontSize: 14,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                color: 'background.primary',
                                                ml: '4px',
                                            }}
                                        >
                                            Host
                                        </Typography>
                                    </HostBox>
                                ) : (
                                    eventState.attending && (
                                        <AttendingBox
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <PersonRoundedIcon
                                                sx={{
                                                    color: 'background.primary',
                                                    fontSize: 16,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    color: 'background.primary',
                                                    ml: '4px',
                                                }}
                                            >
                                                Attending
                                            </Typography>
                                        </AttendingBox>
                                    )
                                )}
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: 50,
                                    fontWeight: 800,
                                    textAlign: 'left',
                                    color: 'text.primary',
                                }}
                            >
                                {eventState.title !== ''
                                    ? eventState.title
                                    : 'Event Title'}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    color: 'text.selected',
                                    fontWeight: 'bold',
                                }}
                            >
                                {iso2eventtime(
                                    eventState.event_time,
                                    'long',
                                    'long'
                                )}
                            </Typography>
                            <Box
                                sx={{
                                    alighItems: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: '8px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {eventState.favorited ? (
                                        <FavoriteRoundedIcon
                                            sx={{
                                                marginRight: '4px',
                                                fontSize: 18,
                                                color: 'text.favorite',
                                            }}
                                        />
                                    ) : (
                                        <FavoriteBorderRoundedIcon
                                            sx={{
                                                marginRight: '4px',
                                                fontSize: 18,
                                                color: 'text.primary',
                                            }}
                                        />
                                    )}
                                    <Typography
                                        color="text.primary"
                                        fontSize={14}
                                    >
                                        {eventState.favorites_count} likes
                                    </Typography>
                                </Box>
                                <Box width={20} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    onClick={() =>
                                        navigateToEvent(
                                            apiManager,
                                            eventState,
                                            eventImage,
                                            navigate,
                                            state,
                                            setState,
                                            'posts'
                                        )
                                    }
                                >
                                    <RepeatRoundedIcon
                                        sx={{
                                            marginRight: '4px',
                                            fontSize: 18,
                                            color: 'text.primary',
                                        }}
                                    />
                                    <Typography
                                        color="text.primary"
                                        fontSize={14}
                                    >
                                        {eventState.post_count} posts
                                    </Typography>
                                </Box>
                                <Box width={20} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    onClick={() =>
                                        navigateToEvent(
                                            apiManager,
                                            eventState,
                                            eventImage,
                                            navigate,
                                            state,
                                            setState,
                                            'attendees'
                                        )
                                    }
                                >
                                    <PersonOutlineRoundedIcon
                                        sx={{
                                            marginRight: '4px',
                                            fontSize: 18,
                                            color: 'text.primary',
                                        }}
                                    />

                                    <Typography
                                        color="text.primary"
                                        fontSize={14}
                                    >
                                        {eventState.attendee_count} attendees
                                    </Typography>
                                </Box>
                            </Box>

                            <GrabTicketBox
                                sx={{
                                    alignItems: 'center',
                                    p: '8px 16px',
                                    display: 'flex',
                                    m: '24px 0px',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            color: 'text.tertiary',
                                            fontSize: '12px',
                                        }}
                                    >
                                        Price Range
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'text.primary',
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Free
                                    </Typography>
                                </Box>
                                <Box flex={1} />
                                {!eventState.is_host && (
                                    <SelectButton
                                        sx={{
                                            height: '35px',

                                            p: '0px 16px',
                                        }}
                                        onClick={() =>
                                            navigateToEventSelectTickets(
                                                eventState,
                                                eventImage,
                                                navigate,
                                                state,
                                                setState
                                            )
                                        }
                                    >
                                        <Typography
                                            sx={{
                                                color: 'text.primary',
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Grab tickets
                                        </Typography>
                                    </SelectButton>
                                )}
                            </GrabTicketBox>
                            <TabBox>
                                <StyledTabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <StyledTab
                                        label="Details"
                                        value="details"
                                    />
                                    <StyledTab label="Posts" value="posts" />
                                    <StyledTab
                                        label="Attendees"
                                        value="attendees"
                                    />
                                </StyledTabs>
                            </TabBox>
                            {value === 'details' ? (
                                <EventDetailsView event={eventState} />
                            ) : value === 'posts' ? (
                                <EventPostsView
                                    event={eventState}
                                    eventImage={eventImage}
                                />
                            ) : (
                                value == 'attendees' && (
                                    <EventAttendeesView
                                        event={eventState}
                                        eventImage={eventImage}
                                    />
                                )
                            )}
                        </Box>
                        {desktopBreakpoint > theme.breakpoints.values.md && (
                            <Box
                                sx={{
                                    width: 'calc(40%)',
                                    padding: '0px 0px',
                                    position: 'sticky',
                                    top: `${navMobileBarHeight + 8}px`,
                                    height: '100%',
                                    ml: '20px',
                                    mt: '40px',
                                }}
                            >
                                <img
                                    src={eventImage!}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        opacity: 1,
                                        borderRadius: 10,
                                        zIndex: 2,
                                        objectFit: 'cover',
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mt: '16px',
                                    }}
                                >
                                    <Box flex={1} />
                                    <Button
                                        sx={{
                                            p: '0px 16px',
                                            height: '35px',
                                            backgroundColor: 'text.primary',
                                            color: 'background.primary',
                                            borderRadius: '50px',
                                            alignItems: 'center',
                                            display: 'flex',
                                            textTransform: 'none',
                                            '&:hover': {
                                                backgroundColor: 'text.primary',
                                            },
                                        }}
                                    >
                                        <AddRoundedIcon
                                            sx={{
                                                color: 'background.primary',
                                                fontSize: 18,
                                                mr: '4px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                color: 'background.primary',
                                            }}
                                        >
                                            Create a post
                                        </Typography>
                                    </Button>
                                    {eventState.is_host && (
                                        <Button
                                            sx={{
                                                p: '0px 16px',
                                                height: '35px',
                                                ml: '8px',
                                                backgroundColor:
                                                    'background.selected',
                                                borderRadius: '50px',
                                                alignItems: 'center',
                                                display: 'flex',
                                                textTransform: 'none',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'text.primary',
                                                },
                                            }}
                                        >
                                            <BuildRoundedIcon
                                                sx={{
                                                    color: 'text.primary',
                                                    fontSize: 18,
                                                    mr: '4px',
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    color: 'text.primary',
                                                }}
                                            >
                                                Tools
                                            </Typography>
                                        </Button>
                                    )}
                                    <Button
                                        sx={{
                                            p: '0px 16px',
                                            height: '35px',
                                            ml: '8px',
                                            backgroundColor: 'transparent',
                                            border: (theme) =>
                                                '1px solid ' +
                                                theme.palette.border.secondary,
                                            borderRadius: '50px',
                                            alignItems: 'center',
                                            textTransform: 'none',
                                            '&:hover': {
                                                backgroundColor:
                                                    'background.secondary',
                                            },
                                        }}
                                    >
                                        <IosShareRoundedIcon
                                            sx={{
                                                color: 'text.primary',
                                                fontSize: 16,
                                                mr: '4px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                color: 'text.primary',
                                            }}
                                        >
                                            Share
                                        </Typography>
                                    </Button>
                                </Box>

                                <SideLinksView />
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <div></div>
            )}
        </>
    )
}

export default EventPageView
