export const hexToRGBA = (hex: string, opacity: number) => {
    let r = 0,
        g = 0,
        b = 0
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16)
        g = parseInt(hex[2] + hex[2], 16)
        b = parseInt(hex[3] + hex[3], 16)
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16)
        g = parseInt(hex[3] + hex[4], 16)
        b = parseInt(hex[5] + hex[6], 16)
    }
    return `rgba(${r},${g},${b},${opacity})`
}

export const darkenHexColor = (hexColor: string, amount: number) => {
    hexColor = hexColor.replace('#', '')
    let r = parseInt(hexColor.substring(0, 2), 16)
    let g = parseInt(hexColor.substring(2, 4), 16)
    let b = parseInt(hexColor.substring(4, 6), 16)
    r = Math.max(0, r - amount)
    g = Math.max(0, g - amount)
    b = Math.max(0, b - amount)
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}
