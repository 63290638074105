import { RouteObject } from 'react-router-dom'
import DefaultLayout from './layouts/DefaultLayout'
import AccountView from './views/AccountView'
import FeedView from './views/FeedView'
import LoginView from './views/AuthView/LoginView'
import { Login } from '@mui/icons-material'
import DiscoveryView from './views/DiscoveryView'
import SplashLayout from './layouts/SplashLayout'
import CreateEventView from './views/CreateEventView'
import CreateAccountView from './views/AuthView/CreateAccountView'
import EventPageView from './views/EventView'
import PostMainView from './views/PostView'
import AccountFFView from './views/AccountView/AccountFFView'
import CreatePostView from './views/PostView/CreatePostView'
import EventPhotosView from './views/EventView/EventPhotosView'
import EditEventView from './views/EditEventView'
import TicketsView from './views/TicketsView'
import GiftTicketView from './views/TicketsView/GiftTicketView.tsx'
import AboutView from './views/SplashView/AboutView'
import EventTicketSelectMobileView from './views/EventView/EventMobileView/EventTicketSelectMobileView'
import EventTicketSelectView from './views/EventView/EventTicketSelectView'
import TicketPageView from './views/TicketsView/TicketPageView'
import EventTools from './views/EventView/EventTools'
import path from 'path'

const routes = function (): RouteObject[] {
    return [
        {
            path: '/',
            element: <SplashLayout />,
            children: [
                { path: '', element: <AboutView /> },
                { path: 'login', element: <AboutView /> },
                { path: 'createaccount', element: <AboutView /> },
            ],
        },

        {
            path: '/app',
            element: <DefaultLayout />,
            children: [
                { path: '/app/feed/fyp', element: <FeedView /> },
                { path: '/app/discover/search', element: <DiscoveryView /> },
                { path: '/app/account', element: <AccountView /> },
                { path: '/app/createevent', element: <CreateEventView /> },
                { path: '/app/:slug/edit', element: <EditEventView /> },
                {
                    path: '/app/createevent/location',
                    element: <CreateEventView />,
                },
                { path: '/app/createevent/tags', element: <CreateEventView /> },
                {
                    path: '/app/event/:slug/:tabIndex/',
                    element: <EventPageView />,
                },
                {
                    path: '/app/event/:slug/:tabIndex/images',
                    element: <EventPageView />,
                },
                {
                    path: '/app/event/:slug/selecttickets',
                    element: <EventTicketSelectView />,
                },
                { path: '/app/post/:uuid', element: <PostMainView /> },
                { path: '/app/tickets/:tabIndex', element: <TicketsView /> },
                { path: '/app/ticketitem/:id', element: <TicketPageView /> },
                { path: '/app/gifttickets/', element: <GiftTicketView /> },
                {
                    path: '/app/account/:username/:tabIndex',
                    element: <AccountView />,
                },
                {
                    path: '/app/account/:username/:tabIndex/ff/:ffTabIndex',
                    element: <AccountView />,
                },
                {
                    path: '/app/account/:username/:tabIndex/edit',
                    element: <AccountView />,
                },
                {
                    path: '/app/createpost',
                    element: <CreatePostView />,
                },
                {
                    path: '/app/createpost/selectevent/:tabIndex',
                    element: <CreatePostView />,
                },
                {
                    path: '/app/event/tools/:slug/general',
                    element: <EventTools />,
                },
                {
                    path: '/app/event/tools/:slug/checkin',
                    element: <EventTools />,
                },
                {
                    path: '/app/event/tools/:slug/analytics',
                    element: <EventTools />,
                },
                {
                    path: '/app/event/tools/:slug/revenue',
                    element: <EventTools />,
                },
            ],
        },
    ]
}

export default routes
