import { Box, Link, Typography } from '@mui/material'
import { PrimaryTypography, SelectTypography } from './StyledComponents'

interface HashtagTypographyProps {
    text: string
    fontSize: number
}
const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/i

const HighlightTypography = ({ text, fontSize }: HashtagTypographyProps) => {
    const getStyledParts = (text: string) => {
        const regex = /(#[\w-]+|\s+|\S+)/g
        return text.match(regex) || []
    }

    return (
        <Typography
            component="div"
            sx={{
                whiteSpace: 'pre-wrap',
                color: 'grey',
                fontSize: '16px',
                lineHeight: '1.0',
            }}
        >
            {getStyledParts(text).map((part, index) => (
                <>
                    {part.startsWith('#') ? (
                        <Typography
                            key={index}
                            component="span"
                            sx={{
                                color: (theme) => theme.palette.text.selected,
                                fontSize: fontSize,
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {part}
                        </Typography>
                    ) : part.startsWith('@') ? (
                        <Typography
                            key={index}
                            component="span"
                            sx={{
                                color: (theme) => theme.palette.text.selected,
                                fontSize: fontSize,
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {part}
                        </Typography>
                    ) : part.match(urlRegex) ? (
                        <Link
                            href={part}
                            key={index}
                            sx={{
                                color: (theme) => theme.palette.text.selected,
                                fontSize: fontSize,
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                // '&:hover': {
                                //     textDecoration: 'underline',
                                // },
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {part}
                        </Link>
                    ) : (
                        <Typography
                            key={index}
                            component="span"
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                fontSize: fontSize,
                            }}
                        >
                            {part}
                        </Typography>
                    )}
                </>
            ))}
        </Typography>
    )
}

export default HighlightTypography
