import { useLocation, useNavigate } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { NavigateCreatePostState } from '../../../models/NavigateState'
import { useEffect, useState } from 'react'
import { createPost } from '../../../api/RestPost'
import { Box, Button, Fade, InputBase, Typography } from '@mui/material'
import {
    PrimaryDivider,
    SelectedHoverButton,
    StyledIconButton,
    StyledTextField,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import { navigateToCreatePostSelectEvent } from '../../../utils/NavigationFunctions'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { styled } from '@mui/system'
import EventSubPostView from '../../EventView/EventSubPostView'
import CreatePostTopBarMobileView from './CreatePostTopBarMobileView'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import CreatePostBottomBarMobileView from './CreatePostBottomBarMobileView'
import { Event } from '../../../models/Event'
import LinkPreview from '../../Components/LinkPreview'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import CreatePostSelectEventMobileView from './CreatePostSelectEventMobileView'
import { DataStatus } from '../../../models/Dataloading'
import { Data } from '@react-google-maps/api'
import LoadingOverlayMobileView from '../../Components/StaticPages/LoadingView/LoadingOverlayMobileView'
import { getIframely } from '../../../api/Iframely'
import { get } from 'http'
import { ExtractUrl } from '../../../utils/ExtractUrl'
import { PostOptions } from '../../../models/Post'
import CreatePostOptionsMobileView from './CreatePostOptionsMobileView'
import { setOptions } from 'marked'

const ContainedEventBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    padding: '8px',
    border: '1px solid ' + theme.palette.border.primary,
}))

const CreatePostMobileView = () => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    // const [urlPreview, setUrlPreview] = useState<string | null>(null)
    const location = useLocation()
    const [submitLoading, setSubmitLoading] = useState<DataStatus>(
        DataStatus.none
    )
    const initialCreatePostState = location.state as NavigateCreatePostState
    const [event, setEvent] = useState<Event | null>(
        initialCreatePostState.event
    )
    const [eventImage, setEventImage] = useState(
        initialCreatePostState.eventImage
    )
    const [eventHostImage, setEventHostImage] = useState(
        initialCreatePostState.eventHostImage
    )
    const [showSelectEvent, setShowSelectEvent] = useState(false)
    const [caption, setCaption] = useState(initialCreatePostState.caption)
    useEffect(() => {
        setEvent(initialCreatePostState.event)
    }, [initialCreatePostState.event])
    const [postOption, setPostOption] = useState<PostOptions>(
        PostOptions.PUBLIC
    )
    const [showOptions, setShowOptions] = useState(false)
    const [postImage, setPostImage] = useState<string | null>(null)
    const handleCaptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCaption(event.target.value)
        // console.log(event.target.value)
        // const respose = ExtractUrl(event.target.value)
        // console.log(respose)
        // if (respose){
        //     setUrlPreview(respose[0])
        // }
        // else{
        //     setUrlPreview(null)
        // }
    }

    return (
        <Box
            sx={{
                height: '100%',
                mt: '55px',
                pb: `${navMobileBarHeight + 40}px`,
                zIndex: (theme) => theme.zIndex.drawer + 5,
            }}
        >
            {event && (
                <CreatePostOptionsMobileView
                    event={event}
                    setPostOptions={setPostOption}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                />
            )}
            {submitLoading === DataStatus.loading && (
                <LoadingOverlayMobileView title="Creating Post" />
            )}
            {window.location.pathname.includes('selectevent') ? (
                <CreatePostSelectEventMobileView />
            ) : (
                <>
                    <CreatePostBottomBarMobileView
                        event={event}
                        setPostImage={setPostImage}
                        setShowOptions={setShowOptions}
                        caption={caption}
                        postOptions={postOption}
                    />
                    <CreatePostTopBarMobileView
                        caption={caption}
                        event={event}
                        postImage={postImage}
                        submitLoading={submitLoading}
                        setSubmitLoading={setSubmitLoading}
                    />
                    <Box
                        sx={{
                            p: '16px 16px',
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                justifyContent: 'center',
                            }}
                        >
                            <Box>
                                {state.userimage ? (
                                    <UserPhotoCircle
                                        sx={{
                                            width: 35,
                                            height: 35,
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                        }}
                                        includeBorder={false}
                                    >
                                        <img
                                            src={state.userimage}
                                            alt="User"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </UserPhotoCircle>
                                ) : (
                                    <ErrorAccountAvatarView
                                        size={35}
                                        includeBorder={false}
                                    />
                                )}
                            </Box>
                            <Fade in={event !== null}>
                                <Box
                                    sx={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <PrimaryDivider
                                        orientation="vertical"
                                        flexItem
                                        sx={{
                                            borderRightWidth: '2px',
                                            height: '100%',
                                            mr: `${34 / 2}px`,
                                            mt: '8px',
                                        }}
                                    />
                                </Box>
                            </Fade>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: '12px',
                                height: '100%',
                                width: '100%',
                                float: 'left',
                                alignItems: 'top',
                                flex: 1,
                            }}
                        >
                            <Box display="flex" alignItems={'center'}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    {state.name}
                                </Typography>
                                {event &&
                                    (event.is_host ? (
                                        <SecurityRoundedIcon
                                            sx={{
                                                color: 'text.host',
                                                fontSize: 18,
                                                ml: '4px',
                                            }}
                                        />
                                    ) : event.attending ? (
                                        <PersonRoundedIcon
                                            sx={{
                                                color: 'text.attending',
                                                fontSize: 18,
                                                ml: '4px',
                                            }}
                                        />
                                    ) : null)}
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        marginLeft: '4px',
                                        color: 'text.secondary',
                                    }}
                                >
                                    @{state.username}
                                </Typography>
                            </Box>
                            <Button
                                sx={{
                                    marginTop: '4px',
                                    fontSize: 14,
                                    display: 'flex',
                                    padding: '3px 4px',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    borderRadius: '50px',
                                    backgroundColor: 'background.tertiary',
                                }}
                                onClick={() =>
                                    navigateToCreatePostSelectEvent(
                                        event,
                                        eventImage,
                                        eventHostImage,
                                        navigate,
                                        'recent'
                                    )
                                }
                            >
                                {event ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* <img
                                        src={eventImage!}
                                        alt="Event"
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            objectFit: 'cover',
                                            borderRadius: '50px',
                                            marginRight: '8px',
                                        }}
                                    /> */}
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                ml: '8px',
                                                color: 'text.primary',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {event.title}
                                        </Typography>
                                        <StyledIconButton
                                            sx={{
                                                ml: '8px',
                                                backgroundColor:
                                                    'background.quaternary',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'background.quaternary',
                                                },
                                                height: '20px',
                                                width: '20px',
                                            }}
                                            onClick={function (e) {
                                                setPostOption(
                                                    PostOptions.PUBLIC
                                                )
                                                e.stopPropagation()
                                                setEvent(null)
                                            }}
                                        >
                                            <CloseRoundedIcon
                                                sx={{
                                                    fontSize: 14,
                                                    color: 'text.primary',
                                                }}
                                            />
                                        </StyledIconButton>
                                    </Box>
                                ) : (
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            ml: '8px',
                                            mr: '8px',
                                            color: 'text.primary',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Select an event
                                    </Typography>
                                )}
                            </Button>
                            {postImage && (
                                <img
                                    src={postImage}
                                    alt="Event"
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '10px',
                                        marginTop: '12px',
                                        aspectRatio: '20/9',
                                    }}
                                />
                            )}
                            <InputBase
                                placeholder="Write a caption..."
                                fullWidth
                                multiline
                                size="small"
                                minRows={1}
                                sx={{
                                    marginTop: '12px',
                                    marginBottom: '4px',
                                    fontSize: 14,
                                }}
                                value={caption}
                                onChange={handleCaptionChange}
                            />
                            {/* <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <StyledIconButton
                                sx={{
                                    padding: '8px',
                                    float: 'left',
                                }}
                                onClick={triggerFileSelect}
                            >
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <CameraAltRoundedIcon
                                    sx={{ fontSize: 20,
                                        color: 'text.primary',
                                     }}
                                />
                            </StyledIconButton>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    textAlign: 'right',
                                    float: 'right',
                                    paddingRight: '8px',
                                    color: 'text.secondary',
                                }}
                            >
                                {`${0}/1000`}
                            </Typography>
                        </Box> */}
                            {/* {urlPreview && (
                        <LinkPreview url={urlPreview} />
                        )} */}
                            {/* <SelectedHoverButton
                                sx={{
                                    fontSize: 14,
                                    marginTop: '8px',
                                }}
                            >
                                Public: Everyone can see this post
                            </SelectedHoverButton> */}
                        </Box>
                    </Box>

                    {event && (
                        <Box
                            sx={{
                                p: '0px 16px',
                                pb: '60px',
                            }}
                        >
                            <ContainedEventBox
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                {/* <StyledIconButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 3,
                            backgroundColor: 'background.tertiary',
                            '&:hover': {
                                backgroundColor: 'background.tertiary',
                            },
                        }}
                        >
                        <StyledCloseRoundedIcon sx={{
                            fontSize: 20,
                        }}/>
                            
                    </StyledIconButton> */}
                                <Box
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <EventSubPostView event={event} />
                                </Box>
                            </ContainedEventBox>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default CreatePostMobileView
