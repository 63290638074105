import { useEffect, useState } from 'react'
import { Ticket } from '../../../models/Ticket'
import { DataStatus } from '../../../models/Dataloading'
import { useAppState } from '../../../AppStateProvider'
import exp from 'constants'
import { Box, List, Typography } from '@mui/material'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import EmptyTicketsView, {
    EmptyTicketsViewType,
} from '../../Components/StaticPages/EmptyView/EmptyTicketsView'
import TicketItemView from '../TicketItemView'
import { useApiManager } from '../../../api/ApiMangerProvider'

interface ActiveExpiredTicketViewProps {
    tabIndex: string
}

const ExpiredTicketMobileView = () => {
    const [ticketState, setTicketState] = useState<Ticket[] | null>(null)
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [ticketLoaded, setTicketLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        try {
            apiManager
                .getTickets('expired', null, 20, 0)
                .then((response) => {
                    console.log(response)
                    setTicketState(response.tickets)
                    setTicketLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setTicketLoaded(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setTicketLoaded(DataStatus.failure)
        }
    }, [])
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            {ticketLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : ticketLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                ticketLoaded === DataStatus.success &&
                ticketState &&
                (ticketState.length === 0 ? (
                    <EmptyTicketsView type={EmptyTicketsViewType.expired} />
                ) : (
                    <Box
                        sx={{
                            marginTop: '4px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',

                                color: 'text.tertiary',
                                mt: '8px',
                            }}
                        >
                            {`${ticketState.length} Expired Tickets`}
                        </Typography>
                        <List sx={{ padding: 0 }}>
                            {ticketState.map((ticket, index) => (
                                <Box>
                                    <Box
                                        key={ticket.id}
                                        sx={{ padding: '8px 0px' }}
                                    >
                                        <TicketItemView ticket={ticket} />
                                    </Box>
                                </Box>
                            ))}
                        </List>
                    </Box>
                ))
            )}
        </Box>
    )
}

const ActiveTicketMobileView = () => {
    const [ticketState, setTicketState] = useState<Ticket[] | null>(null)
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [ticketLoaded, setTicketLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        try {
            apiManager
                .getTickets('active', null, 20, 0)
                .then((response) => {
                    console.log(response)
                    setTicketState(response.tickets)
                    setTicketLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setTicketLoaded(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setTicketLoaded(DataStatus.failure)
        }
    }, [])
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {ticketLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : ticketLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                ticketLoaded === DataStatus.success &&
                ticketState &&
                (ticketState.length === 0 ? (
                    <EmptyTicketsView type={EmptyTicketsViewType.active} />
                ) : (
                    <Box
                        sx={{
                            marginTop: '4px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',

                                color: 'text.tertiary',
                                mt: '8px',
                            }}
                        >
                            {`${ticketState.length} Active Tickets`}
                        </Typography>
                        <List sx={{ padding: 0 }}>
                            {ticketState.map((ticket, index) => (
                                <Box>
                                    <Box
                                        key={ticket.id}
                                        sx={{ padding: '8px 0px' }}
                                    >
                                        <TicketItemView ticket={ticket} />
                                    </Box>
                                </Box>
                            ))}
                        </List>
                    </Box>
                ))
            )}
        </Box>
    )
}
export default ActiveTicketMobileView
