import { Box, Chip, Typography, styled } from '@mui/material'
import {
    PrimaryDivider,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import { Event } from '../../../models/Event'

interface EventDetailsCategoriesViewProps {
    event: Event
}

const EventDetailsCategoriesView = ({
    event,
}: EventDetailsCategoriesViewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                CATEGORIES
            </Typography>
            <PrimaryDivider
                sx={{
                    mt: '8px',
                    mb: '8px',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    gap: 1,
                    marginTop: '16px',
                }}
            ></Box>
        </Box>
    )
}

export default EventDetailsCategoriesView
