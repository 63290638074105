import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavigateDiscoverState } from '../../../models/NavigateState'
import { useAppState } from '../../../AppStateProvider'
import { LocationSearch } from '../../../models/Location'
import { EventType, FilterData, FilterDateChoice } from '../../../models/Filter'
import { Event } from '../../../models/Event'
import { DataStatus } from '../../../models/Dataloading'
import { getEvents } from '../../../api/RestEvent'
import {
    AppBar,
    Box,
    BoxProps,
    Fade,
    Grid,
    IconButton,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import DiscoverTopBarMobileView from './DiscoverTopBarMobileView'
import FilterTopView from '../FilterTopView'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import EventSubView from '../../EventView/EventSubView'
import EventSubMobileView from '../../EventView/EventMobileView/EventSubMobileView'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import MapRoundedIcon from '@mui/icons-material/MapRounded'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import { MapRounded } from '@mui/icons-material'
import MapMobileView from './MapMobileView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import DiscoverSearchMobileView from './DiscoverSearchMobileView'
import FilterTopBarMobileView from './FilterTopBarMobileVIew'
import DiscoverSearchLocationMobileView from './DiscoverSearchLocationMobileView'
import DiscoverFilterMobileView from './DiscoverFilterMobileView'
import { useApiManager } from '../../../api/ApiMangerProvider'
import DiscoverDrawerMobileView from './DiscoverDrawerMobileView'
import { set } from 'date-fns'
import { use } from 'marked'

const PostIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.selected,

    padding: '16px',
}))

const MapIconButton = styled(Box)(({ theme }) => ({
    color: theme.palette.text.selected,
    backgroundColor: theme.palette.background.secondary,
    padding: '8px 12px',
    borderRadius: '50px',
    border: '1px solid ' + theme.palette.border.primary,
}))
interface IconButtonBarProps extends BoxProps {
    showMapEvents: boolean
}

const IconButtonBar = styled((props: IconButtonBarProps) => <Box {...props} />)(
    ({ theme, showMapEvents }) => ({
        background: 'transparent',
        position: 'fixed',
        zIndex: 3,
        bottom: showMapEvents
            ? `${308 + navMobileBarHeight}px`
            : navMobileBarHeight - 16,
        transition: theme.transitions.create('bottom', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    })
)

interface FilterBarProps extends BoxProps {
    expandMap: boolean
}

const FilterBar = styled((props: FilterBarProps) => <Box {...props} />)(
    ({ theme, expandMap }) => ({
        backgroundColor: expandMap
            ? 'transparent'
            : theme.palette.background.primary,
        borderBottom: expandMap
            ? 'transparent'
            : '1px solid ' + theme.palette.border.primary,
        zIndex: 3,
    })
)
const DiscoverMobileView = () => {
    const location = useLocation()
    const stateData = location.state as NavigateDiscoverState
    const { state, setState } = useAppState()
    const [expandmap, setExpandMap] = useState(false)
    const [showMapEvents, setShowMapEvents] = useState(false)
    const apiManager = useApiManager()
    const [offset, setOffset] = useState(
        stateData ? stateData.eventstate.length : 0
    )
    const [loadMore, setLoadMore] = useState<DataStatus>(DataStatus.none)
    const [locationsearchstate, setLocationSearchState] =
        useState<LocationSearch | null>({
            description: 'Los Angeles',
            maintext: 'Los Angeles',
            secondarytext: 'USA',
            place_id: 'ChIJE9on3F3HwoAR9AhGJW_fL-I',
            location: { lat: 34.0549076, lng: -118.242643 },
        })
    const [filterstate, setFilterState] = useState<FilterData>({
        date: { choice: FilterDateChoice.Any, dates: [] },
        attendeeCount: [0, 500],
        range: 500,
        premium: EventType.None,
        following: false,
        category: [],
    })
    const [showSearch, setShowSearch] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [filterItemsExpand, setFilterItemsExpand] = useState<string[]>([
        'date',
        'distance',
        'attendees',
        'premium',
        'category',
    ])
    const [eventState, setEventState] = useState<Event[] | null>(
        stateData ? stateData.eventstate : null
    )
    const [eventStateLoaded, setEventStateLoaded] = useState<DataStatus>(
        stateData ? DataStatus.success : DataStatus.loading
    )
    const [showSearchLocation, setShowSearchLocation] = useState(false)
    const [reloadMore, setReloadMore] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const handleSuccess = (position: any) => {
        const { latitude, longitude } = position.coords
        setLocationSearchState({
            description: 'Current Location',
            maintext: 'Current Location',
            secondarytext: 'Current Location',
            place_id: 'default',
            location: { lat: latitude, lng: longitude },
        })
        setState((prevState) => ({
            ...prevState,
            location: { latitude, longitude },
        }))
    }

    const handleError = (error: any) => {
        console.log(error.message)
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
        } else {
            console.log('Geolocation is not supported by this browser.')
        }
    }

    useEffect(() => {
        getLocation()
    }, [])
    useEffect(() => {
        if (loadMore === DataStatus.loading) {
            apiManager
                .getEvents(null, null, null, filterstate.range, 6, offset)
                .then((response) => {
                    setEventState((prevState) => [
                        ...prevState!,
                        ...response.events,
                    ])
                    setOffset(offset + response.events.length)
                    setLoadMore(DataStatus.success)
                })
                .catch((error) => {
                    setLoadMore(DataStatus.failure)
                })
        }
    }, [loadMore])
    useEffect(() => {
        if (eventStateLoaded === DataStatus.loading) {
            apiManager
                .getEvents(null, null, null, filterstate.range, 6, offset)
                .then((response) => {
                    setEventState([...response.events])
                    setEventStateLoaded(DataStatus.success)
                    setOffset(offset + response.events.length)
                })
                .catch((error) => {
                    setEventStateLoaded(DataStatus.failure)
                })
        }
    }, [])
    // useEffect(() => {
    //     setEventStateLoaded(DataStatus.loading)
    //     apiManager
    //         .getEvents(null, null, null, filterstate.range, 6, offset)
    //         .then((response) => {
    //             setEventState([...response.events])
    //             setEventStateLoaded(DataStatus.success)
    //             setOffset(offset + response.events.length)
    //         })
    //         .catch((error) => {
    //             setEventStateLoaded(DataStatus.failure)
    //         })

    // },[filterstate, locationsearchstate])

    // useEffect(() => {
    //     if (eventState && eventStateLoaded === DataStatus.none) {
    //         setEventStateLoaded(DataStatus.success)
    //     } else {
    //         setEventStateLoaded(DataStatus.loading)
    //         setOffset(0)
    //         try {
    //             apiManager
    //                 .getEvents(null, null, null, filterstate.range, 6, offset)
    //                 .then((response) => {
    //                     console.log(response)
    //                     setEventState([...response.events])
    //                     setEventStateLoaded(DataStatus.success)
    //                     setOffset(offset + response.events.length)
    //                     // setOffset((prev) => prev + 20)
    //                 })
    //                 .catch((error) => {
    //                     console.error(error)
    //                     setEventStateLoaded(DataStatus.failure)
    //                 })
    //         } catch (error) {
    //             console.error(error)
    //             setEventStateLoaded(DataStatus.failure)
    //         }
    //     }
    // }, [filterstate, locationsearchstate])
    const handleExpandMap = () => {
        setExpandMap((prev) => !prev)
        if (expandmap) {
            setShowMapEvents(false)
        }
    }
    const [isVisible, setIsVisible] = useState(false)
    const reloadRef = useRef<HTMLDivElement | null>(null)
    const [showDrawer, setShowDrawer] = useState(false)
    useEffect(() => {
        if (!reloadRef.current || !eventState) return

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && eventState.length > 0) {
                    setLoadMore(DataStatus.loading)
                }
            },
            { root: null, threshold: 1.0 }
        )

        observer.observe(reloadRef.current)

        return () => {
            if (reloadRef.current) {
                observer.unobserve(reloadRef.current)
            }
        }
    }, [reloadRef.current])
    return (
        <Box>
            {showSearch ? (
                <DiscoverSearchMobileView
                    showSearch={showSearch}
                    setShowSearch={setShowSearch}
                />
            ) : showSearchLocation ? (
                <DiscoverSearchLocationMobileView
                    showSearchLocation={showSearchLocation}
                    setShowSearchLocation={setShowSearchLocation}
                    setLocationSearchState={setLocationSearchState}
                />
            ) : showFilter ? (
                <DiscoverFilterMobileView
                    filterState={filterstate}
                    setFilterState={setFilterState}
                    maxAttendees={500}
                    maxDistance={500}
                    setShowFilter={setShowFilter}
                />
            ) : (
                <>
                    <DiscoverDrawerMobileView
                        showDrawer={showDrawer}
                        setShowDrawer={setShowDrawer}
                    />
                    <FilterBar
                        style={{
                            height: 50,
                            width: '100%',
                            display: 'flex',
                            position: 'fixed',
                            top: 45,
                        }}
                        expandMap={expandmap}
                    >
                        <FilterTopBarMobileView
                            locationSearchState={locationsearchstate}
                            setLocationSearchState={setLocationSearchState}
                            filterState={filterstate}
                            setFilterState={setFilterState}
                            setShowSearchLocation={setShowSearchLocation}
                            setShowFilter={setShowFilter}
                            maxDistance={500}
                            maxAttendees={500}
                        />
                    </FilterBar>
                    <IconButtonBar
                        showMapEvents={showMapEvents}
                        sx={{
                            pb: '16px',
                            width: '100%',
                            pl: '8px',
                        }}
                    >
                        <Toolbar>
                            <Box sx={{ flexGrow: 1 }} />
                            <MapIconButton
                                sx={{
                                    mr: '8px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={handleExpandMap}
                            >
                                {expandmap ? (
                                    <>
                                        <ViewListRoundedIcon />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                ml: '8px',
                                            }}
                                        >
                                            List View
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <MapRoundedIcon />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                ml: '8px',
                                            }}
                                        >
                                            Map View
                                        </Typography>
                                    </>
                                )}
                            </MapIconButton>
                        </Toolbar>
                    </IconButtonBar>
                    <DiscoverTopBarMobileView
                        setShowDrawer={setShowDrawer}
                        expandMap={expandmap}
                        setShowSearch={setShowSearch}
                    />
                    {expandmap ? (
                        <Fade in={expandmap}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 'calc(100vh - 60px)',
                                }}
                            >
                                <MapMobileView
                                    eventstate={eventState}
                                    showMapEvents={showMapEvents}
                                    setShowMapEvents={setShowMapEvents}
                                />
                            </Box>
                        </Fade>
                    ) : (
                        <Fade in={!expandmap}>
                            <Box
                                sx={{
                                    mt: '95px',
                                    mb: `${navMobileBarHeight}px`,
                                }}
                            >
                                <Box>
                                    {eventStateLoaded === DataStatus.loading ? (
                                        <Box
                                            sx={{
                                                pt: '16px',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                width: '100%',
                                            }}
                                        >
                                            <StyledCircularProgress />
                                        </Box>
                                    ) : eventStateLoaded ===
                                      DataStatus.failure ? (
                                        <ErrorTabView />
                                    ) : (
                                        eventStateLoaded ===
                                            DataStatus.success &&
                                        locationsearchstate &&
                                        eventState && (
                                            <>
                                                <Box
                                                    sx={{
                                                        pt: '8px',
                                                    }}
                                                >
                                                    <Typography
                                                        align="left"
                                                        sx={{
                                                            fontSize: 14,
                                                            color: 'text.primary',
                                                            ml: '16px',
                                                        }}
                                                    >{`${eventState.length} results`}</Typography>
                                                    <Box>
                                                        {eventState.map(
                                                            (event) => (
                                                                <Box>
                                                                    <EventSubMobileView
                                                                        event={
                                                                            event
                                                                        }
                                                                    />
                                                                    <PrimaryDivider />
                                                                </Box>
                                                            )
                                                        )}
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    )}
                                </Box>
                                <Box
                                    ref={reloadRef}
                                    sx={{
                                        height: '80px',
                                    }}
                                >
                                    {loadMore === DataStatus.loading && (
                                        <Box
                                            sx={{
                                                height: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                width: '100%',
                                            }}
                                        >
                                            <StyledCircularProgress />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Fade>
                    )}
                </>
            )}
        </Box>
    )
}

export default DiscoverMobileView
