import exp from 'constants'
import { useAppState } from '../../AppStateProvider'
import TicketPageMobileView from './TicketMobileView.tsx/TicketPageMobileView'

const TicketPageView = () => {
    const { state, setState } = useAppState()
    return <>{state.isMobile ? <TicketPageMobileView /> : <></>}</>
}

export default TicketPageView
