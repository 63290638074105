import { AppBar, Box, Toolbar, Typography, styled } from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import { StyledIconButton } from '../Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useNavigate } from 'react-router-dom'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
    background: theme.palette.background.primary,
    boxShadow: 'none',
}))

interface CreateAccountTopBarMobileViewProps {
    registerpage: number
    setRegisterPage: React.Dispatch<React.SetStateAction<number>>
    setShowCreateAccount: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateAccountTopBarMobileView = ({
    registerpage,
    setRegisterPage,
    setShowCreateAccount,
}: CreateAccountTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    return (
        <TopBar>
            <Toolbar style={{ minHeight: 55, paddingLeft: 8, paddingRight: 8 }}>
                <Box sx={{ flexGrow: 1 }} />
                <img
                    src={`${process.env.PUBLIC_URL}/logotest03longwhite.png`}
                    height={30}
                />
                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
            <Toolbar
                style={{
                    minHeight: 55,
                    paddingLeft: 16,
                    paddingRight: 8,
                    position: 'absolute',
                }}
            >
                {registerpage === 0 ? (
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.secondary',
                        }}
                        onClick={() => navigate('/')}
                    >
                        Cancel
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.secondary',
                        }}
                        onClick={() => setRegisterPage((prev) => prev - 1)}
                    >
                        Back
                    </Typography>
                )}
            </Toolbar>
        </TopBar>
    )
}

export default CreateAccountTopBarMobileView
