import { Box, Typography } from '@mui/material'
import { Event } from '../../../models/Event'
import {
    AttendingBox,
    HostBox,
    PrimaryDivider,
    PrimaryTypography,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded'
import { timeDifferenceBetweenTimes } from '../../../utils/TimeDifference'
import EventHostDetailsView from './EventHostDetailsView'
import {
    iso2day,
    iso2dayRange,
    iso2time,
} from '../../../utils/DateStringConverter'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import EventDetailsMapView from './EventDetailsMapView'
import EventDetailsTagView from './EventDetailsTagView'
import EventDetailsCategoriesView from './EventDetailsCategoriesView'
import Markdown from 'markdown-to-jsx'
import { locationDifference } from '../../../utils/LocationDifference'
import { useEffect, useState } from 'react'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import EventDetailsAttendeesMobileView from '../EventMobileView/EventDetailsAttendeesMobileView'
import { darkenHexColor } from '../../../utils/haxToRGBA'

interface EventDetailsViewProps {
    event: Event
}

const EventDetailsView = ({ event }: EventDetailsViewProps) => {
    return (
        <Box sx={{ marginTop: '12px' }}>
            <Box
                sx={{
                    mt: '16px',
                }}
            >
                <EventDetailsStatisticsView event={event} />
                {/* <PrimaryDivider
                sx={{
                    mt: '16px',

                }}
            /> */}
            </Box>
            <Box
                sx={{
                    mt: '24px',
                }}
            >
                <EventDetailsDateView event={event} />
            </Box>
            <Box
                sx={{
                    mt: '24px',
                }}
            >
                <EventHostDetailsView host={event.primary_host} />
            </Box>
            <Box
                sx={{
                    mt: '24px',
                }}
            >
                <EventDetailsAboutView event={event} />
            </Box>
            <Box
                sx={{
                    mt: '24px',
                }}
            >
                <EventDetailsMapView event={event} />
            </Box>
            <Box
                sx={{
                    mt: '24px',
                }}
            >
                <EventDetailsCategoriesView event={event} />
            </Box>
            <Box
                sx={{
                    mt: '24px',
                }}
            >
                <EventDetailsTagView event={event} />
            </Box>
        </Box>
    )
}

export const EventDetailsAboutView = ({ event }: EventDetailsViewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                ABOUT
            </Typography>
            <PrimaryDivider
                sx={{
                    mt: '8px',
                    mb: '8px',
                }}
            />
            <Typography
                sx={{ fontSize: 14, marginTop: '8px', color: 'text.primary' }}
            >
                <Markdown
                    fontSize={14}
                    options={{
                        overrides: {
                            // Override headings to render as body text
                            h1: {
                                component: Typography,
                                props: {
                                    style: { fontSize: 14 },
                                    gutterBottom: true,
                                },
                            },
                            h2: {
                                component: Typography,
                                props: {
                                    style: { fontSize: 14 },
                                    gutterBottom: true,
                                },
                            },
                            h3: {
                                component: Typography,
                                props: {
                                    style: { fontSize: 14 },
                                    gutterBottom: true,
                                },
                            },
                            h4: {
                                component: Typography,
                                props: {
                                    style: { fontSize: 14 },
                                    gutterBottom: true,
                                },
                            },
                            h5: {
                                component: Typography,
                                props: {
                                    style: { fontSize: 14 },
                                    gutterBottom: true,
                                },
                            },
                            h6: {
                                component: Typography,
                                props: {
                                    style: { fontSize: 14 },
                                    gutterBottom: true,
                                },
                            },
                            // Render paragraphs as normal body text
                            p: {
                                component: Typography,
                                props: {
                                    style: { fontSize: 14 },
                                    gutterBottom: true,
                                },
                            },
                            // Handle lists normally
                            ul: {
                                component: Typography,
                                props: {
                                    component: 'ul',
                                    style: { fontSize: 14 },
                                },
                            },
                            ol: {
                                component: Typography,
                                props: {
                                    component: 'ol',
                                    style: { fontSize: 14 },
                                },
                            },
                        },
                    }}
                >
                    {event.description}
                </Markdown>
            </Typography>
        </Box>
    )
}

export const EventDetailsDateView = ({ event }: EventDetailsViewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                DATE AND TIME
            </Typography>
            <PrimaryDivider
                sx={{
                    mt: '8px',
                    mb: '8px',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <CalendarMonthRoundedIcon
                    sx={{
                        fontSize: 18,
                        color: 'text.tertiary',
                        background: (theme) =>
                            theme.palette.background.tertiary,
                        padding: '8px',
                        borderRadius: '10px',
                    }}
                />
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            marginLeft: '8px',
                            color: 'text.primary',
                            fontWeight: 'bold',
                        }}
                    >
                        {iso2dayRange(event.event_time, event.end_time)}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            marginLeft: '8px',
                            color: 'text.primary',
                        }}
                    >
                        {`${iso2time(event.event_time)} - ${iso2time(event.end_time)}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export const EventDetailsStatisticsView = ({
    event,
}: EventDetailsViewProps) => {
    const [locationDifferenceString, setLocationDifferenceString] = useState('')
    useEffect(() => {
        locationDifference(event.location).then((response) =>
            setLocationDifferenceString(response)
        )
    }, [])
    return (
        <Box>
            {event.private ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <LockRoundedIcon
                        sx={{
                            fontSize: 18,
                            color: (theme) => theme.palette.text.primary,
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: 14,
                            marginLeft: '8px',
                            color: 'text.primary',
                        }}
                    >
                        Private
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <LockOpenRoundedIcon
                        sx={{
                            fontSize: 18,
                            color: (theme) => theme.palette.text.primary,
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: 14,
                            marginLeft: '8px',
                            color: 'text.primary',
                        }}
                    >
                        Public
                    </Typography>
                </Box>
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <ShoppingCartRoundedIcon
                    sx={{
                        fontSize: 18,
                        color: (theme) => theme.palette.text.primary,
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 14,
                        marginLeft: '8px',
                        color: 'text.primary',
                    }}
                >
                    Free
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <LocationOnRoundedIcon
                    sx={{
                        fontSize: 18,
                        color: (theme) => theme.palette.text.primary,
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 14,
                        marginLeft: '8px',
                        color: 'text.primary',
                    }}
                >
                    {locationDifferenceString}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <AccessAlarmRoundedIcon
                    sx={{
                        fontSize: 18,
                        color: (theme) => theme.palette.text.primary,
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 14,
                        marginLeft: '8px',
                        color: 'text.primary',
                    }}
                >
                    {timeDifferenceBetweenTimes(
                        event.event_time,
                        event.end_time
                    )}
                </Typography>
            </Box>
        </Box>
    )
}

export default EventDetailsView
