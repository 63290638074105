import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useCallback,
} from 'react'
import { styled } from '@mui/material/styles'
import {
    AppBar,
    BoxProps,
    Container,
    ContainerProps,
    Divider,
    GlobalStyles,
    Icon,
    IconButton,
    useMediaQuery,
    useTheme,
    Box,
} from '@mui/material'
import TopBarView from './TopBar'
import DrawerView from './NavBar'
import { Outlet } from 'react-router-dom'
import { Draw } from '@mui/icons-material'
import { AppStateContext } from '../../models/AppState'
import { useAppState } from '../../AppStateProvider'
import {
    ScrollBarDiv,
    StyledLinearProgress,
} from '../../views/Components/StyledComponents'
import TopBarMobileView from './TopBarMobile/TopBarMobile'
import NavMobileBar from './NavMobileBar'
import CreateMobileView from '../../views/CreateView/CreateMobileView'
import CreateBottomBarMobileView from '../../views/CreateView/CreateBottomBarMobileView'
import {
    DesktopSizeProvider,
    useDesktopSize,
} from '../../utils/DesktopSizeProvider'
import { getBreakpoint } from '../../utils/GetBreakpoint'

const CustomDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: theme.palette.border.primary,
}))

const DivContent = styled('div')(({ theme }) => ({
    flexGrow: 1,
    padding: '20px',
}))
const DivRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
}))
const DivRoot2 = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    // '&::-webkit-scrollbar': {
    //     width: '6px',
    // },
    // '&::-webkit-scrollbar-track': {
    //     background: 'transparent',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     backgroundColor: theme.palette.text.disabled,
    //     borderRadius: '10px',
    //     opacity: 0.5,
    //   },
    //   '&::-webkit-scrollbar-thumb:hover': {
    //     opacity: 0.5,
    //     backgroundColor: theme.palette.text.disabled,
    //   },
}))

interface RootContainerProps extends BoxProps {
    isSmallScreen: boolean
    drawerOpen: boolean
}
const RootContainer = styled(
    React.forwardRef<HTMLDivElement, RootContainerProps>((props, ref) => (
        <Box ref={ref} {...props} />
    ))
)(({ theme, isSmallScreen, drawerOpen }) => ({
    ...(isSmallScreen
        ? {
              width: '100%',
          }
        : {
              ...(drawerOpen
                  ? {
                        marginLeft: '220px',
                        transition: theme.transitions.create(
                            ['margin', 'width'],
                            {
                                easing: theme.transitions.easing.easeOut,
                                duration:
                                    theme.transitions.duration.enteringScreen,
                            }
                        ),
                        width: 'calc(100% - 220px)',
                    }
                  : {
                        marginLeft: '60px',
                        transition: theme.transitions.create(
                            ['margin', 'width'],
                            {
                                easing: theme.transitions.easing.sharp,
                                duration:
                                    theme.transitions.duration.leavingScreen,
                            }
                        ),
                        width: 'calc(100% - 60px)',
                    }),
          }),
    padding: 0,
}))

const DefaultMobileLayout = () => {
    const { state, setState } = useAppState()
    const [showCreateMobileView, setShowCreateMobileView] = useState(false)
    return (
        <>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Outlet />
            {!(
                window.location.pathname.includes('createpost') ||
                window.location.pathname.includes('createevent') ||
                window.location.pathname.includes('ticketitem') ||
                (window.location.pathname.includes('event') &&
                    window.location.pathname.includes('details')) ||
                (window.location.pathname.includes('event') &&
                    window.location.pathname.includes('selecttickets'))
            ) && (
                <NavMobileBar
                    showCreateMobileView={showCreateMobileView}
                    setShowCreateMobileView={setShowCreateMobileView}
                />
            )}
            <CreateMobileView
                showCreateMobileView={showCreateMobileView}
                setShowCreateMobileView={setShowCreateMobileView}
            />
            {(window.location.pathname.includes('createpost') ||
                window.location.pathname.includes('createevent')) && (
                <CreateBottomBarMobileView />
            )}
        </>
    )
}

const DefaultLayout = () => {
    const [open, setOpen] = useState(true)
    const [drawertemporaryopen, setDrawerTemporaryOpen] = useState(false)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [showprogress, setShowProgress] = useState(false)
    const { state, setState } = useAppState()
    const [progress, setProgress] = useState(0)
    return (
        <DivRoot>
            <GlobalStyles
                styles={{
                    body: {
                        backgroundColor: theme.palette.background.primary,
                    },
                }}
            />
            {state.isMobile ? (
                <DefaultMobileLayout />
            ) : (
                <DesktopSizeProvider>
                    <DefaultDesktopLayout />
                </DesktopSizeProvider>
            )}
        </DivRoot>
    )
}

const DefaultDesktopLayout = () => {
    const [open, setOpen] = useState(true)
    const [drawertemporaryopen, setDrawerTemporaryOpen] = useState(false)
    const [showprogress, setShowProgress] = useState(false)
    const [progress, setProgress] = useState(0)
    const theme = useTheme()
    const { state, setState } = useAppState()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { desktopBreakpoint, setDesktopBreakpoint } = useDesktopSize()
    const [parentNode, setParentNode] = useState<HTMLDivElement | null>(null)
    const rootRef = useRef<HTMLDivElement | null>(null)
    // const setParentRef = useCallback((node: HTMLDivElement | null) => {
    //     console.log('setParentRef', node);
    //     setParentNode(nodRootContainere);

    // });
    useEffect(() => {
        const updateBreakpoint = () => {
            if (rootRef.current) {
                setDesktopBreakpoint(rootRef.current.offsetWidth)
            }
        }
        updateBreakpoint()
        window.addEventListener('resize', updateBreakpoint)
        return () => {
            window.removeEventListener('resize', updateBreakpoint)
        }
    }, [])
    return (
        <Box>
            <DrawerView
                open={open}
                setOpen={setOpen}
                drawertemporaryopen={drawertemporaryopen}
                setDrawerTemporaryOpen={setDrawerTemporaryOpen}
                showprogress={showprogress}
                setShowProgress={setShowProgress}
                progress={progress}
                setProgress={setProgress}
            />
            <TopBarView
                open={open}
                setOpen={setOpen}
                drawertemporaryopen={drawertemporaryopen}
                setDrawerTemporaryOpen={setDrawerTemporaryOpen}
                showprogress={showprogress}
                setShowProgress={setShowProgress}
                progress={progress}
                setProgress={setProgress}
            />
            <RootContainer
                ref={rootRef}
                id="rootcontainer"
                isSmallScreen={isSmallScreen}
                drawerOpen={state.open}
            >
                <Outlet />
            </RootContainer>
        </Box>
    )
}

export default DefaultLayout
