import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { getPosts } from '../../../api/RestPost'
import { Box, Grid } from '@mui/material'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { error } from 'console'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import EventPostsTopBarMobileView from './EventPostsTopBarMobileView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import EmptyMediaPostsView from '../../Components/StaticPages/EmptyView/EmptyMediaPostsView'
import { EmptyPostsViewType } from '../../Components/StaticPages/EmptyView/EmptyPostsView'
import PostImageView from '../../PostView/PostImageView'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigateEventState } from '../../../models/NavigateState'
import { Event } from '../../../models/Event'
import { useApiManager } from '../../../api/ApiMangerProvider'

interface EventPostsMobileViewProps {
    eventState: Event | null | undefined
}

const EventPostsMobileView = ({ eventState }: EventPostsMobileViewProps) => {
    const { state } = useAppState()
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Best')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const [value, setValue] = useState(searchParams.get('tab') || 'all')
    const handleSortPopperOpen = (e: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(e.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }
    const handleChange = (e: React.SyntheticEvent, newValue: string) => {
        if (eventState) {
            navigate(`/app/event/${eventState.slug}/posts/?tab=${newValue}`, {
                state: {
                    event: eventState,
                    eventImage: null,
                },
            })
            setValue(newValue)
        }
    }
    useEffect(() => {
        setValue(searchParams.get('tab') || 'all')
    }, [searchParams.get('tab')])
    return (
        <Box
            sx={{
                mt: '102px',
                position: 'relative',
                mb: `${navMobileBarHeight}px`,
            }}
        >
            {eventState && (
                <>
                    <TabBox
                        sx={{
                            position: 'fixed',
                            top: '56px',
                            width: '100%',
                            zIndex: 2,
                            backgroundColor: 'background.primary',
                        }}
                    >
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="fullWidth"
                        >
                            <StyledTab label="All" value="all" />
                            <StyledTab label="Media" value="media" />
                            <StyledTab label="Attendees" value="attendees" />
                            <StyledTab label="Hosts" value="hosts" />
                        </StyledTabs>
                    </TabBox>
                    <EventPostsTopBarMobileView eventState={eventState} />
                    {value === 'all' ? (
                        <EventPostsAllMobileView event_slug={eventState.slug} />
                    ) : value === 'media' ? (
                        <EventPostsMediaMobileView
                            event_slug={eventState.slug}
                        />
                    ) : value === 'hosts' ? (
                        <EventPostsHostMobileView eventState={eventState} />
                    ) : (
                        <Box></Box>
                    )}
                </>
            )}
        </Box>
    )
}

interface EventPostsTabMobileViewProps {
    event_slug: string
}

interface EventPostsHostMobileViewProps {
    eventState: Event
}

const EventPostsHostMobileView = ({
    eventState,
}: EventPostsHostMobileViewProps) => {
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const location = useLocation()
    const locationEvent = location.state as NavigateEventState

    useEffect(() => {
        if (eventState) {
            apiManager
                .getPosts(
                    eventState.primary_host.username,
                    null,
                    eventState.slug,
                    20,
                    0
                )
                .then((response) => {
                    setPostState(response.posts)
                    setPostLoaded(DataStatus.success)
                })
                .catch((error) => {
                    setPostLoaded(DataStatus.failure)
                    console.log(error)
                })
        }
    }, [])
    return (
        <Box>
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        pt: '16px',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                postLoaded === DataStatus.success && (
                    <>
                        {postState.length > 0 ? (
                            <Box>
                                {postState.map((post) => (
                                    <Box>
                                        <PostMobileView post={post} />
                                        <PrimaryDivider />
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                )
            )}
        </Box>
    )
}

const EventPostsAllMobileView = ({
    event_slug,
}: EventPostsTabMobileViewProps) => {
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        apiManager
            .getPosts(null, null, event_slug, 20, 0)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box>
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        pt: '8px',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                postLoaded === DataStatus.success && (
                    <>
                        {postState.length > 0 ? (
                            <Box>
                                {postState.map((post) => (
                                    <Box>
                                        <PostMobileView post={post} />
                                        <PrimaryDivider />
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                )
            )}
        </Box>
    )
}
const EventPostsMediaMobileView = ({
    event_slug,
}: EventPostsTabMobileViewProps) => {
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        apiManager
            .getPosts(null, null, event_slug, 20, 0)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : postLoaded === DataStatus.success && postState.length === 0 ? (
                <EmptyMediaPostsView
                    name={event_slug}
                    type={EmptyPostsViewType.event}
                />
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        padding: '8px 16x',
                    }}
                >
                    <Grid container spacing={0}>
                        {postState.map((post) => (
                            <Grid item xs={4} md={4} lg={4}>
                                <PostImageView post={post} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default EventPostsMobileView
