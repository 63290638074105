import { Box, Grid, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Event } from '../../models/Event'
import AccountDetailsSubView from '../AccountView/AccountDesciptionSubView'
import { useEffect, useState } from 'react'
import AccountAttendeeSubView from '../AccountView/AccountAttendeeSubView'
import { TabBoxSelect } from '../Components/StyledComponents'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigateEventState } from '../../models/NavigateState'
import { useDesktopSize } from '../../utils/DesktopSizeProvider'
import { getBreakpoint } from '../../utils/GetBreakpoint'

interface EventAttendeesViewProps {
    event: Event
    eventImage: string | null
}

const EventAttendeesView = ({ event, eventImage }: EventAttendeesViewProps) => {
    const location = useLocation()
    const initialEventStateData = location.state as NavigateEventState
    const { desktopBreakpoint, setDesktopBreakpoint } = useDesktopSize()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const [formatState, setFormatState] = useState<number>(0)
    const theme = useTheme()
    const [value, setValue] = useState(searchParams.get('tab') || 'attendees')
    const [attendeesBreakpoint, setAttendeesBreakpoint] = useState<string>('')
    const handleChange = (newValue: string) => {
        if (event) {
            navigate(`/app/event/${event.slug}/attendees/?tab=${newValue}`, {
                state: {
                    event: event,
                    eventImage: eventImage,
                },
            })
            setValue(newValue)
        }
    }
    useEffect(() => {
        setValue(searchParams.get('tab') || 'attendees')
    }, [searchParams.get('tab')])
    useEffect(() => {
        console.log('desktopBreakpoint', desktopBreakpoint)
    }, [desktopBreakpoint])

    useEffect(() => {
        setAttendeesBreakpoint(
            getBreakpoint(
                desktopBreakpoint > theme.breakpoints.values.md
                    ? desktopBreakpoint * 0.8
                    : desktopBreakpoint,
                theme.breakpoints.values
            )
        )
    }, [desktopBreakpoint])
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    mt: '16px',
                    alignItems: 'center',
                    // ml: '16px',
                }}
            >
                <TabBoxSelect
                    selected={value === 'attendees'}
                    onClick={() => handleChange('attendees')}
                >
                    Attendees
                </TabBoxSelect>
                <TabBoxSelect
                    selected={value === 'following'}
                    sx={{
                        ml: '8px',
                    }}
                    onClick={() => handleChange('following')}
                >
                    Following
                </TabBoxSelect>
                <TabBoxSelect
                    selected={value === 'hosts'}
                    sx={{
                        ml: '8px',
                    }}
                    onClick={() => handleChange('hosts')}
                >
                    Hosts
                </TabBoxSelect>
            </Box>
            {formatState === 0 ? (
                <Grid
                    container
                    spacing={1}
                    sx={{
                        mt: '8px',
                        paddingBottom: '16px',
                        width: '100%',
                    }}
                >
                    {event.attendees.map((attendee) => (
                        <Grid
                            item
                            xs={6}
                            sm={attendeesBreakpoint === 'sm' ? 4 : false}
                            md={attendeesBreakpoint === 'md' ? 3 : false}
                            lg={attendeesBreakpoint === 'lg' ? 3 : false}
                            xl={attendeesBreakpoint === 'xl' ? 3 : false}
                        >
                            <AccountAttendeeSubView user={attendee} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box>
                    {event.attendees.map((attendee) => (
                        <AccountDetailsSubView user={attendee} />
                    ))}
                </Box>
            )}
        </Box>
    )
}

export default EventAttendeesView
