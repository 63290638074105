import { useEffect, useState } from 'react'
import { User } from '../../models/User'
import { getEvents } from '../../api/RestEvent'
import { useAppState } from '../../AppStateProvider'
import { Event } from '../../models/Event'
import { DataStatus } from '../../models/Dataloading'
import { Box, Grid } from '@mui/material'
import {
    StyledCircularProgress,
    TertiaryTypography,
} from '../Components/StyledComponents'
import EventSubView from '../EventView/EventSubView'
import ErrorTabView from '../Components/StaticPages/ErrorView/ErrorTabView'
import EmptyAttendingView from '../Components/StaticPages/EmptyView/EmptyAttendingView'
import { useApiManager } from '../../api/ApiMangerProvider'

interface AccountAttendingViewProps {
    user: User
}

const AccountAttendingView = ({ user }: AccountAttendingViewProps) => {
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [eventState, setEventState] = useState<Event[]>([])
    const [eventLoaded, setEventLoaded] = useState<DataStatus>(
        DataStatus.loading
    )

    useEffect(() => {
        try {
            apiManager
                .getEvents(null, user.username, null, 5, 20, 0)
                .then((response) => {
                    setEventState(response.events)
                    setEventLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setEventLoaded(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setEventLoaded(DataStatus.failure)
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {eventLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : eventLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : eventLoaded === DataStatus.success &&
              eventState.length === 0 ? (
                <EmptyAttendingView name={user.name} />
            ) : (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <TertiaryTypography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            marginLeft: '10px',
                            marginBottom: '4px',
                        }}
                    >
                        UPCOMMING
                    </TertiaryTypography>
                    <Grid container spacing={1}>
                        {eventState.map((event) => (
                            <Grid item xs={12} md={6}>
                                <EventSubView event={event} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default AccountAttendingView
