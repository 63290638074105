import { useState } from 'react'
import { styled } from '@mui/system'
import {
    Box,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Radio,
    Typography,
} from '@mui/material'
import { EventType, FilterData } from '../../../models/Filter'
import { PrimaryDivider } from '../../Components/StyledComponents'

const StyledRadio = styled(Radio)(({ theme }) => ({
    color: theme.palette.text.secondary,
    '&.Mui-checked': {
        color: theme.palette.background.selected,
    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
}))

const RadioLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: 14,
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

interface EventTypePickerViewProps {
    tempfilterstate: FilterData
    setTempFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    width: string
}
const EventTypePickerView = ({
    tempfilterstate,
    setTempFilterState,
    width,
}: EventTypePickerViewProps) => {
    const [value, setValue] = useState<String | null>(
        tempfilterstate.premium.toString()
    )
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        newValue: EventType
    ) => {
        setValue(event.target.value)
        setTempFilterState({ ...tempfilterstate, premium: newValue })
    }
    return (
        <Box sx={{ width: width }}>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.tertiary',
                    fontWeight: 'bold',
                }}
            >
                EVENT TYPE
            </Typography>
            <PrimaryDivider
                sx={{
                    m: '8px 0px',
                }}
            />
            <FormControl>
                <RadioGroup
                    value={value}
                    onChange={(event) => handleChange(event, EventType.None)}
                >
                    <FormControlLabel
                        value="Any"
                        control={<StyledRadio />}
                        label={<RadioLabel>Any</RadioLabel>}
                    />
                </RadioGroup>
                <RadioGroup
                    value={value}
                    onChange={(event) => handleChange(event, EventType.Free)}
                >
                    <FormControlLabel
                        value="Free"
                        control={<StyledRadio />}
                        label={<RadioLabel>Free</RadioLabel>}
                    />
                </RadioGroup>
                <RadioGroup
                    value={value}
                    onChange={(event) => handleChange(event, EventType.Premium)}
                >
                    <FormControlLabel
                        value="Premium"
                        control={<StyledRadio />}
                        label={<RadioLabel>Premium</RadioLabel>}
                    />
                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export default EventTypePickerView
