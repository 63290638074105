import { useNavigate } from 'react-router-dom'
import { Ticket } from '../../models/Ticket'
import { useAppState } from '../../AppStateProvider'
import { useEffect, useRef, useState } from 'react'
import { DataStatus } from '../../models/Dataloading'
import { grabEventPhoto } from '../../api/RestEvent'
import { grabUserPhoto } from '../../api/RestUser'
import { Box, ButtonBase, IconButton, Typography, styled } from '@mui/material'
import { LoadingBox, StyledChipHover } from '../Components/StyledComponents'
import AccountDetailsSubView from '../AccountView/AccountSubViews/AccountDetailsSubView'
import { iso2eventtime } from '../../utils/DateStringConverter'
import { FavoritedRoundedIcon } from '../Components/StyledComponents/StyledIcons'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import {
    navigateToEvent,
    navigateToGiftTickets,
    navigateToTicket,
} from '../../utils/NavigationFunctions'
import CardGiftcardRoundedIcon from '@mui/icons-material/CardGiftcardRounded'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined'
import { useApiManager } from '../../api/ApiMangerProvider'
import { useQuery } from 'react-query'

const EventCard = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: theme.palette.background.secondary,
    border: '1px solid ' + theme.palette.border.primary,
    borderRadius: '10px',
    padding: 10,

    position: 'relative',
    '&:hover': {
        backgroundColor: theme.palette.background.secondary,
    },
    cursor: 'pointer',
}))

interface TicketItemViewProps {
    ticket: Ticket
}
const TicketItemView = ({ ticket }: TicketItemViewProps) => {
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [ticketState, setTicketState] = useState<Ticket>(ticket)

    const {
        data: eventImage,
        isLoading: isEventImageLoading,
        error: eventImageError,
    } = useQuery(
        ticket.event.image,
        () => apiManager.grabEventPhoto(ticket.event.image),
        {
            onSuccess: () => {
                setEventImageStatus(DataStatus.success)
            },
            onError: () => {
                setEventImageStatus(DataStatus.failure)
            },
        }
    )
    const {
        data: userImage,
        isLoading: isUserImageLoading,
        error: userImageError,
    } = useQuery(
        ticket.event.primary_host.image,
        () => apiManager.grabUserPhoto(ticket.event.primary_host.image),
        {
            onSuccess: () => {
                setUserImageStatus(DataStatus.success)
            },
            onError: () => {
                setUserImageStatus(DataStatus.failure)
            },
        }
    )
    return (
        <EventCard
            sx={state.isMobile ? {} : { width: '100%', maxWidth: '500px' }}
            onClick={function (event) {
                event.stopPropagation()
                navigateToTicket(
                    ticketState,
                    eventImage,
                    navigate,
                    state,
                    setState
                )
            }}
        >
            <Box display="flex">
                <div
                    style={{
                        display: 'flex',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        width: 120,
                        height: 120,
                        aspectRatio: '1/1',
                    }}
                >
                    {eventImage ? (
                        <img
                            src={eventImage}
                            alt="Event"
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                                borderRadius: '10px',
                            }}
                        />
                    ) : eventimagestatus === DataStatus.loading ? (
                        <LoadingBox />
                    ) : (
                        <LoadingBox />
                    )}
                </div>
                <Box display="flex" alignItems="center">
                    <Box marginLeft={2}>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                                color: 'text.primary',
                            }}
                        >
                            {ticket.event.title}
                        </Typography>
                        <Box display="flex" alignItems="center" marginTop="4px">
                            <AccountDetailsSubView
                                user={ticket.event.primary_host}
                                followButton={false}
                                bio={false}
                                size={35}
                            />
                        </Box>
                        <Box display="flex" marginTop="4px">
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: 'text.selected',
                                }}
                            >
                                {iso2eventtime(
                                    ticketState.event.event_time,
                                    'short',
                                    'short'
                                )}
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'relative',
                                    height: '31px',
                                    width: '100%',
                                    alignItems: 'center',
                                }}
                            >
                                {state.isMobile ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            position: 'absolute',
                                            left: '-10px',
                                            alignItems: 'center',
                                            height: '31px',
                                        }}
                                    >
                                        <StyledChipHover
                                            component={ButtonBase}
                                            sx={{
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LocalActivityOutlinedIcon
                                                sx={{
                                                    color: 'text.primary',
                                                    mr: '8px',
                                                    fontSize: 16,
                                                }}
                                            />

                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    color: 'text.primary',
                                                }}
                                                noWrap
                                            >
                                                1
                                            </Typography>
                                        </StyledChipHover>
                                        <IconButton>
                                            <CardGiftcardRoundedIcon
                                                sx={{
                                                    color: 'text.primary',
                                                    fontSize: 16,
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton>
                                            <DeleteOutlineRoundedIcon
                                                sx={{
                                                    color: 'text.primary',

                                                    fontSize: 16,
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            position: 'absolute',
                                            left: '-10px',
                                        }}
                                    >
                                        <StyledChipHover
                                            component={ButtonBase}
                                            sx={{
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LocalActivityOutlinedIcon
                                                sx={{
                                                    color: 'text.primary',
                                                    mr: '8px',
                                                    fontSize: 16,
                                                }}
                                            />

                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    color: 'text.primary',
                                                }}
                                                noWrap
                                            >
                                                1 Ticket
                                            </Typography>
                                        </StyledChipHover>
                                        <StyledChipHover
                                            component={ButtonBase}
                                            sx={{
                                                alignItems: 'center',
                                            }}
                                            onClick={function (event) {
                                                event.stopPropagation()
                                                navigateToGiftTickets(
                                                    navigate,
                                                    ticketState,
                                                    eventImage,
                                                    userImage,
                                                    state,
                                                    setState
                                                )
                                            }}
                                        >
                                            <CardGiftcardRoundedIcon
                                                sx={{
                                                    color: 'text.primary',
                                                    mr: '8px',
                                                    fontSize: 16,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    color: 'text.primary',
                                                }}
                                            >
                                                Gift
                                            </Typography>
                                        </StyledChipHover>
                                        <StyledChipHover
                                            component={ButtonBase}
                                            sx={{
                                                alignItems: 'center',
                                            }}
                                        >
                                            <DeleteOutlineRoundedIcon
                                                sx={{
                                                    color: 'text.primary',
                                                    mr: '8px',
                                                    fontSize: 16,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    color: 'text.primary',
                                                }}
                                            >
                                                Delete
                                            </Typography>
                                        </StyledChipHover>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </EventCard>
    )
}

export default TicketItemView
