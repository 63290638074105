import { Box, Button, styled, Typography } from '@mui/material'
import { navigateToDiscover } from '../../../../utils/NavigationFunctions'
import { useApiManager } from '../../../../api/ApiMangerProvider'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../../../AppStateProvider'

const PrimaryTopTypography = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF',
}))

const SecondaryTopTypography = styled(Typography)(({ theme }) => ({
    color: '#C8CED1',
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: '#000000',
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: '#4D4E55',
}))

const CardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '20px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
}))

export const DiscoverButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    padding: '3px 20px',
    fontSize: 14,
    // backgroundColor: '#A872FF',
    background: 'linear-gradient(to right, #A872FF 10%, #914DFF 100%)',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#A872FF',
    },
    // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}))

const AboutMobileView = () => {
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    return (
        <Box
            sx={{
                height: '100%',
                mt: '54px',
                zIndex: -1,
            }}
        >
            <Box
                sx={{
                    height: '400px',
                    position: 'absolute',
                    top: '54px',
                    width: '100%',
                    // background: 'linear-gradient(to bottom, #B285FA 10%, #7C41DE 100%)',
                    background:
                        'linear-gradient(to bottom, #111111 10%, #252528 100%)',

                    // clipPath: "url(#myCurve)",
                    zIndex: 0,
                }}
            >
                <svg width="0" height="0">
                    {/* <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
          <path
            d="M 0,1
               L 0,0
               L 1,0
               L 1,1
               C .69 .8, .35 .8, 0 1
               Z"
          />
        </clipPath> */}
                </svg>
            </Box>
            <Box
                sx={{
                    zIndex: 1,
                    p: '16px 16px',
                    position: 'relative',
                    background:
                        'linear-gradient(to bottom, #111111 10%, #252528 100%)',
                }}
            >
                <PrimaryTopTypography
                    sx={{
                        fontSize: '40px',
                        fontWeight: '900',
                    }}
                >
                    The Event Social Network
                </PrimaryTopTypography>
                <SecondaryTopTypography
                    sx={{
                        fontSize: '14px',
                    }}
                >
                    Create, share, and experience events like never before. From
                    hosting to ticketing and social engagement, build meaningful
                    connections around every event.
                </SecondaryTopTypography>
                <DiscoverButton
                    sx={{
                        mt: '16px',
                        fontSize: '16px',
                    }}
                    onClick={() => navigate('/app/discover/search')}
                    // fullWidth
                >
                    Discover Events
                </DiscoverButton>
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            mt: '48px',
                            pb: '40px',
                        }}
                    >
                        {/* <img src={`${process.env.PUBLIC_URL}/page1-1.png`} 
                    height={200} 
                    /> */}
                        <img
                            src={`${process.env.PUBLIC_URL}/page1-2.png`}
                            height={200}
                        />
                        <img
                            src={`${process.env.PUBLIC_URL}/page1-3.png`}
                            height={200}
                        />
                    </Box>
                    <img
                        src={`${process.env.PUBLIC_URL}/page1-1.png`}
                        style={{
                            position: 'absolute',
                            top: 75,
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            height: '200px',
                            zIndex: 1,
                        }}
                    />
                </Box>
                {/* <Box
                sx={{
                    mt: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}>
                <img src={`${process.env.PUBLIC_URL}/appstore.svg`} 
                    height={50}/>
                    <img src={`${process.env.PUBLIC_URL}/googleplay.png`} 
                    height={49} 
                    />
                </Box> */}
            </Box>
            <Box
                sx={{
                    background: '#F0F0F4',
                    // mt: '16px',
                    p: '16px',
                }}
            >
                <PrimaryTypography
                    sx={{
                        fontSize: 30,
                        fontWeight: 'bold',
                    }}
                >
                    Experience Events in Real-Time
                </PrimaryTypography>
                <SecondaryTypography
                    sx={{
                        fontSize: 16,
                    }}
                >
                    Stay connected with the buzz as users share posts, videos,
                    and images from events they’re attending. Experience the
                    excitement through their eyes, join the conversation, and be
                    part of the moment even if you’re not there!
                </SecondaryTypography>
                <CardContainer
                    sx={{
                        height: '150px',
                        mt: '16px',
                        ml: 'auto',
                        mr: 'auto',
                    }}
                ></CardContainer>
            </Box>
        </Box>
    )
}

export default AboutMobileView
