import { useLocation, useParams } from 'react-router-dom'
import { NavigateEventTools } from '../../../../models/NavigateState'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { Event } from '../../../../models/Event'
import { useApiManager } from '../../../../api/ApiMangerProvider'
import { navMobileBarHeight } from '../../../../layouts/DefaultLayout/NavMobileBar'
import { Box, Typography } from '@mui/material'
import EventToolsTopBarMobileView from './EventToolsTopBarMobileView'
import EventToolsGeneralInfoMobileView from './EventToolsGeneralInfoMobileView'
import EventToolsDrawerMobileView from './EventToolsDrawerMobileView'
import EventToolsCheckInMobileView from './EventToolsCheckinMobileView'

const EventToolsMobileView = () => {
    const apiManager = useApiManager()
    const { slug } = useParams<{ slug: string }>()
    const location = useLocation()
    const locationEvent = location.state as NavigateEventTools
    const [eventState, setEventState] = useState<Event | null>(
        locationEvent ? locationEvent.event : null
    )
    const [eventImage, setEventImage] = useState<string | null>(
        locationEvent ? locationEvent.eventImage : null
    )
    const [showDrawer, setShowDrawer] = useState(false)
    const {
        data: eventQueryImage,
        isLoading: isEventImageLoading,
        error: userImageError,
    } = useQuery(
        eventState ? eventState.image : '',
        function () {
            if (eventState && !eventImage) {
                return apiManager.grabEventPhoto(eventState.image)
            }
        },
        {
            enabled: !!eventState && !!!eventImage,
            onSuccess: (data) => {
                if (data) setEventImage(data)
            },
        }
    )

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                pt: '56px',
                marginBottom: `${navMobileBarHeight + 60}px`,
            }}
        >
            {eventState && (
                <>
                    <EventToolsTopBarMobileView
                        event={eventState}
                        setShowDrawer={setShowDrawer}
                    />
                    <EventToolsDrawerMobileView
                        showDrawer={showDrawer}
                        setShowDrawer={setShowDrawer}
                        event={eventState}
                        eventImage={eventImage}
                    />

                    <Box>
                        <Typography
                            sx={{
                                color: 'text.primary',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                m: '16px',
                                mb: '8px',
                            }}
                        >
                            {window.location.pathname.includes('general')
                                ? 'General Information'
                                : window.location.pathname.includes('checkin')
                                  ? 'Check In'
                                  : window.location.pathname.includes(
                                          'analytics'
                                      )
                                    ? 'Analytics'
                                    : window.location.pathname.includes(
                                          'revenue'
                                      ) && 'Revenue'}
                        </Typography>
                        {window.location.pathname.includes('general') ? (
                            <EventToolsGeneralInfoMobileView
                                event={eventState}
                                eventImage={eventImage}
                            />
                        ) : window.location.pathname.includes('checkin') ? (
                            <EventToolsCheckInMobileView event={eventState} />
                        ) : window.location.pathname.includes('analytics') ? (
                            <></>
                        ) : (
                            window.location.pathname.includes('revenue') && (
                                <></>
                            )
                        )}
                    </Box>
                </>
            )}
        </Box>
    )
}

export default EventToolsMobileView
