import { useEffect, useState } from 'react'
import { User } from '../../../models/User'
import { getEvents } from '../../../api/RestEvent'
import { useAppState } from '../../../AppStateProvider'
import { Event } from '../../../models/Event'
import { DataStatus } from '../../../models/Dataloading'
import { Box, Grid } from '@mui/material'
import {
    PrimaryDivider,
    StyledCircularProgress,
    TabBoxSelect,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import EventSubView from '../../EventView/EventSubView'
import EmptyHostedEventsView from '../../Components/StaticPages/EmptyView/EmptyHostedEventsView'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import EventSubMobileView from '../../EventView/EventMobileView/EventSubMobileView'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { useLocation, useNavigate } from 'react-router-dom'

interface AccountHostingViewProps {
    user: User
    userImage: string | null
    eventImage: string | null
}

const AccountHostingMobileView = ({
    user,
    userImage,
    eventImage,
}: AccountHostingViewProps) => {
    const apiManager = useApiManager()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const navigate = useNavigate()
    const { state } = useAppState()
    const [eventState, setEventState] = useState<Event[]>([])
    const [eventLoaded, setEventLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventFilter, setEventFilter] = useState<string>(
        searchParams.get('filter') || 'upcoming'
    )
    useEffect(() => {
        try {
            apiManager
                .getEvents(user.username, null, null, 5, 20, 0)
                .then((response) => {
                    setEventState(response.events)
                    setEventLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setEventLoaded(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setEventLoaded(DataStatus.failure)
        }
    }, [])
    const handleChange = (newValue: string) => {
        navigate(`/app/account/${user.username}/events/?filter=${newValue}`, {
            state: {
                user: user,
                userImage: userImage,
                eventImage: eventImage,
            },
        })
        setEventFilter(newValue)
    }
    useEffect(() => {
        setEventFilter(searchParams.get('filter') || 'upcoming')
    }, [searchParams.get('filter')])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: 'calc(100% - 16px)',
                    alignItems: 'center',
                    pl: '16px',
                    mt: '16px',
                }}
            >
                <TabBoxSelect
                    selected={eventFilter === 'upcoming'}
                    onClick={() => handleChange('upcoming')}
                >
                    Upcoming
                </TabBoxSelect>
                <TabBoxSelect
                    selected={eventFilter === 'expired'}
                    sx={{
                        ml: '8px',
                    }}
                    onClick={() => handleChange('expired')}
                >
                    Expired
                </TabBoxSelect>
            </Box>
            {eventLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        mt: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : eventLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : eventLoaded === DataStatus.success &&
              eventState.length === 0 ? (
                <EmptyHostedEventsView name={user.name} />
            ) : (
                <Box>
                    <Box>
                        {eventState.map((event) => (
                            <Box
                                sx={{
                                    minWidth: '100vw',
                                }}
                            >
                                <EventSubMobileView event={event} />
                                <PrimaryDivider />
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default AccountHostingMobileView
