import { AppBar, Box, Toolbar, styled } from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import {
    IconMobileButton,
    StyledIconButton,
} from '../../views/Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 2,
    background: '#111111',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.8)',
}))

interface SplashTopBarMobileProps {
    navBarOpen: boolean
    setNavBarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SplashTopBarMobile = ({
    navBarOpen,
    setNavBarOpen,
}: SplashTopBarMobileProps) => {
    const { state, setState } = useAppState()

    return (
        <TopBar>
            <Toolbar
                sx={{ minHeight: '55px', p: '0px 16px', alignItems: 'center' }}
            >
                <img
                    src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                    height={30}
                />
                <Box sx={{ flexGrow: 1 }} />
                <IconMobileButton onClick={() => setNavBarOpen(!navBarOpen)}>
                    {navBarOpen ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
                </IconMobileButton>
            </Toolbar>
        </TopBar>
    )
}

export default SplashTopBarMobile
