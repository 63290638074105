import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { styled, withTheme } from '@mui/material/styles'
import {
    Drawer,
    Tab,
    TabProps,
    TabsProps,
    Tabs,
    Box,
    Divider,
    useMediaQuery,
    useTheme,
    IconButton,
    Fade,
    Collapse,
    BoxProps,
    Typography,
} from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import { getPosts } from '../../api/RestPost'
import { getEvents } from '../../api/RestEvent'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded'
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import LoginRoundedIcon from '@mui/icons-material/LoginRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Login, Margin } from '@mui/icons-material'
import {
    SecondaryTypography,
    TertiaryTypography,
} from '../../views/Components/StyledComponents'
import { clear } from 'console'
import zIndex from '@mui/material/styles/zIndex'
import {
    navigateTickets,
    navigateToAccount,
    navigateToDiscover,
    navigateToFeed,
} from '../../utils/NavigationFunctions'
import { set } from 'date-fns'
import { useApiManager } from '../../api/ApiMangerProvider'
import { useQueryClient } from 'react-query'

const drawerWidth = 220
const drawerClosedWidth = 60
const iconSize = 24
const feeditems = [
    {
        href: '/app/feed/fyp',
        icon: (
            <HomeOutlinedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'For You',
    },
    {
        href: '/app/feed/following',
        icon: (
            <PersonOutlineRoundedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Following',
    },
    {
        href: '/app/feed/favorited',
        icon: (
            <FavoriteBorderRoundedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Favorited',
    },
]
const discoveritems = [
    {
        href: '/app/discover/featured',
        icon: (
            <PublicRoundedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Featured',
    },
    {
        href: '/app/discover/search',
        icon: (
            <SearchRoundedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Discover',
    },
    {
        href: '/app/discover/favorited',
        icon: (
            <FavoriteBorderRoundedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Favorited',
    },
]

const accountitems = [
    {
        href: '/app/account',
        icon: (
            <PersonOutlineRoundedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Account',
    },
    {
        href: '/app/notification',
        icon: (
            <NotificationsNoneRoundedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Notifications',
    },
    {
        href: '/app/tickets',
        icon: (
            <LocalActivityOutlinedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Tickets',
    },
    {
        href: '/app/settings',
        icon: (
            <SettingsOutlinedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'Settings',
    },
]

const items2 = [
    {
        href: '/app/faq',
        icon: (
            <HelpOutlineRoundedIcon
                style={{ fontSize: iconSize, marginLeft: '10px' }}
            />
        ),
        name: 'FAQ',
    },
]

interface BoxTabProps extends BoxProps {
    open: boolean
    selected: boolean
}
const BoxTab = styled((props: BoxTabProps) => <Box {...props} />)(
    ({ theme, open, selected }) => ({
        height: 40,
        overflowX: 'hidden',
        minHeight: 40,
        fontSize: 14,
        textTransform: 'none',
        opacity: 1,
        color: theme.palette.text.secondary,
        cornerRadius: 50,
        display: 'flex',
        '&:hover': {
            color: theme.palette.text.primary,
            cornerRadius: 20,
            backgroundColor: theme.palette.background.hover,
            borderRadius: 10,
        },
        justifyContent: 'initial',
        alignItems: 'center',
        ...(selected && {
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: 10,
            '&:hover': {
                backgroundColor: theme.palette.background.tertiary,
            },
        }),
        ...(open
            ? {
                  width: drawerWidth - 16,
                  transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                  }),
              }
            : {
                  width: drawerClosedWidth - 16,
                  transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                  }),
              }),
        cursor: 'pointer',
    })
)

interface StyledTabProps extends TabProps {
    component?: React.ElementType
    to?: string
    open: boolean
}
const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(
    ({ theme, open }) => ({
        height: 40,
        minHeight: 40,
        fontSize: 14,
        paddingLeft: 10,
        textTransform: 'none',
        opacity: 1,
        color: theme.palette.text.secondary,
        cornerRadius: 50,
        '&:hover': {
            color: theme.palette.text.primary,
            cornerRadius: 20,
            backgroundColor: theme.palette.background.hover,
            borderRadius: 10,
        },
        justifyContent: 'initial',
        '&.MuiTab-root': {
            justifyContent: 'baseline',
            width: 20,
        },
        '&.Mui-selected': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: 10,
        },
        ...(open && {
            width: drawerWidth - 32,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        ...(!open && {
            width: 20,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
    })
)

interface StyledTabsProps extends TabsProps {
    open: boolean
}

const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} />)(
    ({ theme, open }) => ({
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
            color: 'transparent',
            width: 4,
        },
        ...(open && {
            width: drawerWidth - 10,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        ...(!open && {
            width: drawerClosedWidth - 10,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
    })
)

const StyledTabsTemporary = styled(Tabs)(({ theme }) => ({
    width: drawerWidth - 10,
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.text.selected,
        color: theme.palette.text.selected,
        width: 4,
    },
}))

const StyledDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    overflowX: 'hidden',
    paddingRight: 0,
    zIndex: theme.zIndex.drawer,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        paddingRight: 8,
        paddingLeft: 8,
        boxSizing: 'border-box',
        background: theme.palette.background.primary,
        paddingTop: 60,
        zIndex: theme.zIndex.drawer,
        borderRight: '1px solid ' + theme.palette.border.secondary,
        ...(open && {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        ...(!open && {
            width: drawerClosedWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
        }),
    },
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(open && {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!open && {
        width: drawerClosedWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
    }),
}))

const StyledTemporaryDrawer = styled(Drawer)(({ theme }) => ({
    paddingRight: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        paddingRight: 0,
        paddingLeft: 0,
        boxSizing: 'border-box',
        background: theme.palette.background.primary,
        paddingTop: 0,
    },
}))

const LogoIconButton = styled(IconButton)(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
    color: theme.palette.border.primary,
}))

interface DrawerViewProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    drawertemporaryopen: boolean
    setDrawerTemporaryOpen: React.Dispatch<React.SetStateAction<boolean>>
    showprogress: boolean
    setShowProgress: React.Dispatch<React.SetStateAction<boolean>>
    progress: number
    setProgress: React.Dispatch<React.SetStateAction<number>>
}

interface RoutesDict {
    [key: string]: number
}
const routes: RoutesDict = {
    '/app/home': 0,
    '/app/home/following': 1,
    '/app/home/favorited': 2,
    '/app/discover/featured': 5,
    '/app/discover': 6,
    '/app/discover/favorited': 7,
    '/app/account': 10,
    '/app/notification': 11,
    '/app/tickets': 12,
    '/app/settings': 13,
    '/app/faq': 16,
}

const getSelected = () => {
    const href = window.location.pathname
    console.log(href)
    if (href in routes) {
        console.log('found')
        return routes[href]
    } else return -1
}

const DrawerView = ({
    open,
    setOpen,
    drawertemporaryopen,
    setDrawerTemporaryOpen,
    showprogress,
    setShowProgress,
    progress,
    setProgress,
}: DrawerViewProps) => {
    const queryClient = useQueryClient()
    const apiManager = useApiManager()
    const [value, setValue] = React.useState(-1)
    const { state, setState } = useAppState()
    const handleChange = (event: any, newValue: number) => {
        setValue(newValue)
    }
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const navigator = useNavigate()

    const [navbarstate, setNavBarState] = React.useState({
        hover: 'none',
        selected: window.location.pathname,
        left: 0,
        top: 0,
    })
    const handleAccountNavigations = (href: string) => {
        console.log(href)
        if (href == '/app/account') {
            navigateToAccount(
                apiManager,
                queryClient,
                state.username,
                null,
                null,
                null,
                navigator,
                state,
                setState,
                'attending'
            )
        } else if (href == '/app/tickets') {
            navigateTickets(navigator, state, setState, 'active')
        }
    }

    useEffect(() => {
        setValue(getSelected())
    }, [window.location.pathname])

    return (
        <>
            {isSmallScreen ? (
                <StyledTemporaryDrawer
                    variant="temporary"
                    open={state.drawertemporaryopen}
                    onClose={() =>
                        setState({ ...state, drawertemporaryopen: false })
                    }
                >
                    <LogoIconButton disableRipple>
                        <img
                            src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                            height={30}
                        />
                    </LogoIconButton>
                    <StyledDivider
                        sx={{ marginBottom: '7px', marginTop: '2px' }}
                    />
                </StyledTemporaryDrawer>
            ) : (
                <StyledDrawer variant="permanent" open={state.open}>
                    <Collapse in={state.open}>
                        <TertiaryTypography
                            fontSize={13}
                            fontWeight={'bold'}
                            sx={{ marginLeft: '10px', padding: '5px 0px' }}
                        >
                            FEED
                        </TertiaryTypography>
                    </Collapse>
                    {feeditems.map(function ({ icon, name, href }, index) {
                        return (
                            <BoxTab
                                onClick={() =>
                                    navigateToFeed(
                                        apiManager,
                                        navigator,
                                        state,
                                        setState,
                                        href
                                    )
                                }
                                open={state.open}
                                sx={{ margin: '2px 0px' }}
                                selected={window.location.pathname === href}
                            >
                                {icon}
                                <Fade in={state.open}>
                                    <Typography
                                        sx={{
                                            marginLeft: '8px',
                                            fontSize: 14,
                                            color: (theme) =>
                                                value === index
                                                    ? theme.palette.text.primary
                                                    : theme.palette.text
                                                          .secondary,
                                        }}
                                    >
                                        {name}
                                    </Typography>
                                </Fade>
                            </BoxTab>
                        )
                    })}
                    <StyledDivider
                        sx={{
                            marginTop: '4px',
                            marginBottom: '4px',
                        }}
                    />
                    <Collapse in={state.open}>
                        <TertiaryTypography
                            fontSize={13}
                            fontWeight={'bold'}
                            sx={{ marginLeft: '10px', padding: '5px 0px' }}
                        >
                            DISCOVER
                        </TertiaryTypography>
                    </Collapse>
                    {discoveritems.map(function ({ icon, name, href }, index) {
                        return (
                            <BoxTab
                                onClick={() =>
                                    navigateToDiscover(
                                        apiManager,
                                        navigator,
                                        state,
                                        setState,
                                        href
                                    )
                                }
                                open={state.open}
                                sx={{ margin: '2px 0px' }}
                                selected={window.location.pathname === href}
                            >
                                {icon}
                                <Fade in={state.open}>
                                    <Typography
                                        sx={{
                                            marginLeft: '8px',
                                            fontSize: 14,
                                            color: (theme) =>
                                                value === index + 3
                                                    ? theme.palette.text.primary
                                                    : theme.palette.text
                                                          .secondary,
                                        }}
                                    >
                                        {name}
                                    </Typography>
                                </Fade>
                            </BoxTab>
                        )
                    })}
                    <StyledDivider
                        sx={{
                            marginTop: '4px',
                            marginBottom: '4px',
                        }}
                    />
                    <Collapse in={state.open}>
                        <TertiaryTypography
                            fontSize={13}
                            fontWeight={'bold'}
                            sx={{ marginLeft: '10px', padding: '5px 0px' }}
                        >
                            ACCOUNT
                        </TertiaryTypography>
                    </Collapse>
                    {accountitems.map(function ({ icon, name, href }, index) {
                        return (
                            <BoxTab
                                open={state.open}
                                sx={{ margin: '2px 0px' }}
                                selected={
                                    window.location.pathname === href ||
                                    (window.location.pathname.includes(
                                        `/app/account/${state.username}`
                                    ) &&
                                        href === '/app/account') ||
                                    (window.location.pathname.includes(
                                        `/app/tickets/`
                                    ) &&
                                        href === '/app/tickets')
                                }
                                onClick={() => handleAccountNavigations(href)}
                            >
                                {icon}
                                <Fade in={state.open}>
                                    <Typography
                                        sx={{
                                            marginLeft: '8px',
                                            fontSize: 14,
                                            color: (theme) =>
                                                value === index
                                                    ? theme.palette.text.primary
                                                    : theme.palette.text
                                                          .secondary,
                                        }}
                                    >
                                        {name}
                                    </Typography>
                                </Fade>
                            </BoxTab>
                        )
                    })}
                    <StyledDivider
                        sx={{
                            marginTop: '4px',
                            marginBottom: '4px',
                        }}
                    />
                </StyledDrawer>
            )}
        </>
    )
}
export default DrawerView
