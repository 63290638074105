import React, { useState, useEffect } from 'react'
import TopBarView from './TopBar'
import { AppBar, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import LoginView from '../../views/AuthView/LoginView'
import CreateAccountView from '../../views/AuthView/CreateAccountView'
import { useAppState } from '../../AppStateProvider'
import SplashTopBarMobile from './TopBarMobile'
import SplashNavBarMobile from './SplashNavBarMobile'

const DivRoot = styled('div')(({ theme }) => ({
    backgroundColor: 'white',
}))

interface SplashMobileLayoutProps {
    showLogin: boolean
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
}
const SplashMobileLayout = ({
    showLogin,
    setShowLogin,
}: SplashMobileLayoutProps) => {
    const { state } = useAppState()
    const [navBarOpen, setNavBarOpen] = useState(false)
    return (
        <DivRoot>
            <SplashTopBarMobile
                navBarOpen={navBarOpen}
                setNavBarOpen={setNavBarOpen}
            />
            <SplashNavBarMobile
                navBarOpen={navBarOpen}
                setNavBarOpen={setNavBarOpen}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
            <Outlet />
        </DivRoot>
    )
}

const SplashLayoutMobileView = () => {
    const { state } = useAppState()
    const [showLogin, setShowLogin] = useState(false)
    const [showRegister, setShowRegister] = useState(false)
    useEffect(() => {
        console.log(window.location.pathname)
    }, [window.location.pathname])
    return (
        <>
            {!window.location.pathname.includes('login') &&
            !window.location.pathname.includes('createaccount') ? (
                <DivRoot>
                    <SplashMobileLayout
                        showLogin={showLogin}
                        setShowLogin={setShowLogin}
                    />
                </DivRoot>
            ) : window.location.pathname == '/login' ? (
                <div style={{ position: 'absolute' }}>
                    <LoginView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                    />
                </div>
            ) : (
                window.location.pathname.includes('createaccount') && (
                    <div style={{ position: 'absolute' }}>
                        <CreateAccountView
                            setShowLogin={setShowLogin}
                            setShowRegister={setShowRegister}
                        />
                    </div>
                )
            )}
        </>
    )
}
const SplashLayoutDesktopView = () => {
    const { state } = useAppState()
    const [showLogin, setShowLogin] = useState(false)
    const [showRegister, setShowRegister] = useState(false)
    useEffect(() => {
        console.log(window.location.pathname)
    }, [window.location.pathname])
    return (
        <DivRoot>
            <TopBarView
                showLogin={showLogin}
                setShowLogin={setShowLogin}
                setShowRegister={setShowRegister}
            />
            <Container style={{ width: '100%', marginTop: 50 }}>
                <Outlet />
            </Container>
            {window.location.pathname == '/login' && (
                <div style={{ position: 'absolute' }}>
                    <LoginView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                    />
                </div>
            )}
            {window.location.pathname.includes('createaccount') && (
                <div style={{ position: 'absolute' }}>
                    <CreateAccountView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                    />
                </div>
            )}
        </DivRoot>
    )
}

const SplashLayout = () => {
    const { state } = useAppState()
    const [showLogin, setShowLogin] = useState(false)
    const [showRegister, setShowRegister] = useState(false)
    return (
        <>
            {state.isMobile ? (
                <SplashLayoutMobileView />
            ) : (
                <SplashLayoutDesktopView />
            )}
        </>
    )
}

export default SplashLayout
