import {
    Box,
    Collapse,
    Divider,
    Fade,
    Icon,
    IconButton,
    styled,
    Typography,
} from '@mui/material'
import { DefaultButton } from '../Components/StyledComponents'
import { Event } from '../../models/Event'
import { useState } from 'react'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'

const TicketContainer = styled(Box)(({ theme }) => ({
    background: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
}))

const SelectorContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 50,
    background: theme.palette.background.tertiary,
    padding: '4px',
}))
const AddIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.selected,
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
    color: theme.palette.text.primary,
    width: '35px',
    height: '35px',
}))

const RemoveIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.quaternary,
    '&:hover': {
        backgroundColor: theme.palette.background.quaternary,
    },
    color: theme.palette.text.primary,
    width: '35px',
    height: '35px',
}))

const SelectIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.selected,
    padding: '8px',
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
    color: theme.palette.border.primary,
    borderStyle: 'dashed',
}))
interface TicketSelectItemProps {
    eventState: Event
    selectedAmount: number
    setSelectedAmount: React.Dispatch<React.SetStateAction<number>>
}
const TicketSelectItem = ({
    eventState,
    selectedAmount,
    setSelectedAmount,
}: TicketSelectItemProps) => {
    const [expandDescription, setExpandDescription] = useState(false)
    return (
        <TicketContainer
            sx={{
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    p: '8px 16px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '16px',
                        color: 'text.primary',
                    }}
                >
                    General Admission
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        mt: '4px',
                        color: 'text.secondary',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: expandDescription ? 'none' : 3,
                    }}
                >
                    Your general admission tickets grants you full access to the
                    event, allowing you to enjoy all the attractions and
                    experiences available on the day.
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        mt: '4px',
                        color: 'text.selected',
                    }}
                    onClick={() => {
                        setExpandDescription(!expandDescription)
                    }}
                >
                    {expandDescription ? 'Show less' : 'Show more'}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        mt: '8px',
                        alignItems: 'center',
                    }}
                >
                    <DefaultButton>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: 'text.primary',
                                fontWeight: 'bold',
                            }}
                        >
                            Free
                        </Typography>
                    </DefaultButton>
                    <Typography
                        sx={{
                            ml: '8px',
                            fontSize: '14px',
                            color: 'text.secondary',
                        }}
                    >
                        {eventState.attendee_limit} tickets
                    </Typography>
                </Box>
            </Box>
            <StyledDivider orientation="vertical" flexItem />
            <Box
                sx={{
                    p: '8px 16px',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    flexDirection: 'column',
                    display: 'flex',
                }}
            >
                <SelectorContainer>
                    {selectedAmount > 0 && (
                        <>
                            <RemoveIconButton
                                disabled={selectedAmount == 0}
                                onClick={() => {
                                    setSelectedAmount(selectedAmount - 1)
                                }}
                            >
                                <RemoveRoundedIcon
                                    sx={{
                                        fontSize: '24px',
                                    }}
                                />
                            </RemoveIconButton>
                            <Typography
                                sx={{
                                    m: '0 12px',
                                    fontSize: '16px',
                                    color: 'text.primary',
                                    fontWeight: 'bold',
                                }}
                            >
                                {selectedAmount}
                            </Typography>
                        </>
                    )}
                    <AddIconButton
                        disabled={selectedAmount >= eventState.attendee_limit}
                        onClick={() => {
                            setSelectedAmount(selectedAmount + 1)
                        }}
                    >
                        <AddRoundedIcon
                            sx={{
                                fontSize: '24px',
                            }}
                        />
                    </AddIconButton>
                </SelectorContainer>
            </Box>
        </TicketContainer>
    )
}

export default TicketSelectItem
