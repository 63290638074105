import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Event } from '../../../../models/Event'
import { useApiManager } from '../../../../api/ApiMangerProvider'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../../../AppStateProvider'
import { navigateToEvent } from '../../../../utils/NavigationFunctions'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import { iso2eventtime } from '../../../../utils/DateStringConverter'
import { timeDifferenceBetweenTimes } from '../../../../utils/TimeDifference'
import { PrimaryDivider } from '../../../Components/StyledComponents'

const EventPhotoCircle = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
}))

const TopBar = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.header,
}))

const DetailsBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.background.secondary,
    height: '100%',
}))

interface EventToolsGeneralInfoMobileViewProps {
    event: Event
    eventImage: string | null
}

const EventToolsGeneralInfoMobileView = ({
    event,
    eventImage,
}: EventToolsGeneralInfoMobileViewProps) => {
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    return (
        <Box
            sx={{
                p: '0px 16px',
                width: 'calc(100% - 32px)',
            }}
        >
            {/* <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <EventPhotoCircle
                    sx={{
                        height: '60px',
                        width: '60px',
                        overflow: 'hidden',
                    }}
                >
                    {eventImage && (
                        <img
                            src={eventImage}
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    )}
                </EventPhotoCircle>
                <Box
                    sx={{
                        ml: '16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '30px',
                            color: 'text.primary',
                            fontWeight: 'bold',
                        }}
                    >
                        {event.title}
                    </Typography>
                    <Box
                        sx={{
                            alighItems: 'left',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {event.favorited ? (
                                <FavoriteRoundedIcon
                                    sx={{
                                        marginRight: '4px',
                                        fontSize: 18,
                                        color: 'text.favorite',
                                    }}
                                />
                            ) : (
                                <FavoriteBorderRoundedIcon
                                    sx={{
                                        marginRight: '4px',
                                        fontSize: 18,
                                        color: 'text.secondary',
                                    }}
                                />
                            )}
                            <Typography color="text.secondary" fontSize={14}>
                                {event.favorites_count} likes
                            </Typography>
                        </Box>
                        <Box width={16} />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={() =>
                                navigateToEvent(
                                    apiManager,
                                    event,
                                    eventImage,
                                    navigate,
                                    state,
                                    setState,
                                    'posts'
                                )
                            }
                        >
                            <RepeatRoundedIcon
                                sx={{
                                    marginRight: '4px',
                                    fontSize: 18,
                                    color: 'text.secondary',
                                }}
                            />
                            <Typography color="text.secondary" fontSize={14}>
                                {event.post_count} posts
                            </Typography>
                        </Box>
                        <Box width={16} />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={() =>
                                navigateToEvent(
                                    apiManager,
                                    event,
                                    eventImage,
                                    navigate,
                                    state,
                                    setState,
                                    'attendees'
                                )
                            }
                        >
                            <PersonOutlineRoundedIcon
                                sx={{
                                    marginRight: '4px',
                                    fontSize: 18,
                                    color: 'text.secondary',
                                }}
                            />

                            <Typography color="text.secondary" fontSize={14}>
                                {event.attendee_count} attendees
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box> */}
            <DetailsBox
                sx={{
                    mt: '16px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 14,
                        marginLeft: '16px',
                        fontWeight: 'bold',
                        color: 'text.tertiary',
                        mt: '8px',
                    }}
                >
                    EVENT META DATA
                </Typography>
                <PrimaryDivider
                    sx={{
                        m: '8px 16px',
                        mb: '0px',
                    }}
                />
                <Box
                    sx={{
                        p: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Attendee limit
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {event.attendee_limit}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Ticket price range
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            Free
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Number of hosts
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            1
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Event start time
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {iso2eventtime(
                                event.event_time,
                                undefined,
                                'short'
                            )}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Event end time
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {iso2eventtime(event.end_time, undefined, 'short')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Event duration
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {timeDifferenceBetweenTimes(
                                event.event_time,
                                event.end_time
                            )}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Privacy
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {event.private ? 'Private' : 'Public'}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Active
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {event.active ? 'Yes' : 'No'}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Creation date
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {iso2eventtime(
                                event.created_at,
                                undefined,
                                'short'
                            )}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Latest update
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {iso2eventtime(
                                event.updated_at,
                                undefined,
                                'short'
                            )}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            Number of tags
                        </Typography>
                        <Box flex={1} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {event.tags.length}
                        </Typography>
                    </Box>
                </Box>
            </DetailsBox>
        </Box>
    )
}

export default EventToolsGeneralInfoMobileView
