import {
    Box,
    BoxProps,
    Collapse,
    Drawer,
    IconButton,
    List,
    styled,
    Typography,
} from '@mui/material'
import { useAppState } from '../../../../AppStateProvider'
import zIndex from '@mui/material/styles/zIndex'
import {
    PrimaryDivider,
    StyledIconButton,
} from '../../../Components/StyledComponents'
import { useNavigate } from 'react-router-dom'
import { useApiManager } from '../../../../api/ApiMangerProvider'
import { useState } from 'react'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { Event } from '../../../../models/Event'
import { navigateToEventTools } from '../../../../utils/NavigationFunctions'

interface BoxTabProps extends BoxProps {
    selected: boolean
}
const BoxTab = styled((props: BoxTabProps) => <Box {...props} />)(
    ({ theme, selected }) => ({
        height: 40,
        minHeight: 40,
        fontSize: 14,
        textTransform: 'none',
        opacity: 1,
        color: theme.palette.text.secondary,
        cornerRadius: 50,
        display: 'flex',
        '&:hover': {
            color: theme.palette.text.primary,
            cornerRadius: 20,
            backgroundColor: theme.palette.background.hover,
            borderRadius: 10,
        },
        justifyContent: 'initial',
        alignItems: 'center',
        ...(selected && {
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: 10,
            '&:hover': {
                backgroundColor: theme.palette.background.tertiary,
            },
        }),
        width: 300 - 32,
        cursor: 'pointer',
    })
)

const EventPhotoCircle = styled(Box)(({ theme }) => ({
    borderRadius: '50%',
    backgroundColor: theme.palette.background.tertiary,
}))

interface StyledArrowForwardIosRoundedIconProps
    extends React.ComponentProps<typeof ArrowForwardIosRoundedIcon> {
    open: boolean
}
const StyledArrowForwardIosRoundedIcon = styled(
    (props: StyledArrowForwardIosRoundedIconProps) => (
        <ArrowForwardIosRoundedIcon {...props} />
    )
)(({ theme, open }) => ({
    color: theme.palette.text.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: 300,
        boxSizing: 'border-box',
        background: theme.palette.background.primary,
        // borderRight: '1px solid ' + theme.palette.border.primary,
        zIndex: zIndex.drawer + 5,
    },
    zIndex: zIndex.drawer + 5,
}))

interface EventToolsDrawerMobileViewProps {
    showDrawer: boolean
    setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>
    event: Event
    eventImage: string | null | undefined
}
const EventToolsDrawerMobileView = ({
    showDrawer,
    setShowDrawer,
    event,
    eventImage,
}: EventToolsDrawerMobileViewProps) => {
    const { state, setState } = useAppState()
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const [showItems, setShowItems] = useState(['recent', 'trending'])
    const handleCollapse = (item: string) => {
        if (showItems.includes(item)) {
            setShowItems(showItems.filter((i) => i !== item))
        } else {
            setShowItems([...showItems, item])
        }
    }
    return (
        <StyledDrawer
            variant="temporary"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            anchor="right"
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: '0px 16px',
                        height: 55,
                    }}
                >
                    <StyledIconButton>
                        <ClearRoundedIcon
                            onClick={() => setShowDrawer(false)}
                            sx={{
                                color: 'text.primary',
                            }}
                        />
                    </StyledIconButton>
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        pb: '16px',
                        justifyContent: 'center',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <EventPhotoCircle
                        sx={{
                            height: '100px',
                            width: '100px',
                            overflow: 'hidden',
                        }}
                    >
                        {eventImage && (
                            <img
                                src={eventImage}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                    </EventPhotoCircle>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.primary',
                            mt: '16px',
                        }}
                    >
                        {event.title}
                    </Typography>
                </Box>
                <PrimaryDivider />
            </Box>
            <Box
                sx={{
                    p: '16px',
                }}
            >
                <BoxTab
                    selected={window.location.pathname.includes('general')}
                    onClick={() => {
                        setShowDrawer(false)
                        navigateToEventTools(
                            event,
                            eventImage,
                            navigate,
                            state,
                            setState,
                            'general'
                        )
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: window.location.pathname.includes('general')
                                ? 'text.primary'
                                : 'text.secondary',
                            ml: '16px',
                        }}
                    >
                        General
                    </Typography>
                </BoxTab>
                <BoxTab
                    sx={{
                        mt: '8px',
                    }}
                    selected={window.location.pathname.includes('checkin')}
                    onClick={() => {
                        setShowDrawer(false)
                        navigateToEventTools(
                            event,
                            eventImage,
                            navigate,
                            state,
                            setState,
                            'checkin'
                        )
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: window.location.pathname.includes('checkin')
                                ? 'text.primary'
                                : 'text.secondary',
                            ml: '16px',
                        }}
                    >
                        Check In
                    </Typography>
                </BoxTab>
                <BoxTab
                    sx={{
                        mt: '8px',
                    }}
                    selected={window.location.pathname.includes('analytics')}
                    onClick={() => {
                        setShowDrawer(false)
                        navigateToEventTools(
                            event,
                            eventImage,
                            navigate,
                            state,
                            setState,
                            'analytics'
                        )
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: window.location.pathname.includes(
                                'analytics'
                            )
                                ? 'text.primary'
                                : 'text.secondary',
                            ml: '16px',
                        }}
                    >
                        Analytics
                    </Typography>
                </BoxTab>
                <BoxTab
                    sx={{
                        mt: '8px',
                    }}
                    selected={window.location.pathname.includes('revenue')}
                    onClick={() => {
                        setShowDrawer(false)
                        navigateToEventTools(
                            event,
                            eventImage,
                            navigate,
                            state,
                            setState,
                            'revenue'
                        )
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: window.location.pathname.includes('revenue')
                                ? 'text.primary'
                                : 'text.secondary',
                            ml: '16px',
                        }}
                    >
                        Revenue
                    </Typography>
                </BoxTab>
            </Box>
        </StyledDrawer>
    )
}

export default EventToolsDrawerMobileView
