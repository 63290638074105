import React, { useEffect, useRef, useState } from 'react'
import { User } from '../../../models/User'
import { followUser, grabUserPhoto, unfollowUser } from '../../../api/RestUser'
import { useAppState } from '../../../AppStateProvider'
import { Box, Typography } from '@mui/material'
import {
    FollowButton,
    PrimaryTypography,
    SecondaryTypography,
    UnfollowButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import AccountPopoverView from '../AccountPopoverView'
import { navigateToAccount } from '../../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import AccountAvatarSubView from '../AccountAvatarSubView'
import { DataStatus } from '../../../models/Dataloading'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { useQuery, useQueryClient } from 'react-query'

interface AccountDetailsSubViewProps {
    user: User
    followButton: boolean
    bio: boolean
    size: number
}

const AccountDetailsSubView = ({
    user,
    followButton,
    bio,
    size,
}: AccountDetailsSubViewProps) => {
    const queryClient = useQueryClient()
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const [userState, setUserState] = useState<User>(user)
    const { state, setState } = useAppState()
    // const [userImage, setUserImage] = useState<string | null>(null)
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )

    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }
    const {
        data: userImage,
        isLoading: isUserImageLoading,
        error: userImageError,
    } = useQuery(user.image, () => apiManager.grabUserPhoto(user.image))
    // useEffect(() => {
    //     try {
    //         apiManager
    //             .grabUserPhoto(userState.image)
    //             .then((response) => {
    //                 setUserImage(response)
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, [])

    const handleFollow = (event: React.MouseEvent) => {
        event.stopPropagation()
        setUserState({
            ...userState,
            follower_size: userState.follower_size + 1,
            following: true,
        })
        try {
            apiManager
                .followUser(userState.username)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleUnfollow = (event: React.MouseEvent) => {
        event.stopPropagation()
        setUserState({
            ...userState,
            follower_size: userState.follower_size - 1,
            following: false,
        })
        try {
            apiManager
                .unfollowUser(userState.username)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width={'100%'}
        >
            <Box
                display="flex"
                alignItems="center"
                position={'relative'}
                width={'100%'}
            >
                <Box>
                    {followButton ? (
                        <UserPhotoCircle
                            includeBorder={false}
                            sx={{
                                width: size,
                                height: size,
                                borderRadius: '50%',
                                marginRight: '8px',
                                overflow: 'hidden',
                            }}
                        >
                            {userImage && (
                                <img
                                    src={userImage}
                                    alt="User"
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                        cursor: 'pointer',
                                    }}
                                    onMouseOver={(e) =>
                                        !state.isMobile &&
                                        handleAccountPopperOpen(e)
                                    }
                                    onMouseLeave={(e) =>
                                        !state.isMobile &&
                                        handleAccountPopperClose()
                                    }
                                    onClick={function (event) {
                                        event.stopPropagation()
                                        navigateToAccount(
                                            apiManager,
                                            queryClient,
                                            userState.username,
                                            userState,
                                            userImage,
                                            null,
                                            navigate,
                                            state,
                                            setState,
                                            'attending'
                                        )
                                    }}
                                />
                            )}
                        </UserPhotoCircle>
                    ) : (
                        <AccountAvatarSubView
                            user={userState}
                            userimage={userImage}
                            isUserImageLoading={isUserImageLoading}
                            handleAccountPopperClose={handleAccountPopperClose}
                            handleAccountPopperOpen={handleAccountPopperOpen}
                            following={userState.following}
                            height={size}
                            width={size}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            marginLeft: followButton ? '0px' : '8px',
                        }}
                    >
                        <Box display="flex">
                            <PrimaryTypography
                                sx={{
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                                onMouseOver={(e) =>
                                    !state.isMobile &&
                                    handleAccountPopperOpen(e)
                                }
                                onMouseLeave={(e) =>
                                    !state.isMobile &&
                                    handleAccountPopperClose()
                                }
                                onClick={function (event) {
                                    event.stopPropagation()
                                    navigateToAccount(
                                        apiManager,
                                        queryClient,
                                        userState.username,
                                        userState,
                                        userImage,
                                        null,
                                        navigate,
                                        state,
                                        setState,
                                        'attending'
                                    )
                                }}
                            >
                                {userState.name}
                            </PrimaryTypography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    ml: '4px',
                                    color: 'text.secondary',
                                }}
                            >
                                @{userState.username}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <SecondaryTypography fontSize={12}>
                                {userState.follower_size} followers
                            </SecondaryTypography>
                            <SecondaryTypography
                                fontSize={12}
                                marginLeft={'8px'}
                            >
                                0 events
                            </SecondaryTypography>
                        </Box>
                        <PrimaryTypography>{userState.bio}</PrimaryTypography>
                    </Box>
                    {followButton && (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'right',
                                justifyContent: 'right',
                            }}
                        >
                            {state.username !== userState.username &&
                                (userState.following ? (
                                    <UnfollowButton onClick={handleUnfollow}>
                                        Following
                                    </UnfollowButton>
                                ) : (
                                    <FollowButton onClick={handleFollow}>
                                        Follow
                                    </FollowButton>
                                ))}
                        </Box>
                    )}
                </Box>
                {!state.isMobile && (
                    <AccountPopoverView
                        user={userState}
                        setUser={setUserState}
                        openpopover={openAccountPopover}
                        setOpenPopover={setOpenAccountPopover}
                        anchorEl={accountAnchorEl}
                        setAnchorEl={setAccountAnchorEl}
                        timerRef={timerRef}
                    />
                )}
            </Box>
        </Box>
    )
}

export default AccountDetailsSubView
