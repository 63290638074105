import { useMediaQuery } from '@mui/material'
import React, { createContext, useContext } from 'react'

interface MobileProviderProps {
    children: React.ReactNode
}

const MobileContext = createContext(false)

export const MobileProvider: React.FC<MobileProviderProps> = ({ children }) => {
    const isMobile = useMediaQuery('(max-width: 480px)')

    return (
        <MobileContext.Provider value={isMobile}>
            {children}
        </MobileContext.Provider>
    )
}

export const useMobile = () => {
    return useContext(MobileContext)
}
