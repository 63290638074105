import { useEffect, useState } from 'react'
import { Box, ButtonBase, Grid, List, Typography } from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import { getPosts } from '../../api/RestPost'
import { Post } from '../../models/Post'
import {
    PrimaryDivider,
    StyledChip,
    StyledCircularProgress,
    TabBoxSelect,
} from '../Components/StyledComponents'
import PostView from '../PostView/PostView'
import SortPopper from '../Components/SortPopper'
import { StyledArrowForwardIosRoundedIcon } from '../Components/StyledComponents/StyledIcons'
import { Event } from '../../models/Event'
import { useApiManager } from '../../api/ApiMangerProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { DataStatus } from '../../models/Dataloading'
import ErrorTabView from '../Components/StaticPages/ErrorView/ErrorTabView'
import EmptyMediaPostsView from '../Components/StaticPages/EmptyView/EmptyMediaPostsView'
import { EmptyPostsViewType } from '../Components/StaticPages/EmptyView/EmptyPostsView'
import PostImageView from '../PostView/PostImageView'

interface EventPostsViewProps {
    event: Event
    eventImage: string | null
}

const EventPostsView = ({ event, eventImage }: EventPostsViewProps) => {
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [posts, setPosts] = useState<Post[]>([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Best')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const searchParams = new URLSearchParams(location.search)
    const [value, setValue] = useState(searchParams.get('tab') || 'all')
    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }
    const handleChange = (newValue: string) => {
        if (event) {
            navigate(`/app/event/${event.slug}/posts/?tab=${newValue}`, {
                state: {
                    event: event,
                    eventImage: eventImage,
                },
            })
            setValue(newValue)
        }
    }
    useEffect(() => {
        apiManager.getPosts(null, null, event.slug, 20, 0).then((response) => {
            if ('errorCode' in response) {
                setLoading(false)
            } else {
                setPosts(response.posts)
                setLoading(false)
            }
        })
    }, [])

    return (
        <Box sx={{ width: '100%', marginTop: '16px' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    m: '16px 0px',
                    // ml: '16px',
                }}
            >
                <TabBoxSelect
                    selected={value === 'all'}
                    onClick={() => handleChange('all')}
                >
                    All
                </TabBoxSelect>
                <TabBoxSelect
                    sx={{
                        ml: '8px',
                    }}
                    selected={value === 'media'}
                    onClick={() => handleChange('media')}
                >
                    Media
                </TabBoxSelect>
                <TabBoxSelect
                    sx={{
                        ml: '8px',
                    }}
                    selected={value === 'attendees'}
                    onClick={() => handleChange('attendees')}
                >
                    Attendees
                </TabBoxSelect>
                <TabBoxSelect
                    selected={value === 'hosts'}
                    sx={{
                        ml: '8px',
                    }}
                    onClick={() => handleChange('hosts')}
                >
                    Hosts
                </TabBoxSelect>
            </Box>
            {value === 'all' ? (
                <EventPostsAllView event={event} />
            ) : value === 'media' ? (
                <EventPostsMediaView event={event} />
            ) : value === 'attendees' ? (
                <></>
            ) : (
                value === 'hosts' && <EventPostsHostView event={event} />
            )}
        </Box>
    )
}

interface EventPostsTabViewProps {
    event: Event
}
const EventPostsAllView = ({ event }: EventPostsTabViewProps) => {
    const apiManager = useApiManager()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        apiManager
            .getPosts(null, null, event.slug, 20, 0)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box>
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        pt: '8px',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                postLoaded === DataStatus.success && (
                    <>
                        {postState.length > 0 ? (
                            <Box>
                                {postState.map((post) => (
                                    <Box
                                        sx={{
                                            mb: '8px',
                                        }}
                                    >
                                        <PostView post={post} />
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                )
            )}
        </Box>
    )
}

const EventPostsHostView = ({ event }: EventPostsTabViewProps) => {
    const apiManager = useApiManager()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        apiManager
            .getPosts(event.primary_host.username, null, event.slug, 20, 0)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box>
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        pt: '8px',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                postLoaded === DataStatus.success && (
                    <>
                        {postState.length > 0 ? (
                            <Box>
                                {postState.map((post) => (
                                    <Box
                                        sx={{
                                            mb: '8px',
                                        }}
                                    >
                                        <PostView post={post} />
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                )
            )}
        </Box>
    )
}

const EventPostsMediaView = ({ event }: EventPostsTabViewProps) => {
    const apiManager = useApiManager()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        apiManager
            .getPosts(null, null, event.slug, 20, 0)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : postLoaded === DataStatus.success && postState.length === 0 ? (
                <EmptyMediaPostsView
                    name={event.slug}
                    type={EmptyPostsViewType.event}
                />
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        padding: '8px 16x',
                    }}
                >
                    <Grid container spacing={0}>
                        {postState.map((post) => (
                            <Grid item xs={4} md={4} lg={4}>
                                <PostImageView post={post} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default EventPostsView
