import {
    Button,
    Typography,
    IconButton,
    Divider,
    Box,
    TextField,
    Popper,
    CircularProgress,
    BoxProps,
    Select,
    MenuItem,
    StepConnector,
    StepIcon,
    StepIconProps,
    LinearProgress,
    InputBase,
    ButtonProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { hexToRGBA } from '../../utils/haxToRGBA'
import { BorderColor } from '@mui/icons-material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

export const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

export const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

export const SelectTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.selected,
}))

export const TertiaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.tertiary,
}))

export const PrimaryDivider = styled(Divider)(({ theme }) => ({
    color: theme.palette.border.primary,
}))

export const SecondaryDivider = styled(Divider)(({ theme }) => ({
    color: theme.palette.border.secondary,
}))

export const EmptyImageBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
    margin: '10px 0',
    '& label.Mui-focused': {
        color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
    },
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.border.primary,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.selected,
        },
    },
}))

export const ClearIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.quaternary,
    '&:hover': {
        backgroundColor: theme.palette.background.quaternary,
    },
}))

export const StyledPopper = styled(Popper)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 10,
    boxShadow: '0px 0px 10px 0px ' + 'black',
}))

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.background.selected,
}))

export const StyledButtonCircularProgress = styled(CircularProgress)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

export const ScrollBarDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    boxSizing: 'content-box',
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.disabled,
        borderRadius: '10px',
        opacity: 0.5,
    },
    '&::-webkit-scrollbar-thumb:hover': {
        opacity: 0.5,
        backgroundColor: theme.palette.text.disabled,
    },
}))

export const FollowButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    padding: '3px 20px',
    fontSize: 14,
    backgroundColor: 'white',
    color: theme.palette.background.secondary,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: 'white',
    },
}))

export const SelectButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    padding: '3px 20px',
    fontSize: 14,
    backgroundColor: theme.palette.background.selected,
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
    '&:disabled': {
        backgroundColor: theme.palette.background.selected,
        color: 'white',
        opacity: 0.5,
    },
}))

export const DefaultButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    padding: '3px 20px',
    fontSize: 14,
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))
export const SelectedHoverButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    padding: '3px 20px',
    fontSize: 14,
    backgroundColor: hexToRGBA(theme.palette.text.selected, 0.1),
    color: theme.palette.text.selected,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: hexToRGBA(theme.palette.text.selected, 0.2),
    },
}))

export const UnfollowButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    padding: '3px 20px',
    fontSize: 14,
    backgroundColor: theme.palette.background.selected,
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

interface UserPhotoCircleProps extends BoxProps {
    includeBorder: boolean
}

export const UserPhotoCircle = styled((props: UserPhotoCircleProps) => (
    <Box {...props} />
))(({ theme, includeBorder }) => ({
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '50%',
    ...(includeBorder && {
        border: '3px solid ' + theme.palette.background.secondary,
    }),
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

export const BackgroundIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.7),
    '&:hover': {
        backgroundColor: hexToRGBA(theme.palette.background.primary, 0.7),
    },
}))

export const StyledChip = styled(Box)(({ theme }) => ({
    color: theme.palette.text.secondary,
    borderRadius: 50,
    padding: '5px 10px',
    display: 'flex',
    // backgroundColor: 'transparent',
    backgroundColor: theme.palette.background.tertiary,
    alignItems: 'center',

    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

export const StyledChipHover = styled(Box)(({ theme }) => ({
    color: theme.palette.text.secondary,
    borderRadius: 50,
    padding: '5px 10px',
    display: 'flex',
    backgroundColor: 'transparent',
    alignItems: 'center',

    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

export const BackgroundFullPopperView = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    position: 'fixed',
    pointerEvents: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    // backdropFilter: 'blur(10px)',
    background: hexToRGBA('black', 0.7),
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme.palette.text.secondary,
        BorderColor: theme.palette.text.selected,
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
    },
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.border.primary,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.selected,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.border.primary,
        '&.Mui-focused': {
            borderColor: theme.palette.text.selected,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.selected,
        },
    },
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.Mui-selected': {
        color: theme.palette.text.selected,
    },
    '&.Mui-selected:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

export const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    '&.Mui-active .MuiStepConnector-line': {
        borderColor: theme.palette.background.selected,
    },
    '&.Mui-completed .MuiStepConnector-line': {
        borderColor: theme.palette.background.selected,
    },
}))

interface StyledStepIconBoxProps extends BoxProps {
    active: boolean | undefined
    completed: boolean | undefined
}

export const LoadingBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const StyledStepIconBox = styled((props: StyledStepIconBoxProps) => (
    <Box {...props} />
))(({ theme, active, completed }) => ({
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.tertiary,
    borderRadius: '50%',
    width: 24,
    height: 24,
    fontSize: 14,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    ...(active && {
        backgroundColor: theme.palette.background.selected,
        color: theme.palette.text.primary,
    }),
    ...(completed && {
        backgroundColor: theme.palette.background.selected,
        color: theme.palette.text.primary,
    }),
}))
export const StyledStepIcon = (props: StepIconProps) => {
    const { active, completed } = props

    return (
        <Box
            sx={{
                width: 26,
                height: 26,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <StyledStepIconBox
                // style={{
                //   color: active ? 'text.selected' : completed ? 'text.selected' : 'background.tertiary',

                // }}
                active={active}
                completed={completed}
            >
                {completed ? (
                    <CheckRoundedIcon
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    />
                ) : (
                    props.icon
                )}
                {/* You can use custom icons here */}
            </StyledStepIconBox>
        </Box>
    )
}
// export const StyledStepIcon = styled(StepIcon)(({ theme }) => ({
//     '&.MuiStepIcon-active': {
//         color: theme.palette.background.selected,
//     },
//     '&.MuiStepIcon-completed': {
//         color: theme.palette.background.selected,
//     },
//     '&.MuiStepIcon-disabled': {
//         color: theme.palette.border.primary,
//     },
// }))

export const HostBox = styled(Box)(({ theme }) => ({
    backgroundColor: hexToRGBA(theme.palette.background.host, 1),
    // background: `linear-gradient(to right, ${theme.palette.background.host} 0%, ${theme.palette.text.host} 90%)`,
    border: '2px solid ' + theme.palette.background.host,
    borderRadius: '8px',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '0px 8px',
    paddingLeft: '4px',
}))

export const AttendingBox = styled(Box)(({ theme }) => ({
    backgroundColor: hexToRGBA(theme.palette.background.attending, 1),
    // background: `linear-gradient(to right, ${theme.palette.background.attending} 0%, ${theme.palette.text.attending} 90%)`,
    border: '2px solid ' + theme.palette.background.attending,
    borderRadius: '8px',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '0px 8px',
    paddingLeft: '4px',
}))

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

interface OutlineContainerProps extends BoxProps {
    focused: boolean
}

export const OutlineContainer = styled((props: OutlineContainerProps) => (
    <Box {...props} />
))(({ theme, focused }) => ({
    // backgroundColor: theme.palette.background.secondary,
    borderBottom: '1px solid ' + theme.palette.border.secondary,
    display: 'flex',
    padding: '0px 0px',
    width: '100%',
    '&:hover': {
        borderColor: focused
            ? theme.palette.background.selected
            : theme.palette.text.secondary,
    },
    ...(focused && {
        borderColor: theme.palette.background.selected,
    }),
}))

export const OutlineInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingLeft: 4,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}))

export const IconMobileButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

export const StyledSnackbar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '12px 0px',
    width: '100%',
}))

interface TabBoxProps extends ButtonProps {
    selected: boolean
}
export const TabBoxSelect = styled((props: TabBoxProps) => (
    <Button {...props} />
))(({ theme, selected }) => ({
    backgroundColor: selected
        ? theme.palette.text.primary
        : theme.palette.background.tertiary,
    color: selected
        ? theme.palette.background.primary
        : theme.palette.text.secondary,
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '14px',
    borderRadius: '10px',
    height: '35px',
    padding: '0px 16px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: selected
            ? theme.palette.text.primary
            : theme.palette.background.tertiary,
    },
}))
