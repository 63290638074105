import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { Box, Slider, Button, Typography } from '@mui/material'
import { FilterData } from '../../../models/Filter'
import { PrimaryDivider } from '../../Components/StyledComponents'

const StyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-track': {
        color: theme.palette.background.selected,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.background.tertiary,
    },
    '& .MuiSlider-thumb': {
        color: theme.palette.background.selected,
    },
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

interface RadiusPickerViewProps {
    filterstate: FilterData
    setFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    maxDistance: number
    width: string
}

const RadiusPickerView = React.memo(
    ({
        filterstate,
        setFilterState,
        maxDistance,
        width,
    }: RadiusPickerViewProps) => {
        const [value, setValue] = useState<number | number[]>(filterstate.range)
        const handleChange = (event: Event, newValue: number | number[]) => {
            if (typeof newValue === 'number') {
                setValue(newValue)
                setFilterState({ ...filterstate, range: newValue })
            }
        }
        return (
            <Box sx={{ width: width }}>
                <Typography
                    sx={{
                        fontSize: 14,
                        color: 'text.tertiary',
                        fontWeight: 'bold',
                    }}
                >
                    DISTANCE
                </Typography>
                <PrimaryDivider
                    sx={{
                        m: '8px 0px',
                    }}
                />
                <SecondaryTypography sx={{ fontSize: 14, mt: '16px' }}>
                    {value == maxDistance
                        ? 'Any distance'
                        : `${value} miles away`}
                </SecondaryTypography>
                <Box padding={'0px 0px'}>
                    <StyledSlider
                        min={10}
                        max={maxDistance}
                        value={value}
                        step={10}
                        onChange={handleChange}
                        valueLabelDisplay="off"
                        sx={{ paddingBottom: '20px' }}
                    />
                </Box>
            </Box>
        )
    }
)

export default RadiusPickerView
