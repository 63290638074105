import { DataStatus } from '../../models/Dataloading'
import { Box, Card, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { User } from '../../models/User'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { useEffect, useState, useRef } from 'react'
import { grabUserPhoto } from '../../api/RestUser'
import { useAppState } from '../../AppStateProvider'
import {
    FollowButton,
    PrimaryTypography,
    SecondaryTypography,
    UnfollowButton,
} from '../Components/StyledComponents'
import AccountAvatarSubView from './AccountAvatarSubView'
import AccountPopoverView from './AccountPopoverView'
import { navigateToAccount } from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { useApiManager } from '../../api/ApiMangerProvider'
import { useQuery, useQueryClient } from 'react-query'

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const FollowIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.text.primary,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.primary,
    },
}))

const FollowingIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.text.selected,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.selected,
    },
}))

const AccountContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    // backgroundColor: theme.palette.background.secondary,
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    cursor: 'pointer',
}))

interface AccountAttendeeSubViewProps {
    user: User
}
const AccountAttendeeSubView = ({ user }: AccountAttendeeSubViewProps) => {
    const queryClient = useQueryClient()
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [userImage, setUserImage] = useState<string | null>(null)
    const [userState, setUserState] = useState<User>(user)
    const [userImageStatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )

    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    useQuery(user.image, () => apiManager.grabUserPhoto(user.image), {
        enabled: !!!userImage,
        onSuccess: (data) => {
            setUserImage(data)
            setUserImageStatus(DataStatus.success)
        },
        onError: () => {
            setUserImageStatus(DataStatus.failure)
        },
    })

    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }
    const handleFollow = (event: React.MouseEvent) => {
        event.stopPropagation()
        setUserState({
            ...userState,
            follower_size: userState.follower_size + 1,
            following: true,
        })
        try {
            apiManager
                .followUser(userState.username)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleUnfollow = (event: React.MouseEvent) => {
        event.stopPropagation()
        setUserState({
            ...userState,
            follower_size: userState.follower_size - 1,
            following: false,
        })
        try {
            apiManager
                .unfollowUser(userState.username)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <AccountContainer
            sx={{ p: '16px' }}
            onMouseOver={(event) => handleAccountPopperOpen(event)}
            onMouseLeave={handleAccountPopperClose}
            onClick={() =>
                navigateToAccount(
                    apiManager,
                    queryClient,
                    userState.username,
                    userState,
                    userImage,
                    null,
                    navigate,
                    state,
                    setState,
                    'attending'
                )
            }
        >
            <Box
                sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '5px',
                    display: 'flex',
                }}
            >
                <Box>
                    {/* <AccountAvatarSubView
                        user={user}
                        userimage={userImage}
                        isUserImageLoading={isUserImageLoading}
                        handleAccountPopperClose={handleAccountPopperClose}
                        handleAccountPopperOpen={handleAccountPopperOpen}
                        following={userState.following}
                        height={50}
                        width={50}
                    /> */}
                    <UserPhotoCircle
                        sx={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        {userImageStatus === DataStatus.success &&
                            userImage && (
                                <img
                                    src={userImage}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                    </UserPhotoCircle>
                </Box>
            </Box>
            <Typography
                noWrap
                sx={{
                    fontSize: 14,
                    textAlign: 'center',
                    mt: '8px',
                    color: 'text.primary',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                }}
            >
                {userState.name}
            </Typography>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                    textAlign: 'center',
                }}
            >
                @{userState.username}
            </Typography>
            {userState.username !== state.username && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <Box flex={1} />
                    {userState.following ? (
                        <UnfollowButton
                            fullWidth
                            sx={{
                                fontSize: 12,
                                mt: '8px',
                                alignContent: 'center',
                            }}
                            onClick={handleUnfollow}
                        >
                            Following
                        </UnfollowButton>
                    ) : (
                        <FollowButton
                            fullWidth
                            sx={{
                                fontSize: 12,
                                mt: '8px',
                            }}
                            onClick={handleFollow}
                        >
                            Follow
                        </FollowButton>
                    )}
                    <Box flex={1} />
                </Box>
            )}
            {openAccountPopover && (
                <AccountPopoverView
                    user={userState}
                    setUser={setUserState}
                    openpopover={openAccountPopover}
                    setOpenPopover={setOpenAccountPopover}
                    anchorEl={accountAnchorEl}
                    setAnchorEl={setAccountAnchorEl}
                    timerRef={timerRef}
                />
            )}
        </AccountContainer>
    )
}

export default AccountAttendeeSubView
