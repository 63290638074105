import { createContext, useContext, useState } from 'react'

interface DesktopSizeProviderProps {
    children: React.ReactNode
}

interface DesktopSizeContextType {
    desktopBreakpoint: number
    setDesktopBreakpoint: React.Dispatch<React.SetStateAction<number>>
}

const DesktopSizeContext = createContext<DesktopSizeContextType | undefined>(
    undefined
)

export const DesktopSizeProvider: React.FC<DesktopSizeProviderProps> = ({
    children,
}) => {
    const [desktopBreakpoint, setDesktopBreakpoint] = useState(-1)
    return (
        <DesktopSizeContext.Provider
            value={{
                desktopBreakpoint: desktopBreakpoint,
                setDesktopBreakpoint: setDesktopBreakpoint,
            }}
        >
            {children}
        </DesktopSizeContext.Provider>
    )
}

export const useDesktopSize = () => {
    const context = useContext(DesktopSizeContext)
    if (!context) {
        throw new Error('useDesktop must be used within an DesktopSizeProvider')
    }
    return context
}
