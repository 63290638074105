import {
    AppBar,
    AppBarProps,
    Box,
    BoxProps,
    IconButton,
    IconButtonProps,
    LinearProgress,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import {
    StyledIconButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useAppState } from '../../../AppStateProvider'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { Link as RouterLink } from 'react-router-dom'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'

interface TopBarProps extends AppBarProps {
    expandMap: boolean
}
const TopBar = styled((props: TopBarProps) => <AppBar {...props} />)(
    ({ theme, expandMap }) => ({
        paddingLeft: 0,
        borderBottom: '1px solid ' + theme.palette.background.primary,
        background: expandMap
            ? 'transparent'
            : theme.palette.background.primary,
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 'none',
        height: 45,
        // boxShadow: 'none',
        // marginRight: 6,
    })
)

interface LogoIconButtonProps extends IconButtonProps {
    component?: React.ElementType
    href?: string
}

const LogoIconButton = styled((props: LogoIconButtonProps) => (
    <IconButton {...props} />
))(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

interface BarBoxProps extends BoxProps {
    expandMap: boolean
}
const BarBox = styled((props: BarBoxProps) => <Box {...props} />)(
    ({ theme, expandMap }) => ({
        ...(expandMap
            ? { background: theme.palette.background.tertiary }
            : { background: theme.palette.background.primary }),
    })
)

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

interface DiscoverTopBarMobileViewProps {
    setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>
    expandMap: boolean
    setShowSearch: React.Dispatch<React.SetStateAction<boolean>>
}

const DiscoverTopBarMobileView = ({
    setShowDrawer,
    expandMap,
    setShowSearch,
}: DiscoverTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    return (
        <TopBar expandMap={expandMap}>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 8, paddingRight: 16 }}
            >
                {/* <LogoIconButton
                    component={RouterLink}
                    href="/app/home/"
                    disableRipple
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                        height={30}
                    />
                </LogoIconButton> */}
                <BarBox
                    expandMap={expandMap}
                    sx={{
                        pl: 0,
                        pr: '16px',
                        borderRadius: '50px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <StyledIconButton onClick={() => setShowDrawer(true)}>
                        <MenuRoundedIcon />
                    </StyledIconButton>
                    <Typography
                        sx={{
                            fontSize: 24,
                            fontWeight: 700,
                            ml: '4px',
                            color: 'text.primary',
                        }}
                    >
                        Discover
                    </Typography>
                </BarBox>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <StyledIconButton
                        sx={{
                            backgroundColor: expandMap
                                ? 'background.tertiary'
                                : 'background.primary',
                        }}
                        onClick={() => setShowSearch(true)}
                    >
                        <SearchRoundedIcon />
                    </StyledIconButton>
                </Box>
            </Toolbar>
        </TopBar>
    )
}

export default DiscoverTopBarMobileView
