import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
    const location = useLocation()
    const prevPath = useRef(location.pathname)
    useEffect(() => {
        if (location.pathname !== prevPath.current) {
            window.scrollTo(0, 0)
            prevPath.current = location.pathname
        }
    }, [location])
    return null
}

export default ScrollToTop
