import { Box } from '@mui/material'
import { User } from '../../../models/User'
import AccountSideRecommendedAccountsView from '../../SideViews/AccountSideRecommendedAccountsView'
import AccountSideSharedEventsView from '../../SideViews/AccountSideSharedEventsView'
import AccountSideSimilarEventsView from '../../SideViews/AccountSideSharedEventsView'
import SideLinksView from '../../SideViews/SideLinksView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'

const eventphotoheight = 200

interface AccountSideViewProps {
    user: User
}

const AccountSideView = ({ user }: AccountSideViewProps) => {
    return (
        <Box
            id="account-side-view-container"
            sx={{
                width: '380px',
                paddingLeft: '20px',
                position: 'sticky',
                mt: `${eventphotoheight + 16}px`,
                top: `${navMobileBarHeight + 8}px`,
                height: '100%',
            }}
        >
            <AccountSideRecommendedAccountsView user={user} />
            <Box
                sx={{
                    height: '8px',
                }}
            />
            <AccountSideSimilarEventsView user={user} />
            <SideLinksView />
        </Box>
    )
}

export default AccountSideView
