import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { User } from '../../../models/User'
import { DataStatus } from '../../../models/Dataloading'
import { Box, Typography } from '@mui/material'
import { followUser, grabUserPhoto, unfollowUser } from '../../../api/RestUser'
import { getEvents, grabEventPhoto } from '../../../api/RestEvent'
import { Data } from '@react-google-maps/api'
import {
    FollowButton,
    LoadingBox,
    PrimaryDivider,
    UnfollowButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import { navigateToAccount } from '../../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/system'
import zIndex from '@mui/material/styles/zIndex'
import AccountFullSubView from '../../AccountView/AccountFullSubView'

const userphotoheight = 60
const eventphotoheight = 80

interface EventDetailsHostMobileViewProps {
    host: User
}

const HostContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    backgroundColor: theme.palette.background.secondary,
    // border: '1px solid ' + theme.palette.border.primary,
}))

const EventDetailsHostMobileView = ({
    host,
}: EventDetailsHostMobileViewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                HOST
            </Typography>
            <PrimaryDivider
                sx={{
                    mt: '8px',
                    mb: '8px',
                }}
            />
            <HostContainer
                sx={{
                    mt: '16px',
                    position: 'relative',
                }}
            >
                <AccountFullSubView user={host} />
            </HostContainer>
        </Box>
    )
}

export default EventDetailsHostMobileView
