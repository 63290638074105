import { AppBar, Box, styled, Toolbar, Typography } from '@mui/material'
import { hexToRGBA } from '../../../../utils/haxToRGBA'
import { Event } from '../../../../models/Event'
import {
    BackgroundIconButton,
    StyledLinearProgress,
} from '../../../Components/StyledComponents'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { useApiManager } from '../../../../api/ApiMangerProvider'
import { useAppState } from '../../../../AppStateProvider'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { DataStatus } from '../../../../models/Dataloading'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    // borderBottom: '1px solid ' + theme.palette.border.secondary,
    background: hexToRGBA('#111111', 0.6),
    position: 'fixed',
    width: `calc(100%)`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    // boxShadow: 'none',
    // marginRight: 6,
}))

interface TopBarMobileViewProps {
    event: Event
    setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const EventToolsTopBarMobileView = ({
    event,
    setShowDrawer,
}: TopBarMobileViewProps) => {
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const [eventImageStatus, setEventImageStatus] = useState(DataStatus.loading)
    const navigate = useNavigate()
    const {
        data: eventImage,
        isLoading: isEventImageLoading,
        error: eventImageError,
    } = useQuery(event.image, () => apiManager.grabEventPhoto(event.image))

    return (
        <TopBar
            sx={{
                overflow: 'hidden',
                borderBottom: (theme) =>
                    eventImage
                        ? 'transparent'
                        : '1px solid ' + theme.palette.border.secondary,
            }}
        >
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            {eventImage && (
                <img
                    src={eventImage}
                    alt="Event"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        filter: 'blur(10px)',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        scale: 1.1,
                    }}
                />
            )}
            <Toolbar
                sx={{
                    minHeight: '55px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                <BackgroundIconButton
                    sx={{
                        height: '36px',
                        width: '36px',
                    }}
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <ArrowBackIosNewRoundedIcon
                        sx={{
                            fontSize: '16px',
                        }}
                    />
                </BackgroundIconButton>
                <Box ml="12px">
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: 'text.primary',
                            fontWeight: 'bold',
                        }}
                    >
                        {event.title}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            bt: 0,
                            color: 'text.primary',
                        }}
                    >
                        Tools
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <BackgroundIconButton
                    sx={{
                        height: '36px',
                        width: '36px',
                        mr: '8px',
                    }}
                >
                    <EditRoundedIcon
                        sx={{
                            fontSize: '16px',
                        }}
                    />
                </BackgroundIconButton>
                <BackgroundIconButton
                    sx={{
                        height: '36px',
                        width: '36px',
                    }}
                    onClick={() => setShowDrawer(true)}
                >
                    <MenuRoundedIcon
                        sx={{
                            fontSize: '16px',
                        }}
                    />
                </BackgroundIconButton>
            </Toolbar>
        </TopBar>
    )
}

export default EventToolsTopBarMobileView
