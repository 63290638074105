import React, { useEffect, useState } from 'react'
import { Event } from '../../models/Event'
import { DataStatus } from '../../models/Dataloading'
import { styled } from '@mui/material/styles'
import { Box, CardContent, CardMedia, Typography } from '@mui/material'
import { grabEventPhoto } from '../../api/RestEvent'
import { useAppState } from '../../AppStateProvider'
import { Link, useNavigate } from 'react-router-dom'
import { iso2eventtime } from '../../utils/DateStringConverter'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { grabUserPhoto } from '../../api/RestUser'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'

interface EventSubViewProps {
    event: Event
}

const LoadingBox = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const EventTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: 16,
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: '10px 10px',
    '&:last-child': {
        paddingBottom: 10,
    },
}))

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const EventCard = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
    backgroundColor: theme.palette.background.primary,
    padding: 0,
    borderRadius: 10,
    width: 350,
    cursor: 'pointer',
}))

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

const StyledCalendarMonthRoundedIcon = styled(CalendarMonthRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.selected,
    })
)

const StyledShoppingCartRoundedIcon = styled(ShoppingCartRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.selected,
    })
)

const StyledLocationOnRoundedIcon = styled(LocationOnRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.selected,
    })
)

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledRepeatRoundedIcon = styled(RepeatRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledPersonOutlineRoundedIcon = styled(PersonOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const DetailsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 50,
}))

const MapEventSubView = ({ event }: EventSubViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimage, setEventImage] = React.useState<string | null>(null)
    const [userimage, setUserImage] = React.useState<string | null>(null)
    const navigateToEvent = () => {
        navigate(`/app/event/${event.slug}`, {
            state: { event: event, eventImage: eventimage },
        })
    }

    useEffect(() => {
        try {
            grabEventPhoto(event.image, state.usertoken)
                .then((response) => {
                    setEventImageStatus(DataStatus.failure)
                    setEventImage(response)
                    setEventImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setEventImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.error(error)
            setEventImageStatus(DataStatus.failure)
        }
    }, [])
    useEffect(() => {
        try {
            grabUserPhoto(event.primary_host.image, state.usertoken)
                .then((response) => {
                    setUserImage(response)
                    setUserImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setUserImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.error(error)
            setUserImageStatus(DataStatus.failure)
        }
    }, [event])
    return (
        <EventCard onClick={navigateToEvent} id="event-marker">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px 10px 0px 0px',
                    overflow: 'hidden',
                    aspectRatio: '20/9',
                    position: 'relative',
                }}
            >
                {eventimagestatus === DataStatus.success && eventimage ? (
                    <img
                        src={eventimage}
                        alt="Event"
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: 0,
                        }}
                    />
                ) : eventimagestatus === DataStatus.loading ? (
                    <LoadingBox />
                ) : (
                    <LoadingBox />
                )}
                <DetailsBox>
                    <StyledFavoriteBorderRoundedIcon
                        style={{ marginRight: 5, fontSize: 16 }}
                    />
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        fontSize={12}
                    >
                        {event.favorites_count}
                    </Typography>
                    <Box width={20} />
                    <StyledRepeatRoundedIcon
                        style={{ marginRight: 5, fontSize: 16 }}
                    />
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        fontSize={12}
                    >
                        {event.post_count}
                    </Typography>
                    <Box width={20} />
                    <StyledPersonOutlineRoundedIcon
                        style={{ marginRight: 5, fontSize: 16 }}
                    />
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        fontSize={12}
                    >
                        {event.attendee_count}
                    </Typography>
                </DetailsBox>
            </div>
            <StyledCardContent>
                <Box>
                    <EventTitle>{event.title}</EventTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ paddingBottom: '5px' }}
                    >
                        <Box display="flex" alignItems="center">
                            {userimagestatus === DataStatus.success &&
                            userimage ? (
                                <UserPhotoCircle
                                    sx={{
                                        width: 35,
                                        height: 35,
                                        borderRadius: '50%',
                                        marginRight: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <img
                                        src={userimage}
                                        alt="User"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </UserPhotoCircle>
                            ) : (
                                <UserPhotoCircle
                                    sx={{
                                        width: 35,
                                        height: 35,
                                        borderRadius: '50%',
                                        marginRight: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <StyledPersonRoundedIcon
                                        sx={{
                                            width: '75%',
                                            height: '75%',
                                        }}
                                    />
                                </UserPhotoCircle>
                            )}

                            {/* Text Stack */}
                            <Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        color="textPrimary"
                                        fontSize={14}
                                    >
                                        {event.primary_host.name}
                                    </Typography>
                                    <Box width={5} />
                                    <Typography
                                        color="textSecondary"
                                        fontSize={14}
                                    >
                                        @{event.primary_host.username}
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    fontSize={14}
                                >
                                    {event.primary_host.follower_size} followers
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <StyledCalendarMonthRoundedIcon
                            style={{ marginRight: 5, fontSize: 18 }}
                        />
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            fontSize={14}
                        >
                            {iso2eventtime(event.event_time, 'short', 'short')}
                        </Typography>
                        <Box width={10} />
                        <StyledLocationOnRoundedIcon
                            style={{ marginRight: 5, fontSize: 18 }}
                        />
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            fontSize={14}
                        >
                            20
                        </Typography>
                        <Box width={10} />
                        <StyledShoppingCartRoundedIcon
                            style={{ marginRight: 5, fontSize: 18 }}
                        />
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            fontSize={14}
                        >
                            Free
                        </Typography>
                    </Box>
                </Box>
            </StyledCardContent>
        </EventCard>
    )
}

export default MapEventSubView
