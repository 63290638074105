import {
    Box,
    Drawer,
    IconButton,
    Slide,
    Snackbar,
    styled,
    Typography,
} from '@mui/material'
import { Post, PostOptions } from '../../../models/Post'
import {
    PrimaryDivider,
    StyledSnackbar,
} from '../../Components/StyledComponents'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ReactDOM from 'react-dom'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { useAppState } from '../../../AppStateProvider'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import { deletePost } from '../../../api/RestPost'
import { useNavigate } from 'react-router-dom'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { useState } from 'react'
import { Event } from '../../../models/Event'

const ItemContainer = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '10px',
}))

const OptionsDrawer = styled(Drawer)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 100000,
    '& .MuiDrawer-paper': {
        width: '100%',
        height: '300px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        padding: '0px',
        background: theme.palette.background.default,
        zIndex: theme.zIndex.drawer + 100000,
    },
}))

interface CreatePostOptionsMobileViewProps {
    event: Event
    setPostOptions: React.Dispatch<React.SetStateAction<PostOptions>>
    showOptions: boolean
    setShowOptions: React.Dispatch<React.SetStateAction<boolean>>
}

const CreatePostOptionsMobileView = ({
    event,
    setPostOptions,
    showOptions,
    setShowOptions,
}: CreatePostOptionsMobileViewProps) => {
    const handleClose = () => {
        setShowOptions(false)
    }
    const parentElement = document.getElementById('root')
    const handlePublic = () => {
        setPostOptions(PostOptions.PUBLIC)
        setShowOptions(false)
    }
    const handleAttendees = () => {
        setPostOptions(PostOptions.ATTENDEES)
        setShowOptions(false)
    }
    const handleHost = () => {
        setPostOptions(PostOptions.HOST)
        setShowOptions(false)
    }
    const handleFollowers = () => {
        setPostOptions(PostOptions.FOLLOWERS)
        setShowOptions(false)
    }

    return ReactDOM.createPortal(
        <>
            <OptionsDrawer
                anchor="bottom"
                open={showOptions}
                onClose={handleClose}
                onClick={function (event) {
                    event.stopPropagation()
                }}
            >
                <Box
                    sx={{
                        minHeight: '300px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '55px',
                            p: '0px 16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Select Post Audience
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={handleClose}>
                            <ClearRoundedIcon
                                sx={{
                                    color: 'text.primary',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                }}
                            />
                        </IconButton>
                    </Box>
                    <PrimaryDivider />
                    <Box
                        sx={{
                            m: '16px 16px',
                        }}
                    >
                        <ItemContainer>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: '12px 16px',
                                }}
                                onClick={handlePublic}
                            >
                                <FavoriteRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: 'text.primary',
                                        mr: '8px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Public
                                </Typography>
                            </Box>
                            <PrimaryDivider />
                            {(event.attending || event.is_host) && (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            p: '12px 16px',
                                        }}
                                        onClick={handleAttendees}
                                    >
                                        <FavoriteRoundedIcon
                                            sx={{
                                                fontSize: '20px',
                                                color: 'text.primary',
                                                mr: '8px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: 'text.primary',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Attendees
                                        </Typography>
                                    </Box>
                                    <PrimaryDivider />
                                </>
                            )}
                            {event.is_host && (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            p: '12px 16px',
                                        }}
                                        onClick={handleHost}
                                    >
                                        <FavoriteRoundedIcon
                                            sx={{
                                                fontSize: '20px',
                                                color: 'text.primary',
                                                mr: '8px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: 'text.primary',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Hosts
                                        </Typography>
                                    </Box>

                                    <PrimaryDivider />
                                </>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: '12px 16px',
                                }}
                                onClick={handleFollowers}
                            >
                                <FavoriteRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: 'text.primary',
                                        mr: '8px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Followers
                                </Typography>
                            </Box>
                        </ItemContainer>
                    </Box>
                </Box>
            </OptionsDrawer>
        </>,
        parentElement as Element
    )
}

export default CreatePostOptionsMobileView
