import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { getPosts } from '../../../api/RestPost'
import { Box, InputBase, styled } from '@mui/material'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { error } from 'console'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import EventPostsTopBarMobileView from './EventPostsTopBarMobileView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { Event } from '../../../models/Event'
import AccountDetailsSubView from '../../AccountView/AccountSubViews/AccountDetailsSubView'
import EventAttendeesTopBarMobileView from './EventAttendeesTopBarMobileView copy'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigateEventState } from '../../../models/NavigateState'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingLeft: 4,
    },
}))

const SearchContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: 20,
    backgroundColor: theme.palette.background.tertiary,
    width: 'calc(100% - 16px)',
    padding: '0px 8px',
    alignItems: 'center',
    display: 'flex',
    minHeight: 35,
    marginRight: 16,
}))

interface EventAttendeesMobileViewProps {
    event: Event | null | undefined
}

const EventAttendeesMobileView = ({ event }: EventAttendeesMobileViewProps) => {
    const { state } = useAppState()
    const location = useLocation()
    const initialEventStateData = location.state as NavigateEventState
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const [value, setValue] = useState(searchParams.get('tab') || 'attendees')
    const handleChange = (e: React.SyntheticEvent, newValue: string) => {
        if (event) {
            navigate(`/app/event/${event.slug}/attendees/?tab=${newValue}`, {
                state: {
                    event: event,
                    eventImage: null,
                },
            })
            setValue(newValue)
        }
    }
    useEffect(() => {
        setValue(searchParams.get('tab') || 'attendees')
    }, [searchParams.get('tab')])
    return (
        <Box
            sx={{
                mt: '112px',
                position: 'relative',
                mb: `${navMobileBarHeight}px`,
            }}
        >
            {event && (
                <>
                    <TabBox
                        sx={{
                            position: 'fixed',
                            top: '56px',
                            width: '100%',
                            zIndex: 2,
                            backgroundColor: 'background.primary',
                        }}
                    >
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="fullWidth"
                        >
                            <StyledTab label="Attendees" value="attendees" />
                            <StyledTab label="Followers" value="followers" />
                            <StyledTab label="Hosts" value="hosts" />
                        </StyledTabs>
                    </TabBox>
                    <EventAttendeesTopBarMobileView eventState={event} />
                    {value === 'attendees' && (
                        <EventAttendeesAllMobileView event={event} />
                    )}
                </>
            )}
        </Box>
    )
}

export default EventAttendeesMobileView

interface EventAttendeesAllMobileViewProps {
    event: Event
}

const EventAttendeesAllMobileView = ({
    event,
}: EventAttendeesAllMobileViewProps) => {
    const [searchInput, setSearchInput] = useState('')
    return (
        <Box
            sx={{
                p: '0px 16px',
                pt: '8px',
            }}
        >
            <SearchContainer
                sx={{
                    mb: '16px',
                }}
            >
                <SearchRoundedIcon
                    sx={{
                        color: 'text.disabled',
                        pl: '4px',
                        fontSize: '20px',
                    }}
                />
                <StyledInputBase
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                {searchInput.length > 0 && (
                    <CancelRoundedIcon
                        sx={{
                            color: 'text.disabled',
                            position: 'absolute',
                            right: '8px',
                            cursor: 'pointer',
                            fontSize: '20px',
                        }}
                        onClick={() => setSearchInput('')}
                    />
                )}
            </SearchContainer>
            {event.attendees.map((attendee, index) => (
                <Box
                    sx={{
                        pb:
                            index === event.attendees.length - 1
                                ? '16px'
                                : '24px',
                    }}
                >
                    <AccountDetailsSubView
                        user={attendee}
                        followButton={true}
                        bio={true}
                        size={40}
                    />
                </Box>
            ))}
        </Box>
    )
}
