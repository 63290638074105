import { Event } from './Event'
import { User } from './User'

export interface Post {
    uuid: string
    id: string
    tag_list: [string]
    created_at: string
    updated_at: string
    favorited: boolean
    favorites_count: number
    comment_count: number
    event: Event
    author: User
    attending: boolean
    is_host: boolean
    image: string
    caption: string
}

export enum PostOptions {
    PUBLIC = 'Public: Viewable by everyone',
    ATTENDEES = 'Attendees: Only viewable by attendees',
    HOST = 'Hosts: Only viewable by hosts',
    FOLLOWERS = 'Followers: Only viewable by followers',
}
