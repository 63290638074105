import { useState } from 'react'
import { styled } from '@mui/system'
import {
    Box,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Radio,
    Typography,
    Collapse,
} from '@mui/material'
import DateRangeCalendar from '../../Components/DateRangeCalendar'
import { FilterData, FilterDateChoice } from '../../../models/Filter'
import { PrimaryDivider } from '../../Components/StyledComponents'

const StyledRadio = styled(Radio)(({ theme }) => ({
    color: theme.palette.text.secondary,
    '&.Mui-checked': {
        color: theme.palette.background.selected,
    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
}))

const RadioLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: 14,
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

interface DatePickerViewViewProps {
    tempfilterstate: FilterData
    setTempFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    width: string
}

const DatePickerView = ({
    tempfilterstate,
    setTempFilterState,
    width: width,
}: DatePickerViewViewProps) => {
    const [value, setValue] = useState<String | null>(
        tempfilterstate.date.choice.toString()
    )
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        newValue: FilterDateChoice
    ) => {
        setValue((event.target as HTMLInputElement).value)
        setTempFilterState({
            ...tempfilterstate,
            date: { choice: newValue, dates: [] },
        })
    }
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        tempfilterstate.date.dates[0],
        tempfilterstate.date.dates[1],
    ])
    return (
        <Box sx={{ width: width }}>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.tertiary',
                    fontWeight: 'bold',
                }}
            >
                DATE
            </Typography>
            <PrimaryDivider
                sx={{
                    m: '8px 0px',
                }}
            />
            <FormControl>
                <RadioGroup
                    value={value}
                    onChange={(event) =>
                        handleChange(event, FilterDateChoice.Any)
                    }
                >
                    <FormControlLabel
                        value="Any"
                        control={<StyledRadio />}
                        label={<RadioLabel>Any</RadioLabel>}
                    />
                </RadioGroup>
                <RadioGroup
                    value={value}
                    onChange={(event) =>
                        handleChange(event, FilterDateChoice.Today)
                    }
                >
                    <FormControlLabel
                        value="Today"
                        control={<StyledRadio />}
                        label={<RadioLabel>Today</RadioLabel>}
                    />
                </RadioGroup>
                <RadioGroup
                    value={value}
                    onChange={(event) =>
                        handleChange(event, FilterDateChoice.Tomorrow)
                    }
                >
                    <FormControlLabel
                        value="Tomorrow"
                        control={<StyledRadio />}
                        label={<RadioLabel>Tomorrow</RadioLabel>}
                    />
                </RadioGroup>
                <RadioGroup
                    value={value}
                    onChange={(event) =>
                        handleChange(event, FilterDateChoice.ThisWeek)
                    }
                >
                    <FormControlLabel
                        value="This Week"
                        control={<StyledRadio />}
                        label={<RadioLabel>This week</RadioLabel>}
                    />
                </RadioGroup>
                <RadioGroup
                    value={value}
                    onChange={(event) =>
                        handleChange(event, FilterDateChoice.ThisMonth)
                    }
                >
                    <FormControlLabel
                        value="This Month"
                        control={<StyledRadio />}
                        label={<RadioLabel>This month</RadioLabel>}
                    />
                </RadioGroup>
                <RadioGroup
                    value={value}
                    onChange={(event) =>
                        handleChange(event, FilterDateChoice.Custom)
                    }
                >
                    <FormControlLabel
                        value="Custom"
                        control={<StyledRadio />}
                        label={<RadioLabel>Custom</RadioLabel>}
                    />
                </RadioGroup>
            </FormControl>
            <Collapse in={value === 'Custom'}>
                <DateRangeCalendar
                    value={dateRange}
                    tempfilterstate={tempfilterstate}
                    setTempFilterState={setTempFilterState}
                />
            </Collapse>
        </Box>
    )
}

export default DatePickerView
