import { AppBar, Box, Toolbar, Typography, styled } from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import { StyledIconButton } from '../../views/Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useNavigate } from 'react-router-dom'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
    background: theme.palette.background.primary,
    boxShadow: 'none',
}))

interface LoginTopBarMobileViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginTopBarMobileView = ({
    setShowLogin,
}: LoginTopBarMobileViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()

    return (
        <TopBar>
            <Toolbar style={{ minHeight: 55, paddingLeft: 8, paddingRight: 8 }}>
                <Box sx={{ flexGrow: 1 }} />
                <img
                    src={`${process.env.PUBLIC_URL}/logotest03longwhite.png`}
                    height={30}
                />
                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
            <Toolbar
                style={{
                    minHeight: 55,
                    paddingLeft: 16,
                    paddingRight: 8,
                    position: 'absolute',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.secondary',
                    }}
                    onClick={() => navigate('')}
                >
                    Cancel
                </Typography>
            </Toolbar>
        </TopBar>
    )
}

export default LoginTopBarMobileView
