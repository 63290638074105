import { Box, Drawer, Fade, Grow, Icon, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Post } from '../../../models/Post'
import ReactDOM from 'react-dom'
import { styled } from '@mui/material/styles'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { Comment } from '../../../models/Comment'
import ExpandImageSideView from '../ExpandImageSideView/ExpandImageSideView'
import { getComments } from '../../../api/RestComment'
import { useAppState } from '../../../AppStateProvider'
import { BackgroundIconButton } from '../../Components/StyledComponents'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import PostHeaderView from '../PostHeaderView'
import { DataStatus } from '../../../models/Dataloading'
import HighlightTypography from '../../Components/HighlightTypography'
import { hexToRGBA } from '../../../utils/haxToRGBA'

const ImageContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    float: 'left',
    position: 'relative',
    backgroundColor: theme.palette.background.primary,
}))

const ClearIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.background.primary,
    },
}))

interface SideViewIconButtonProps
    extends React.ComponentProps<typeof IconButton> {
    showSideDrawer: boolean
}
const SideViewIconButton = styled((props: SideViewIconButtonProps) => (
    <IconButton {...props} />
))(({ theme, showSideDrawer }) => ({
    position: 'absolute',
    zIndex: 3,
    borderRadius: 50,
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    '&:hover': {
        backgroundColor: theme.palette.background.primary,
    },
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    transform: showSideDrawer ? 'rotate(0deg)' : 'rotate(-180deg)',
}))

interface ExpandImagePostViewProps {
    post: Post
    postImage: string
    userImage: string | null | undefined
    isUserImageLoading: boolean
    comments: Comment[] | null
    expandImage: boolean
    setExpandImage: React.Dispatch<React.SetStateAction<boolean>>
    setShowOptions: React.Dispatch<React.SetStateAction<boolean>>
}

const ExpandImagePostMobileView = ({
    post,
    postImage,
    userImage,
    isUserImageLoading,
    comments,
    expandImage,
    setExpandImage,
    setShowOptions,
}: ExpandImagePostViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post>(post)
    const [showSideDrawer, setShowSideDrawer] = useState<boolean>(true)
    const parentElement = document.getElementById('root')
    const [userState, setUserState] = useState(post.author)
    const [commentsState, setCommentsState] = useState<Comment[] | null>(
        comments
    )

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        if (!comments) {
            try {
                getComments(post.id, state.usertoken)
                    .then((response) => {
                        setCommentsState(response.comments)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }, [])
    const [showDetails, setShowDetails] = useState<boolean>(true)
    return ReactDOM.createPortal(
        <Grow in={expandImage}>
            <Box
                id="expand-image-post-view"
                sx={{
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    left: 0,
                    top: 0,
                    display: 'flex',
                    zIndex: (theme) => theme.zIndex.drawer + 100000,
                }}
            >
                <ImageContainer
                    sx={{
                        zIndex: 100000,
                        position: 'fixed',
                    }}
                    onClick={function (event) {
                        event.stopPropagation()
                        setShowDetails(!showDetails)
                    }}
                >
                    <img
                        src={postImage}
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            opacity: 1,
                            filter: 'blur(20px)',
                            zIndex: 1,
                            objectFit: 'cover',
                            scale: 1.1,
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            left: 0,
                            top: 0,
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={postImage}
                            style={{
                                position: 'relative',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                width: 'auto',
                                height: 'auto',
                                zIndex: 2,
                            }}
                        />
                    </Box>
                    <Fade in={showDetails}>
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                m: '0px 16px',
                                width: 'calc(100% - 32px)',
                                top: 0,
                                zIndex: 2,

                                height: '55px',
                            }}
                        >
                            <BackgroundIconButton
                                sx={{
                                    height: '36px',
                                    width: '36px',
                                }}
                                onClick={function (event) {
                                    document.body.style.overflow = 'auto'
                                    event.stopPropagation()
                                    setExpandImage(false)
                                }}
                            >
                                <ClearRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                    }}
                                />
                            </BackgroundIconButton>
                            <Box sx={{ flexGrow: 1 }} />
                            <BackgroundIconButton
                                sx={{
                                    height: '36px',
                                    width: '36px',
                                }}
                                onClick={function (event) {
                                    document.body.style.overflow = 'auto'
                                    event.stopPropagation()
                                    setShowOptions(true)
                                }}
                            >
                                <MoreHorizRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                    }}
                                />
                            </BackgroundIconButton>
                        </Box>
                    </Fade>
                    <Fade in={showDetails}>
                        <Box
                            sx={{
                                position: 'absolute',
                                p: '16px 16px',
                                bottom: 0,
                                zIndex: 2,
                                width: 'calc(100% - 32px)',
                                background: (theme) =>
                                    hexToRGBA(
                                        theme.palette.background.primary,
                                        0.3
                                    ),
                            }}
                        >
                            <PostHeaderView
                                post={post}
                                userImage={userImage}
                                isUserImageLoading={isUserImageLoading}
                                user={userState}
                                showMoreButton={false}
                            />
                            <Box
                                sx={{
                                    fontSize: 14,
                                }}
                            >
                                <HighlightTypography
                                    text={post.caption}
                                    fontSize={14}
                                />
                            </Box>
                        </Box>
                    </Fade>
                </ImageContainer>
            </Box>
        </Grow>,
        parentElement as Element
    )
}

export default ExpandImagePostMobileView
