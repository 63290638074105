import React, { useEffect, useState } from 'react'
import { getPosts } from '../../api/RestPost'
import { useAppState } from '../../AppStateProvider'
import { Post } from '../../models/Post'
import { DataStatus } from '../../models/Dataloading'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import PostView from '../PostView/PostView'
import {
    CircularProgress,
    Box,
    Grid,
    Divider,
    List,
    ListItem,
    Button,
    ButtonBase,
    Typography,
    useTheme,
} from '@mui/material'
import { NavigateFeedState } from '../../models/NavigateState'
import {
    PrimaryDivider,
    PrimaryTypography,
    ScrollBarDiv,
    SecondaryDivider,
    SecondaryTypography,
    StyledChip,
    TertiaryTypography,
} from '../Components/StyledComponents'
import FeedSideView from './FeedSideView'
import SortPopper from '../Components/SortPopper'
import { StyledArrowForwardIosRoundedIcon } from '../Components/StyledComponents/StyledIcons'
import FeedMobileView from './FeedMobileView.tsx'
import { useMobile } from '../../utils/MobileProvider'
import { navMobileBarHeight } from '../../layouts/DefaultLayout/NavMobileBar'
import { useDesktopSize } from '../../utils/DesktopSizeProvider'

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.selected,
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.border.primary,
}))

const FeedView = () => {
    const isMobile = useMobile()
    return <>{isMobile ? <FeedMobileView /> : <FeedDesktopView />}</>
}

const FeedDesktopView = React.memo(() => {
    const [offset, setOffset] = useState(0)
    const location = useLocation()
    const initialpoststateData = location.state as NavigateFeedState | null
    const { state, setState } = useAppState()
    const [poststate, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)

    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Best')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const theme = useTheme()
    const { desktopBreakpoint } = useDesktopSize()
    // Sets the anchor element for the sort popper and displays the popper
    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }

    // Fetches posts from the server
    useEffect(() => {
        if (offset === 0 && initialpoststateData !== null) {
            setPostLoaded(DataStatus.success)
            setPostState(initialpoststateData.poststate)
        } else {
            try {
                getPosts(null, null, null, 15, offset, state.usertoken)
                    .then((response) => {
                        setPostState((prevState) => [
                            ...prevState,
                            ...response.posts,
                        ])
                        setPostLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        setPostLoaded(DataStatus.failure)
                        console.log(error)
                    })
            } catch (error) {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            }
        }
    }, [location.pathname])
    return (
        <Box
            sx={{
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    maxWidth: 1300,
                    marginTop: `${navMobileBarHeight + 8}px`,
                    width: '100%',
                    zIndex: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '0px 20px',
                }}
            >
                <Box
                    sx={{
                        width:
                            desktopBreakpoint > theme.breakpoints.values.md
                                ? 'calc(100% - 420px)'
                                : '100%',
                        display: 'flex',
                        height: '100%',
                    }}
                >
                    {postloaded === DataStatus.loading ? (
                        <Box
                            sx={{
                                marginTop: '16px',
                                justifyContent: 'center',
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <StyledCircularProgress />
                        </Box>
                    ) : (
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    fontWeight: 'bold',
                                    color: 'text.primary',
                                }}
                            >
                                For You
                            </Typography>
                            <SecondaryDivider sx={{ margin: '8px 0px' }} />

                            {poststate.map((post) => (
                                <Box
                                    sx={{
                                        mb: '8px',
                                    }}
                                >
                                    <PostView post={post} />
                                    {/* <StyledDivider /> */}
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
                {desktopBreakpoint > theme.breakpoints.values.md && (
                    <Box
                        sx={{
                            width: '400px',
                            height: '100%',
                            justifyContent: 'center',
                            pl: '20px',
                        }}
                    >
                        <FeedSideView />
                    </Box>
                )}
            </Box>
        </Box>
    )
})

export default FeedView
