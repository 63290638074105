import { Box, Button, InputBase, styled, Typography } from '@mui/material'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../../Components/StyledComponents/StyledTabView'
import { useState } from 'react'
import { Event } from '../../../../models/Event'
import { navMobileBarHeight } from '../../../../layouts/DefaultLayout/NavMobileBar'
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded'
import AccountDetailsSubView from '../../../AccountView/AccountSubViews/AccountDetailsSubView'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingLeft: 4,
    },
}))

const SearchContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: 20,
    backgroundColor: theme.palette.background.tertiary,
    width: 'calc(100% - 16px)',
    padding: '0px 8px',
    alignItems: 'center',
    display: 'flex',
    minHeight: 35,
    marginRight: 16,
}))

const ButtonsContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
    height: '100%',
}))

const ScanIconButton = styled(Box)(({ theme }) => ({
    color: theme.palette.text.selected,
    backgroundColor: theme.palette.background.secondary,
    padding: '8px 12px',
    borderRadius: '50px',
    border: '1px solid ' + theme.palette.border.primary,
    display: 'flex',
    alignItems: 'center',
}))

interface EventToolsCheckInMobileViewProps {
    event: Event
}

const EventToolsCheckInMobileView = ({
    event,
}: EventToolsCheckInMobileViewProps) => {
    const [value, setValue] = useState('attendees')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }
    return (
        <Box>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: `${navMobileBarHeight}px`,
                    display: 'flex',
                    width: 'calc(100% - 32px)',
                    p: '16px',
                }}
            >
                <Box flex={1} />
                <ScanIconButton>
                    <QrCodeScannerRoundedIcon
                        sx={{
                            color: 'text.selected',
                            fontSize: 24,
                            mr: '8px',
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                        }}
                    >
                        Scan
                    </Typography>
                </ScanIconButton>
            </Box>
            {/* <Typography
                sx={{
                    fontSize: 14,
                    ml: '16px',
                    color: 'text.secondary',
                    mb: '8px',
                }}
            >
                Manually check in attendees or ban them from the event
            </Typography>
            <ButtonsContainer
                sx={{
                    display: 'flex',
                    p: '8px',
                    m: '0px 16px',
                    mb: '8px',
                }}
            >
                <Button
                    sx={{
                        backgroundColor: 'background.selected',
                        borderRadius: '10px',
                        textTransform: 'none',
                    }}
                    fullWidth
                >
                    <Typography
                        sx={{
                            color: 'text.primary',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    >
                        Check In
                    </Typography>
                </Button>
                <Button
                    sx={{
                        backgroundColor: 'text.favorite',
                        borderRadius: '10px',
                        textTransform: 'none',
                        m: '0px 8px',
                    }}
                    fullWidth
                >
                    <Typography
                        sx={{
                            color: 'text.primary',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    >
                        Ban
                    </Typography>
                </Button>
                <Button
                    sx={{
                        backgroundColor: 'text.primary',
                        borderRadius: '10px',
                        textTransform: 'none',
                    }}
                    fullWidth
                >
                    <Typography
                        sx={{
                            color: 'background.primary',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    >
                        Unban
                    </Typography>
                </Button>
            </ButtonsContainer> */}
            <TabBox
                sx={{
                    width: '100%',
                    zIndex: 2,
                    backgroundColor: 'background.primary',
                }}
            >
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                >
                    <StyledTab label="Attendees" value="attendees" />
                    <StyledTab label="Checked In" value="checkedin" />
                    <StyledTab label="Banned" value="banned" />
                </StyledTabs>
            </TabBox>
            {value === 'attendees' && (
                <EventAttendeesMobileView event={event} />
            )}
        </Box>
    )
}

interface EventAttendeesMobileViewProps {
    event: Event
}

const EventAttendeesMobileView = ({ event }: EventAttendeesMobileViewProps) => {
    const [searchInput, setSearchInput] = useState('')
    return (
        <Box
            sx={{
                p: '16px 16px',
            }}
        >
            <SearchContainer
                sx={{
                    mb: '16px',
                }}
            >
                <SearchRoundedIcon
                    sx={{
                        color: 'text.disabled',
                        pl: '4px',
                        fontSize: '20px',
                    }}
                />
                <StyledInputBase
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                {searchInput.length > 0 && (
                    <CancelRoundedIcon
                        sx={{
                            color: 'text.disabled',
                            position: 'absolute',
                            right: '8px',
                            cursor: 'pointer',
                            fontSize: '20px',
                        }}
                        onClick={() => setSearchInput('')}
                    />
                )}
            </SearchContainer>
            {event.attendees.map((attendee, index) => (
                <Box
                    sx={{
                        pb:
                            index === event.attendees.length - 1
                                ? '16px'
                                : '24px',
                    }}
                >
                    <AccountDetailsSubView
                        user={attendee}
                        followButton={true}
                        bio={true}
                        size={40}
                    />
                </Box>
            ))}
        </Box>
    )
}

export default EventToolsCheckInMobileView
