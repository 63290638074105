import { useAppState } from '../../AppStateProvider'
import EventTicketSelectMobileView from './EventMobileView/EventTicketSelectMobileView'
import EventTicketSelectDesktopView from './EventTicketSelectDesktopView'

const EventTicketSelectView = () => {
    const { state, setState } = useAppState()
    return (
        <>
            {state.isMobile ? (
                <EventTicketSelectMobileView />
            ) : (
                <EventTicketSelectDesktopView />
            )}
        </>
    )
}

export default EventTicketSelectView
