import {
    AppBar,
    Box,
    IconButton,
    IconButtonProps,
    LinearProgress,
    styled,
    Toolbar,
} from '@mui/material'
import {
    StyledIconButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useAppState } from '../../../AppStateProvider'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { Link as RouterLink } from 'react-router-dom'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    borderBottom: '1px solid ' + theme.palette.border.secondary,
    // background: hexToRGBA('#111111', 0.6),
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    // backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    // boxShadow: 'none',
    // marginRight: 6,
}))

interface LogoIconButtonProps extends IconButtonProps {
    component?: React.ElementType
    href?: string
}

const LogoIconButton = styled((props: LogoIconButtonProps) => (
    <IconButton {...props} />
))(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

interface FeedTopBarMobileViewProps {
    setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>
    setShowSearch: React.Dispatch<React.SetStateAction<boolean>>
}

const FeedTopBarMobileView = ({
    setShowDrawer,
    setShowSearch,
}: FeedTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar style={{ minHeight: 55, paddingLeft: 8, paddingRight: 8 }}>
                <StyledIconButton onClick={() => setShowDrawer(true)}>
                    <MenuRoundedIcon />
                </StyledIconButton>
                <LogoIconButton
                    component={RouterLink}
                    href="/app/home/"
                    disableRipple
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                        height={30}
                    />
                </LogoIconButton>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                    <StyledIconButton onClick={() => setShowSearch(true)}>
                        <SearchRoundedIcon />
                    </StyledIconButton>
                </Box>
                <Box>
                    <StyledIconButton>
                        <NotificationsNoneRoundedIcon />
                    </StyledIconButton>
                </Box>
            </Toolbar>
        </TopBar>
    )
}

export default FeedTopBarMobileView
