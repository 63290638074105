import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { useEffect, useRef, useState } from 'react'
import { DataStatus } from '../../../models/Dataloading'
import { NavigateEventState } from '../../../models/NavigateState'
import { Event } from '../../../models/Event'
import { Box, Typography } from '@mui/material'
import {
    AttendingBox,
    HostBox,
    LoadingBox,
    PrimaryDivider,
} from '../../Components/StyledComponents'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import { styled } from '@mui/system'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import EventDetailsView, {
    EventDetailsAboutView,
    EventDetailsDateView,
    EventDetailsStatisticsView,
} from '../EventDetailsView'
import EventHostDetailsView from '../EventDetailsView/EventHostDetailsView'
import EventDetailsMapView from '../EventDetailsView/EventDetailsMapView'
import EventDetailsCategoriesView from '../EventDetailsView/EventDetailsCategoriesView'
import EventDetailsTagView from '../EventDetailsView/EventDetailsTagView'
import EventTopBarMobileView from './EventTopBarMobileView'
import zIndex from '@mui/material/styles/zIndex'
import { set } from 'date-fns'
import EventPostsMobileView from './EventPostsMobileView'
import EventDetailsPostsMobileView from './EventDetailsPostsMobileView'
import EventDetailsAttendeesMobileView from './EventDetailsAttendeesMobileView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import EventAttendeesMobileView from './EventAttendeesMobileView'
import { getEvent, grabEventPhoto } from '../../../api/RestEvent'
import { use } from 'marked'
import EventDetailsHostMobileView from './EventDetailsHostMobileView'
import { navigateToEvent } from '../../../utils/NavigationFunctions'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { iso2eventtime } from '../../../utils/DateStringConverter'
import { useQuery } from 'react-query'
import { Post } from '../../../models/Post'
import EventBottomBarMobileView from './EventBottomBarMobileView'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'

const eventphotoheight = 265
const topbarheight = 56
const eventphotoheightdifference = eventphotoheight - topbarheight

const PicturesBox = styled(Box)(({ theme }) => ({
    width: '100%',
    borderRadius: 50,
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.7),
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignText: 'center',
    display: 'flex',
    padding: '5px 10px',
    cursor: 'pointer',
}))

const EventPageMobileView = () => {
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const { slug } = useParams<{ slug: string }>()
    const navigate = useNavigate()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const location = useLocation()
    const locationEvent = location.state as NavigateEventState
    const eventslugRef = useRef(slug)
    const [eventState, setEventState] = useState<Event | null>(
        locationEvent ? locationEvent.event : null
    )
    const [eventImage, setEventImage] = useState<string | null>(
        locationEvent ? locationEvent.eventImage : null
    )

    const [eventHostImage, setEventHostImage] = useState<string | null>(null)
    const [value, setValue] = useState('date')
    const [scrollPosition, setScrollPosition] = useState(0)
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [eventloaded, setEventLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const {
        data: eventQueryImage,
        isLoading: isEventImageLoading,
        error: userImageError,
    } = useQuery(
        eventState ? eventState.image : '',
        function () {
            if (eventState && !eventImage) {
                return apiManager.grabEventPhoto(eventState.image)
            }
        },
        { enabled: !!eventState && !!!eventImage }
    )

    useEffect(() => {
        if (eventQueryImage && !eventImage) {
            setEventImage(eventQueryImage)
        }
    }, [eventQueryImage, eventImage, eventState])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (detailsRef.current[newValue]) {
            const targetDiv = detailsRef.current[newValue] as HTMLElement
            const yPosition =
                targetDiv.getBoundingClientRect().top +
                window.scrollY -
                topbarheight -
                45
            console.log(yPosition)
            window.scrollTo({
                behavior: 'smooth',
                top: yPosition,
            })
        }
        setValue(newValue)
    }
    const detailsRef = useRef<{ [key: string]: HTMLElement | null }>({})
    useEffect(() => {
        eventslugRef.current = slug
    }, [window.location.pathname])

    useEffect(() => {
        if (eventState === null) {
            setEventLoaded(DataStatus.loading)
            if (slug) {
                apiManager
                    .getEvent(slug)
                    .then((response) => {
                        setEventState(response.event)
                        setEventLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        setEventLoaded(DataStatus.failure)
                        console.log(error)
                    })
            }
        } else {
            setEventLoaded(DataStatus.success)
        }
        const handleScroll = () => {
            setScrollPosition(window.scrollY)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    useEffect(() => {
        if (
            postLoaded === DataStatus.loading &&
            eventState &&
            tabIndex === 'details'
        ) {
            apiManager
                .getPosts(null, null, eventState.slug, 5, 0)
                .then((response) => {
                    setPostState(response.posts)
                    setPostLoaded(DataStatus.success)
                })
                .catch((error) => {
                    setPostLoaded(DataStatus.failure)
                    console.log(error)
                })
        }
    }, [tabIndex, eventState])

    useEffect(() => {
        const closestDetail = () => {
            var closestId: string | null = null
            let minDistance = Infinity
            Object.entries(detailsRef.current).forEach(([key, value]) => {
                if (value) {
                    const yPosition =
                        value.getBoundingClientRect().top - topbarheight - 45
                    if (Math.abs(yPosition) < minDistance) {
                        minDistance = Math.abs(yPosition)
                        closestId = key
                    }
                }
            })
            if (closestId) {
                return closestId
            } else {
                return value
            }
        }
        setValue(closestDetail())
    }, [scrollPosition])
    return (
        <>
            {eventloaded === DataStatus.success &&
                eventState &&
                (tabIndex === 'details' ? (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            height: 'calc(100%)',
                            mb: `${navMobileBarHeight}px`,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        {eventState && !eventState.is_host && (
                            <EventBottomBarMobileView eventState={eventState} />
                        )}

                        <Box
                            sx={{
                                width: '100%',
                                height: '550px',
                                position: 'absolute',
                                top: 0,
                                zIndex: -1,
                            }}
                        >
                            {eventImage && (
                                <img
                                    src={eventImage}
                                    style={{
                                        width: `100%`,
                                        filter: `blur(40px)`,
                                        height: '100%',
                                        WebkitMaskImage:
                                            'linear-gradient(to bottom, rgba(0, 0, 0, 01), rgba(0, 0, 0, 0))',
                                        // background:
                                        //     'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                                    }}
                                />
                            )}
                        </Box>
                        <EventTopBarMobileView
                            event={eventState}
                            setEvent={setEventState}
                            // show top bar text linearly after photo detials dissapear
                            opacity={Math.min(
                                (scrollPosition - 100) /
                                    (eventphotoheightdifference - 100),
                                1
                            )}
                        />
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'sticky',
                                    width: '100%',
                                    height: eventphotoheight,
                                    top: -eventphotoheightdifference,
                                    // backgroundColor: 'background.primary',
                                    zIndex: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    display: 'flex',
                                    mt: '54px',
                                }}
                            >
                                {eventImage ? (
                                    <Box
                                        sx={{
                                            backgroundColor:
                                                'background.primary',
                                            width: `calc(100% - ${32 - Math.min((scrollPosition / eventphotoheightdifference) * 32, 32)}px)`,
                                            borderRadius: `${20 - Math.min((scrollPosition / eventphotoheightdifference) * 20, 20)}px`,
                                            overflow: 'hidden',
                                            height: '100%',
                                        }}
                                    >
                                        <img
                                            src={eventImage}
                                            style={{
                                                width: `100%`,
                                                filter: `blur(${Math.min((scrollPosition / eventphotoheightdifference) * 10, 10)}px)`,
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <LoadingBox
                                        sx={{
                                            width: `calc(100% - ${32 - Math.min((scrollPosition / eventphotoheightdifference) * 32, 32)}px)`,
                                            borderRadius: `${10 - Math.min((scrollPosition / eventphotoheightdifference) * 10, 10)}px`,
                                            height: '100%',
                                            opacity: 1,
                                            zIndex: 1,
                                            objectFit: 'cover',
                                        }}
                                    />
                                )}
                                {/* <Box
                                    sx={{
                                        position: 'absolute',
                                        width: 'calc(100% - 48px)',
                                        bottom: 0,
                                        padding: '8px 16px',
                                        opacity:
                                            1 -
                                            Math.min(scrollPosition / 100, 1),
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 30,
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            color: 'text.primary',
                                            textShadow:
                                                '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                        }}
                                    >
                                        {eventState.title !== ''
                                            ? eventState.title
                                            : 'Event Title'}
                                    </Typography>
                                    <Box
                                        sx={{
                                            alighItems: 'left',
                                            display: 'flex',
                                            alignItems: 'center',
                                            textShadow:
                                                '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FavoriteBorderRoundedIcon
                                                sx={{
                                                    marginRight: '4px',
                                                    fontSize: 20,
                                                    color: 'text.primary',
                                                    textShadow:
                                                        '0px 0px 10px rgba(0, 0, 0, 0.9)',
                                                }}
                                            />
                                            <Typography
                                                variant="body1"
                                                color="textPrimary"
                                                fontSize={16}
                                                sx={{
                                                    textShadow:
                                                        '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                                }}
                                            >
                                                {eventState.favorites_count}{' '}
                                                likes
                                            </Typography>
                                        </Box>
                                        <Box width={20} />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onClick={() =>
                                                navigateToEvent(
                                                    apiManager,
                                                    eventState,
                                                    eventImage,
                                                    navigate,
                                                    state,
                                                    setState,
                                                    'posts'
                                                )
                                            }
                                        >
                                            <RepeatRoundedIcon
                                                sx={{
                                                    marginRight: '4px',
                                                    fontSize: 20,
                                                    color: 'text.primary',
                                                    textShadow:
                                                        '0px 0px 10px rgba(0, 0, 0, 0.9)',
                                                }}
                                            />
                                            <Typography
                                                color="textPrimary"
                                                fontSize={16}
                                                sx={{
                                                    textShadow:
                                                        '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                                }}
                                            >
                                                {eventState.post_count} posts
                                            </Typography>
                                        </Box>
                                        <Box width={20} />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onClick={() =>
                                                navigateToEvent(
                                                    apiManager,
                                                    eventState,
                                                    eventImage,
                                                    navigate,
                                                    state,
                                                    setState,
                                                    'attendees'
                                                )
                                            }
                                        >
                                            <PersonOutlineRoundedIcon
                                                sx={{
                                                    marginRight: '4px',
                                                    fontSize: 20,
                                                    color: 'text.primary',
                                                    textShadow:
                                                        '0px 0px 10px rgba(0, 0, 0, 0.9)',
                                                }}
                                            />

                                            <Typography
                                                variant="body1"
                                                color="textPrimary"
                                                fontSize={16}
                                                sx={{
                                                    textShadow:
                                                        '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                                }}
                                            >
                                                {eventState.attendee_count}{' '}
                                                attendees
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <PicturesBox
                                            sx={{ marginTop: '8px' }}
                                            // onClick={() =>
                                            //     navigateToEventPhotos(
                                            //         event,
                                            //         eventImage,
                                            //         navigate,
                                            //         state,
                                            //         setState,
                                            //         tabIndex!
                                            //     )
                                            // }
                                        >
                                            <Typography
                                                color="textPrimary"
                                                sx={{ fontSize: 14 }}
                                            >
                                                See all 0 photos
                                            </Typography>
                                        </PicturesBox>
                                    </Box>
                                </Box> */}
                            </Box>

                            <Box
                                sx={{
                                    pt: '8px',
                                    pb: '16px',
                                    borderRadius: '0px 0px 10px 10px',
                                }}
                            >
                                <Box
                                    sx={{
                                        p: '8px 16px',
                                    }}
                                >
                                    <Box display="flex" alignItems="center">
                                        {eventState.is_host ? (
                                            <HostBox
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    mr: '8px',
                                                    // p: '4px 20px',
                                                    // pl: '16px',
                                                }}
                                            >
                                                <SecurityRoundedIcon
                                                    sx={{
                                                        color: 'background.primary',
                                                        fontSize: 14,
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: 'bold',
                                                        color: 'background.primary',
                                                        ml: '4px',
                                                    }}
                                                >
                                                    Host
                                                </Typography>
                                            </HostBox>
                                        ) : (
                                            eventState.attending && (
                                                <AttendingBox
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <PersonRoundedIcon
                                                        sx={{
                                                            color: 'background.primary',
                                                            fontSize: 16,
                                                        }}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            color: 'background.primary',
                                                            ml: '4px',
                                                        }}
                                                    >
                                                        Attending
                                                    </Typography>
                                                </AttendingBox>
                                            )
                                        )}
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: 30,
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            color: 'text.primary',
                                        }}
                                    >
                                        {eventState.title !== ''
                                            ? eventState.title
                                            : 'Event Title'}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            color: 'text.selected',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {iso2eventtime(
                                            eventState.event_time,
                                            'long',
                                            'long'
                                        )}
                                    </Typography>

                                    <Box
                                        sx={{
                                            alighItems: 'left',
                                            display: 'flex',
                                            alignItems: 'center',
                                            mt: '8px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {eventState.favorited ? (
                                                <FavoriteRoundedIcon
                                                    sx={{
                                                        marginRight: '4px',
                                                        fontSize: 18,
                                                        color: 'text.favorite',
                                                    }}
                                                />
                                            ) : (
                                                <FavoriteBorderRoundedIcon
                                                    sx={{
                                                        marginRight: '4px',
                                                        fontSize: 18,
                                                        color: 'text.primary',
                                                    }}
                                                />
                                            )}
                                            <Typography
                                                color="text.primary"
                                                fontSize={14}
                                            >
                                                {eventState.favorites_count}{' '}
                                                likes
                                            </Typography>
                                        </Box>
                                        <Box width={20} />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onClick={() =>
                                                navigateToEvent(
                                                    apiManager,
                                                    eventState,
                                                    eventImage,
                                                    navigate,
                                                    state,
                                                    setState,
                                                    'posts'
                                                )
                                            }
                                        >
                                            <RepeatRoundedIcon
                                                sx={{
                                                    marginRight: '4px',
                                                    fontSize: 18,
                                                    color: 'text.primary',
                                                }}
                                            />
                                            <Typography
                                                color="text.primary"
                                                fontSize={14}
                                            >
                                                {eventState.post_count} posts
                                            </Typography>
                                        </Box>
                                        <Box width={20} />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onClick={() =>
                                                navigateToEvent(
                                                    apiManager,
                                                    eventState,
                                                    eventImage,
                                                    navigate,
                                                    state,
                                                    setState,
                                                    'attendees'
                                                )
                                            }
                                        >
                                            <PersonOutlineRoundedIcon
                                                sx={{
                                                    marginRight: '4px',
                                                    fontSize: 18,
                                                    color: 'text.primary',
                                                }}
                                            />

                                            <Typography
                                                color="text.primary"
                                                fontSize={14}
                                            >
                                                {eventState.attendee_count}{' '}
                                                attendees
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <PrimaryDivider
                                        sx={{ margin: '16px 0px' }}
                                    />
                                    <EventDetailsStatisticsView
                                        event={eventState}
                                    />
                                </Box>
                                <TabBox
                                    sx={{
                                        position: 'sticky',
                                        top: topbarheight,
                                        backgroundColor: 'background.primary',
                                        zIndex: 2,
                                    }}
                                >
                                    <StyledTabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        variant="scrollable"
                                    >
                                        <StyledTab label="Date" value="date" />
                                        <StyledTab label="Host" value="host" />
                                        <StyledTab
                                            label="About"
                                            value="about"
                                        />
                                        <StyledTab
                                            label="Location"
                                            value="location"
                                        />
                                        <StyledTab
                                            label="Posts"
                                            value="posts"
                                        />
                                        <StyledTab
                                            label="Categories"
                                            value="categories"
                                        />
                                        <StyledTab label="Tags" value="tags" />
                                        <StyledTab
                                            label="Attendees"
                                            value="attendees"
                                        />
                                    </StyledTabs>
                                </TabBox>
                                <Box
                                    sx={{
                                        p: '0px 16px',
                                        pt: '16px',
                                        pb: '16px',
                                    }}
                                    ref={(el: HTMLElement | null) =>
                                        (detailsRef.current['date'] = el)
                                    }
                                >
                                    <EventDetailsDateView event={eventState} />
                                    {/* <PrimaryDivider sx={{ mt: '24px' }} /> */}
                                </Box>
                                <Box
                                    sx={{
                                        p: '0px 16px',
                                        pt: '8px',
                                        pb: '16px',
                                    }}
                                    ref={(el: HTMLElement | null) =>
                                        (detailsRef.current['host'] = el)
                                    }
                                >
                                    <EventDetailsHostMobileView
                                        host={eventState.primary_host}
                                    />
                                    {/* <PrimaryDivider sx={{ mt: '24px' }} /> */}
                                </Box>

                                <Box
                                    sx={{
                                        p: '0px 16px',
                                        pt: '8px',
                                        pb: '16px',
                                    }}
                                    ref={(el: HTMLElement | null) =>
                                        (detailsRef.current['about'] = el)
                                    }
                                >
                                    <EventDetailsAboutView event={eventState} />
                                    {/* <PrimaryDivider sx={{ mt: '24px' }} /> */}
                                </Box>

                                <Box
                                    sx={{
                                        p: '0px 16px',
                                        pt: '8px',
                                        pb: '16px',
                                    }}
                                    ref={(el: HTMLElement | null) =>
                                        (detailsRef.current['location'] = el)
                                    }
                                >
                                    <EventDetailsMapView
                                        event={eventState}
                                        height={200}
                                    />
                                    {/* <PrimaryDivider sx={{ mt: '24px' }} /> */}
                                </Box>

                                <Box
                                    sx={{
                                        p: '0px 0px',
                                        pt: '8px',
                                        pb: '16px',
                                    }}
                                    ref={(el: HTMLElement | null) =>
                                        (detailsRef.current['posts'] = el)
                                    }
                                >
                                    <EventDetailsPostsMobileView
                                        event={eventState}
                                        eventImage={eventImage}
                                        postState={postState}
                                        postLoaded={postLoaded}
                                    />
                                    {/* <PrimaryDivider sx={{ mt: '24px' }} /> */}
                                </Box>

                                <Box
                                    sx={{
                                        p: '0px 16px',
                                        pt: '8px',
                                        pb: '16px',
                                    }}
                                    ref={(el: HTMLElement | null) =>
                                        (detailsRef.current['categories'] = el)
                                    }
                                >
                                    <EventDetailsCategoriesView
                                        event={eventState}
                                    />
                                    {/* <PrimaryDivider sx={{ mt: '24px' }} /> */}
                                </Box>

                                <Box
                                    sx={{
                                        p: '0px 16px',
                                        pt: '8px',
                                        pb: '16px',
                                    }}
                                    ref={(el: HTMLElement | null) =>
                                        (detailsRef.current['tags'] = el)
                                    }
                                >
                                    <EventDetailsTagView event={eventState} />
                                    {/* <PrimaryDivider sx={{ mt: '24px' }} /> */}
                                </Box>
                                <Box
                                    sx={{
                                        p: '0px 16px',
                                        pt: '8px',
                                    }}
                                    ref={(el: HTMLElement | null) =>
                                        (detailsRef.current['tags'] = el)
                                    }
                                >
                                    <EventDetailsAttendeesMobileView
                                        event={eventState}
                                        eventImage={eventImage}
                                    />
                                    {/* <PrimaryDivider sx={{ mt: '24px' }} /> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ) : tabIndex === 'posts' ? (
                    <EventPostsMobileView eventState={eventState} />
                ) : (
                    tabIndex === 'attendees' && (
                        <EventAttendeesMobileView event={eventState} />
                    )
                ))}
        </>
    )
}

export default EventPageMobileView
