import {
    Box,
    BoxProps,
    InputBase,
    Select,
    Typography,
    styled,
} from '@mui/material'
import { LoginRequestData, login } from '../../api/Auth'
import { useState } from 'react'
import { Formik } from 'formik'
import { FormValues } from './LoginView'
import * as Yup from 'yup'
import { useAppState } from '../../AppStateProvider'
import { grabUserPhoto } from '../../api/RestUser'
import { useNavigate } from 'react-router-dom'
import {
    SelectButton,
    StyledButtonCircularProgress,
} from '../Components/StyledComponents'
import LoginTopBarMobileView from './LoginTopBarMobileView'
import { useApiManager } from '../../api/ApiMangerProvider'

interface LoginViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
}

const DivRoot = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 10000000000000,
}))

interface InputContainerProps extends BoxProps {
    focused: boolean
}

const InputContainer = styled((props: InputContainerProps) => (
    <Box {...props} />
))(({ theme, focused }) => ({
    // backgroundColor: theme.palette.background.secondary,
    borderBottom: '1px solid ' + theme.palette.border.secondary,
    display: 'flex',
    padding: '0px 0px',
    width: '100%',
    '&:hover': {
        borderColor: focused
            ? theme.palette.background.selected
            : theme.palette.text.secondary,
    },
    ...(focused && {
        borderColor: theme.palette.background.selected,
    }),
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingLeft: 4,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}))

const LoginMobileView = ({ setShowLogin, setShowRegister }: LoginViewProps) => {
    const apiManager = useApiManager()
    const [authenticating, setAuthenticating] = useState(false)
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const handleSubmit = (values: FormValues) => {
        setAuthenticating(true)
        const loginRequestData: LoginRequestData = {
            user: {
                email: values.email,
                password: values.password,
            },
        }
        apiManager
            .login(loginRequestData.user.email, loginRequestData.user.password)
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    name: response.user.name,
                    username: response.user.username.replace(/\s+/g, ''),
                    useremail: response.user.email,
                    usertoken: response.user.token,
                    userpassword: values.password,
                    userimagelink: response.user.image,
                }))
                localStorage.setItem('usertoken', response.user.token)
                localStorage.setItem('username', response.user.username)
                localStorage.setItem('useremail', response.user.email)
                localStorage.setItem('userpassword', values.password)
                localStorage.setItem('name', response.user.name)
                localStorage.setItem('userimagelink', response.user.image)
                localStorage.setItem('lastauthtime', Date.now().toString())
                if (response.user.image && !state.userimage) {
                    grabUserPhoto(response.user.image, response.user.token)
                        .then((response) => {
                            setState((prevState) => ({
                                ...prevState,
                                userimage: response,
                            }))
                            localStorage.setItem('userimage', response)
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                }
                navigate('/app/feed/fyp', {
                    replace: true,
                })
                setAuthenticating(false)
            })
            .catch((error) => {
                console.error(error)
                setAuthenticating(false)
            })
    }
    return (
        <DivRoot>
            <LoginTopBarMobileView setShowLogin={setShowLogin} />
            <Box
                sx={{
                    mt: '55px',
                    p: '16px',
                    width: '100%',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                >
                    Log in
                </Typography>
                <Typography
                    sx={{
                        mt: '8px',
                        fontSize: 14,
                        color: 'text.secondary',
                    }}
                >
                    Log in using your email address
                </Typography>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    initialErrors={
                        {
                            email: 'Email is required',
                            password: 'Password is required',
                        } as FormValues
                    }
                    validate={(values) => {
                        const errors: Partial<FormValues> = {}
                        if (!values.email) {
                            errors.email = 'Email is required'
                        }
                        if (!values.password) {
                            errors.password = 'Password is required'
                        }
                        return errors
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('Must be a valid email')
                            .max(255),
                        password: Yup.string().min(6).max(255),
                    })}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <InputContainer
                                focused={false}
                                sx={{
                                    mt: '24px',
                                }}
                            >
                                <StyledInputBase
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </InputContainer>
                            <InputContainer
                                focused={false}
                                sx={{
                                    mt: '16px',
                                }}
                            >
                                <StyledInputBase
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </InputContainer>
                            <Typography
                                sx={{
                                    mt: '16px',
                                    fontSize: 14,
                                    color: 'text.selected',
                                }}
                            >
                                Forgot password
                            </Typography>

                            <SelectButton
                                fullWidth
                                type="submit"
                                sx={{
                                    mt: '16px',
                                    height: '40px',
                                    fontSize: 16,
                                }}
                                disabled={!isValid}
                            >
                                {authenticating ? (
                                    <StyledButtonCircularProgress
                                        size={'26px'}
                                    />
                                ) : (
                                    'Log in'
                                )}
                            </SelectButton>
                            <Box
                                sx={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: '16px',
                                        fontSize: 14,
                                        color: 'text.tertiary',
                                    }}
                                >
                                    Don't have an account?
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: '16px',
                                        ml: '4px',
                                        fontSize: 14,
                                        color: 'text.selected',
                                    }}
                                    onClick={function () {
                                        navigate('/createaccount')
                                    }}
                                >
                                    Sign in here
                                </Typography>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </DivRoot>
    )
}

export default LoginMobileView
