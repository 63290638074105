import { useState } from 'react'
import { styled } from '@mui/system'
import { Box, Typography, Chip, ButtonBase } from '@mui/material'
import { FilterCategory, FilterData } from '../../../models/Filter'
import { PrimaryDivider } from '../../Components/StyledComponents'

interface StyledIconButtonProps extends React.ComponentProps<typeof Chip> {
    item: FilterCategory
    selecteditems: string[]
}

const StyledChip = styled((props: StyledIconButtonProps) => (
    <Chip {...props} />
))(({ theme, item, selecteditems }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
    ...(selecteditems.includes(item) && {
        backgroundColor: theme.palette.background.selected,
        color: theme.palette.text.primary,
    }),
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

interface CategoriesPickerViewProps {
    tempfilterstate: FilterData
    setTempFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    width: string
}

const CategoriesPickerView = ({
    tempfilterstate,
    setTempFilterState,
    width,
}: CategoriesPickerViewProps) => {
    const [value, setValue] = useState<string[]>(tempfilterstate.category)
    const handleChange = (newValue: FilterCategory) => {
        setValue((prevItems) => {
            if (prevItems.includes(newValue)) {
                return prevItems.filter((item) => item !== newValue)
            } else {
                return [...prevItems, newValue]
            }
        })
        setTempFilterState((prevItems) => {
            if (prevItems.category.includes(newValue)) {
                return {
                    ...prevItems,
                    category: prevItems.category.filter(
                        (item) => item !== newValue
                    ),
                }
            } else {
                return {
                    ...prevItems,
                    category: [...prevItems.category, newValue],
                }
            }
        })
    }

    return (
        <Box sx={{ width: width }}>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.tertiary',
                    fontWeight: 'bold',
                }}
            >
                CATEGORIES
            </Typography>
            <PrimaryDivider
                sx={{
                    m: '8px 0px',
                }}
            />
            <SecondaryTypography sx={{ fontSize: 14, mt: '16px' }}>
                Filter events based on event categories
            </SecondaryTypography>
            <Box
                sx={{
                    width: `calc(${width} - 20px)`,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    gap: 1,
                    padding: '15px 0px',
                }}
            >
                {Object.values(FilterCategory).map((item, index) => (
                    <ButtonBase
                        sx={{ borderRadius: 50 }}
                        onClick={() => handleChange(item)}
                    >
                        <StyledChip
                            key={index}
                            label={item.toString()}
                            item={item}
                            selecteditems={value}
                        />
                    </ButtonBase>
                ))}
            </Box>
        </Box>
    )
}

export default CategoriesPickerView
